import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Earnings,EarningsOverview, Stats,Reviews} from '../../components/dashboard/';
import { Link, NavLink} from 'react-router-dom';
import {
Button, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Table
} from 'reactstrap';
import { Popconfirm,message,Modal  } from "antd";

import img1 from '../../assets/images/users/1.jpg';
import img4 from '../../assets/images/users/4.jpg';
import img5 from '../../assets/images/users/5.jpg';

import {getData} from '../../services/index';


class Dashboard extends React.Component {
constructor(props) {
super(props);
this.state = {
modal: false,
obj: {},
booking_list : [],
booking_count: '0',
detail_data:[],
visible: false,
booking_earning:0
};
		this.toggle = this.toggle.bind(this);
		this.bookingSubmit = this.bookingSubmit.bind(this);
		this.bookingcheckin = this.bookingcheckin.bind(this);
		this.notshow = this.notshow.bind(this);
		this.showModal = this.showModal.bind(this);
		this.handleCancel = this.handleCancel.bind(this);

		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			this.props.history.push("/authentication/extranet");
		}

	}

	handleCancel = (id,e) => {
		this.setState({
		  visible: false,
		});
	};
	showModal = (id,e) => {
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {hotel_id : hotel_id,booking_id:id}
		getData('extranet/booking_details',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
				if(result['count']>0){
					this.setState({
						detail_data: result['data']
					})
				}else{
					this.setState({
						detail_data: []
					});
				}
			}
		});
		this.setState({
			visible: true,
		});
	};

bookingSubmit(){
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = document.getElementById("booking_id").value;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_confirm',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
					message.error('Room Not Update!',2);
				}
				this.checkData();
			}
		});


  }



  bookingcheckin = (event) => {
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = event;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_checkin',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});
  }

  notshow = (event) => {
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = event;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_not_show',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});
  }

	confirm(event) {
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var booking_id = event;
		var app = {
			hotel_id : storedArray['data'][0]['hotel_id'],
			booking_id : booking_id,
		}
		getData('extranet/booking_reject',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
				if(result['status']==200){
					message.success('Booking rejected!',2);
					this.checkData();
				}
			}
		});
	}
	cancel(event) {
		console.log(event);
	}

	checkData(){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {hotel_id : hotel_id, type : 'pending_booking'}
		getData('extranet/booking_list',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
				if(result['count']>0){
					this.setState({
						booking_list: result['data'],
						booking_count: result['count']
					});
				}else{
					this.setState({
						booking_list: [],
						booking_count: '0',
					});
				}
			}
		});
		getData('extranet/booking_earning',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
				if(result['count']>0){
					this.setState({
						booking_earning: result['data'][0]['total']
					});
				}else{
					this.setState({
						booking_earning: '0'
					});
				}
			}
		});
	}

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.checkData();
		}

	}


	toggle() {
		this.setState({
		modal: !this.state.modal
		});
	}

render() {
var BookingDetails;

return (
<div>
   <Row>
      <Col sm={12} lg={4}>
      <Earnings />
      </Col>
      <Col sm={12} lg={8}>
      <EarningsOverview />
      </Col>
   </Row>

	<Modal
				title="Booking Details"
				visible={this.state.visible}
				onCancel={this.handleCancel}
				okButtonProps={{ disabled: true }}
			>
			{this.state.detail_data.map((list) => (
				<table class="table table-bordered">
					<tbody>
					<tr>
						<td className="td_wd">Booking ID</td>
						<td>BMS00{list.booking_id}</td>
					</tr>
					<tr>
						<td> Name of Hotel</td>
						<td>{list.hotel_name}</td>
					</tr>
					<tr>
						<td><label>Name of Guest </label></td>
						<td>{list.name}</td>
					</tr>
					<tr>
						<td> <label>Booking Date </label></td>
						<td>{list.booking_date}</td>
					</tr>
					<tr>
						<td> <label>Checkin Date </label></td>
						<td>{list.check_in}</td>
					</tr>
					<tr>
						<td> <label>Checkout Date </label></td>
						<td>{list.check_out}</td>
					</tr>
					<tr>
						<td> <label>Number of Nights </label></td>
						<td>{list.total_night}</td>
					</tr>
					<tr>
						<td> <label>Guest </label></td>
						<td>Adult {list.adult} Child {list.children} </td>
					</tr>
					<tr>
						<td> <label>Number of Rooms </label></td>
						<td>{list.room}</td>
					</tr>
					<tr>
						<td colspan="2">
						<table class="table table-bordered">
    <thead>
      <tr>
        <th>Room Type</th>
        <th>Total Rooms</th>
        <th>Rate Plan</th>
      </tr>
    </thead>
    <tbody>
	{list.room_list.map((plan) => (
      <tr>
        <td>{plan.display_name}</td>
        <td>{plan.room_qty}</td>
        <td>{plan.plan_type}</td>
      </tr>
	))}
    </tbody>
  </table>
						</td>
					</tr>
					</tbody>
				</table>
			))}
            </Modal>

</div>
);
}
}
export default Dashboard;
