import React from 'react';
import axios from 'axios';
import {getData} from '../../services/index';
import {Link} from 'react-router-dom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import 'react-datetime/css/react-datetime.css';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Collapse } from 'antd';
import { Button } from 'antd';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  FormFeedback
} from 'reactstrap';

import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import { DatePicker,Popover,message,Checkbox,Drawer } from 'antd';



import ac_img from "../../assets/icons/ac.png";
import breakfast_img from "../../assets/icons/breakfast.png";
import pure_veg from "../../assets/icons/pure_veg.png";
import pet_img from "../../assets/icons/pet.png";
import pool_img from "../../assets/icons/pool.png";
import restaurant_img from "../../assets/icons/restaurant.png";
import wifi_img from "../../assets/icons/wifi.png";
import $ from 'jquery';

const { Panel } = Collapse;

function callback(key) {
  //console.log(key);
}

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'MMM DD, YYYY';
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  return current && current < moment().startOf('day');
}
function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}
function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const now = new Date();
var yesterday = Datetime.moment().subtract(1, "day");
var valid = function(current) {
  return current.isAfter(yesterday);
};
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
var d = new Date();
const curr_checkin = monthNames[d.getMonth()] + ' ' + (d .getDate()) + ', ' + d .getFullYear();
const curr_checkout = monthNames[d.getMonth()] + ' ' + (d .getDate()+1) + ', ' + d .getFullYear();

class AddNewHotel extends React.Component {

	constructor(props) {

		super(props);
		this.defaultOption = { "id": "-1", "value": "" };
		this.state = {
			RoomData: [],
			persons1: [],
			child_age_rows: [],
			question: "",
			bookingLoading:false,
            options: [this.defaultOption],
			rows: [],
			room_double_sell_rate : '',
			room_single_sell_rate : '',
			checkin_time : '',
			checkout_time : '',
			grand_total:0,
			discount_total:0,
			extra_discount:0,
			adult_qty:2,
			child_qty:0,
			room_qty:1,
			email : '',
			phone : '',
			adult_name : [],
			adult_img : [],
			child_name : [],
			child_img : [],
			 gst_visible:false,
			no_rooms:0,
			hotel_id : '',
			ListData : [],
			UpdateData : [],
			CityData:[],
			StateData:[],
			 name:'',
		   company_name:'',
		   company_address:'',
		   gstin_no:'',
		   hotel_discount:0,
		   booster_discount:0,
		   bookmystay_offer:0,
		   en: true,
		   show: false,
		   config: {},
		   checkin_Date : '',
		   checkout_Date : '',
		   room_list:[],
		};
		this.gstNumber = this.gstNumber.bind(this);
		this.onChange = this.onChange.bind(this);
		this.extraDiscount = this.extraDiscount.bind(this);
		this.onChange_ = this.onChange_.bind(this);
		this.booking = this.booking.bind(this);
		this.checking = this.checking.bind(this);

	}


componentDidMount() {
		this.checkdata();

	}





	checkdata() {

		var tempDate = new Date();
		var date = new Date(+now) ;
		var edate = date.setDate(date.getDate() + 1);

		function convert(str) {
		    var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		    return [date.getFullYear(), mnth, day].join("-");
		}

		var checkin = localStorage.getItem("checkin");
		var checkout = localStorage.getItem("checkout");
		var room = parseInt(localStorage.getItem("room"));
		var adult = parseInt(localStorage.getItem("adult"));
		var child = parseInt(localStorage.getItem("child"));
		var hotel_id = parseInt(localStorage.getItem("hotel_id"));
		var room_id = parseInt(localStorage.getItem("room_id"));
		var hotel_discount = parseInt(localStorage.getItem("hotel_discount"));
		var booster_discount = parseInt(localStorage.getItem("booster_discount"));
		var bookmystay_offer = parseInt(localStorage.getItem("bookmystay_offer"));

		if(localStorage.getItem("checkin")){
			var date1 = convert(checkin);
			var date2 = convert(checkout);
			var checkin_Date = new Date(checkin);
			var checkout_Date = new Date(checkout);
		}

		if(child > 0){
			child = child;
		}else{
			child = parseInt('0');
		}
		this.setState({
			checkin_Date: checkin_Date,
			checkout_Date: checkout_Date,
			room_qty: room,
			adult_qty: adult,
			child_qty: child,
			hotel_id: hotel_id,
			checkin: localStorage.getItem("checkin"),
			checkout: localStorage.getItem("checkout"),
			hotel_discount: hotel_discount,
			booster_discount: booster_discount,
			bookmystay_offer: bookmystay_offer
		});

		var user_info = {
			user_id: 0
		}
        getData('hotel/user_details',user_info).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.setState({
						name: result['data'][0]['name'],
						email : result['data'][0]['email'],
						phone : result['data'][0]['phone']
					})
				}
			}
		});

		var app = {
			hotel_id: hotel_id,
			checkin: checkin_Date,
			checkout: checkout_Date
		}
        getData('extranet/offline_hotel_details',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						ListData: result['data'],
						hotel_name : result['data'][0]['hotel_name'],
						url : result['data'][0]['url'],
						id : result['data'][0]['id'],
						pay_hotel : result['data'][0]['pay_hotel']
					})
					var pay_value = result['data'][0]['pay_value']
					var pay_type = result['data'][0]['pay_type']
					var pay_hotel = result['data'][0]['pay_hotel']
					var pay_value = result['data'][0]['pay_value']
					localStorage.setItem('pay_hotel', pay_hotel);
					localStorage.setItem('pay_value', pay_value);
					if(pay_hotel == '1' && pay_value > 0){
						if(pay_type == 'flat'){
							var pay_amount = pay_value
							this.setState({
								'pay_amount': pay_amount,
							});
							localStorage.setItem('pay_amount', pay_amount)
						}else{
							var grand_total =  localStorage.getItem("grand_total");
							var pay_amount = (grand_total * pay_value)/100 ;
							this.setState({
								'pay_amount': pay_amount,
							});
							localStorage.setItem('pay_amount', pay_amount);
						}
					}else{
						var grand_total =  localStorage.getItem("grand_total");
						this.setState({
							'pay_amount': grand_total,
						});
						localStorage.setItem('pay_amount', grand_total);
					}
				}
			}
		});
		var plan_list_array='';
		var plan_array_count=localStorage.getItem("plan_array_count");
		var comma = '';
		for(var j=1;j<plan_array_count;j++){
			if(localStorage.getItem('plan_list'+j)>0){
				plan_list_array += comma+localStorage.getItem('plan_list'+j);
				comma=',';
			}
		}
		var room_list_array='';
		var comma = '';
		var room_qty_array = '';
		for(var k=1;k<plan_array_count;k++){
			if(localStorage.getItem('room_list'+k)>0){
				room_list_array += comma+localStorage.getItem('room_list'+k);
				room_qty_array += comma+localStorage.getItem('room'+k);
				comma=',';
			}
		}
		var child_age = "";
		if(child>0){
			var comma = '';
			for(var i=0;i<child;i++){
				child_age += comma+localStorage.getItem('age_value'+i);
				comma=',';
			}
		}
		var app_ = {
			hotel_id: hotel_id,
			checkin : date1,
			checkout : date2,
			rooms : room,
			adult : adult,
			children : child,
			room_list_array : room_list_array,
			plan_list_array : plan_list_array,
			room_qty_array : room_qty_array,
			child_age : child_age
		}
		//console.log(app_);
		getData('extranet/offline_room_details',app_).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						RoomData: result['data'],
						room_count:result['count']
					})
					//localStorage.setItem('plan_array_count', result['plan_array_count']);
					if(localStorage.getItem("room_default")=='true'){
						var plan_id=result['data'][0]['rate_plan'][0]['plan_id'];
						var plan_array_no=result['data'][0]['rate_plan'][0]['plan_array_no'];
						if(localStorage.getItem("room"+plan_array_no)>0){
							var room = parseInt(localStorage.getItem("room"+plan_array_no));
							var room_id = result['data'][0]['room_id'];
						}
						else{
							var room = parseInt(localStorage.getItem("room"));
							var room_id = result['data'][0]['room_id'];
						}
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
						localStorage.setItem('room'+plan_array_no, parseInt(room));
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
						this.selectrate();
					}
					else{
						var plan_id=localStorage.getItem("plan_id")
						var room_id=localStorage.getItem("room_id")
						this.selectrate();
					}
				}
			}
		});
	}

	gstNumber = (e) => {
		this.setState({
			gst_visible: e.target.checked
		});
	};

   toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab': tab
			});
		}
	}

	toggle1(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab1': tab
			});
		}
	}

   goBack(){
		this.props.history.goBack();
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

	extraDiscount(e){
		//this.setState({[e.target.name]:e.target.value});
		var discount_total=this.state.grand_total;
		discount_total=parseFloat(discount_total)-parseFloat(e.target.value);
		var hotel_discount=this.state.hotel_discount;
		hotel_discount=(parseFloat(hotel_discount)+parseFloat(e.target.value));
		this.setState({discount_total:discount_total,extra_discount:e.target.value});
	}


	selectrate = () => {

		var room = parseInt(localStorage.getItem("room"));
		var adult = parseInt(localStorage.getItem("adult"));
		var child = parseInt(localStorage.getItem("child"));
		var checkin_Date = localStorage.getItem("checkin_Date");
		var checkout_Date = localStorage.getItem("checkout_Date");
		var checkin = localStorage.getItem("checkin");
		var checkout = localStorage.getItem("checkout");

		function convert(str) {
		    var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		    return [date.getFullYear(), mnth, day].join("-");
		}
		if(localStorage.getItem("checkin")){
			var date1 = convert(checkin);
			var date2 = convert(checkout);
			var checkin_Date_ = new Date(checkin);
			var checkout_Date_ = new Date(checkout);
		}

		var page= this.props.location.pathname;
		var hotel_id = parseInt(localStorage.getItem("hotel_id"));
		var Difference_In_Time = checkout_Date_.getTime() - checkin_Date_.getTime();
		var Difference_In_Days = Math.round(Difference_In_Time/(1000*3600*24));


		var room_type = '0';
		var plan_list_array='';
		var plan_array_count=localStorage.getItem("plan_array_count");
		var comma = '';
		for(var j=1;j<plan_array_count;j++){
			if(localStorage.getItem('plan_list'+j)>0){
				plan_list_array += comma+localStorage.getItem('plan_list'+j);
				comma=',';
			}
		}
		var room_list_array='';
		var comma = '';
		var room_qty_array = '';
		for(var k=1;k<plan_array_count;k++){
			if(localStorage.getItem('room_list'+k)>0){
				room_list_array += comma+localStorage.getItem('room_list'+k);
				room_qty_array += comma+localStorage.getItem('room'+k);
				comma=',';
			}
		}
		var child_age = "";
		if(child>0){
			var comma = '';
			for(var i=0;i<child;i++){
				child_age += comma+localStorage.getItem('age_value'+i);
				comma=',';
			}
		}
		var app = {
			hotel_id: hotel_id,
			start : date1,
			end : date2,
			rooms : room,
			adult : adult,
			children : child,
			room_list_array : room_list_array,
			plan_list_array : plan_list_array,
			room_qty_array : room_qty_array,
			child_age : child_age
		}
		getData('extranet/offline_get_hotelcheck',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						get_hotelcheck: result['data']
					})
					this.setState({
						'base_rate': result['data'][0]['base_rate'],
						'child_charges': result['data'][0]['child_charges'],
						'hotel_discount': result['data'][0]['hotel_discount'],
						'booster_discount': result['data'][0]['booster_discount'],
						'bookmystay_offer': result['data'][0]['bookmystay_offer'],
						'gst_total': result['data'][0]['gst_total'],
						'grand_total': result['data'][0]['grand_total'],
						lmr_block: result['data'][0]['lmr_block'],
						'room_list': result['data'][0]['room_list']
					});
					if(child>0){
						var appendhtml = "(";
						var comma = '';
						for(var i=0;i<child;i++){
							appendhtml += comma+localStorage.getItem('age_value'+i)+' years';
							comma=', ';
						}
						appendhtml += ')';
						this.setState({
							child_age: appendhtml,
							child_charges:result['data'][0]['child_charges']
						})
					}
					localStorage.setItem("hotel_discount",result['data'][0]['hotel_discount']);
					localStorage.setItem("booster_discount",result['data'][0]['booster_discount']);
					localStorage.setItem("bookmystay_offer",result['data'][0]['bookmystay_offer']);
				}
			}
		});
		this.setState({
			'days': Difference_In_Days,
		});

	}

	checking(){


	}

	booking()
	{
		this.setState({ bookingLoading: true });
		var is_check=false;
		if(this.state.name==''){
			message.warning('Please enter your name');
		}
		else if(this.state.phone==''){
			message.warning('Please enter your phone');
		}
		else if(this.state.email==''){
			message.warning('Please enter your email');
		}
		else if(this.state.gst_visible==true){
			if(this.state.email==''){
				message.warning('Please enter gst number');
			}
			else if(this.state.company_name==''){
				message.warning('Please enter company name');
			}
			else if(this.state.phone==''){
				message.warning('Please enter company address');
			}
			else{
				is_check=true;
			}
		}
		else{
			is_check=true;
		}
		if(is_check==true){
			var checkin   = localStorage.getItem("checkin");
			var checkout  = localStorage.getItem("checkout");
			var checkin   = localStorage.getItem("checkin");
			var checkout  = localStorage.getItem("checkout");
			var plan_type = localStorage.getItem("plan_type");
			function convert(str) {
				var date = new Date(str),
				mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				day  = ("0" + date.getDate()).slice(-2);
				return [date.getFullYear(), mnth, day].join("-");
			}
			var room = parseInt(localStorage.getItem("room"));
			var adult = parseInt(localStorage.getItem("adult"));
			var child = parseInt(localStorage.getItem("child"));
			var hotel_id = parseInt(localStorage.getItem("hotel_id"));
			var room_id = parseInt(localStorage.getItem("room_id"));

			if(localStorage.getItem("checkin")){
				var date1 = convert(checkin);
				var date2 = convert(checkout);
				var checkin_ = new Date(checkin);
				var checkout_ = new Date(checkout);
			}
			else if(localStorage.getItem("checkin")){
				var date1 = convert(checkin);
				var date2 = convert(checkout);
				var checkin_ = new Date(checkin_);
				var checkout_ = new Date(checkout_);
			}
			if(child > 0){
				child = child;
			}else{
				child = parseInt('0');
			}
			var pay_amount = this.state.pay_amount;

			var plan_list_array='';
			var plan_array_count=localStorage.getItem("plan_array_count");
			var comma = '';
			for(var j=1;j<plan_array_count;j++){
				if(localStorage.getItem('plan_list'+j)>0){
					plan_list_array += comma+localStorage.getItem('plan_list'+j);
					comma=',';
				}
			}
			var room_list_array='';
			var comma = '';
			var room_qty_array = '';
			for(var k=1;k<plan_array_count;k++){
				if(localStorage.getItem('room_list'+k)>0){
					room_list_array += comma+localStorage.getItem('room_list'+k);
					room_qty_array += comma+localStorage.getItem('room'+k);
					comma=',';
				}
			}
			var child_age = "";
			if(child>0){
				var comma = '';
				for(var i=0;i<child;i++){
					child_age += comma+localStorage.getItem('age_value'+i);
					comma=',';
				}
			}
			localStorage.setItem('payment_type', this.state.payment_type);

			var adult_name = [];
			for (var i = 0; i < this.state.adult_qty; i++) {
			 adult_name[i]= document.getElementById('adult_name_'+i).value;
			}

			var adult_img = [];
			for (var i = 0; i < this.state.adult_qty; i++) {
			 adult_img[i]= document.getElementById('adult_img_'+i).value;
			}

			var child_name = [];
			for (var i = 0; i < this.state.child_qty; i++) {
			 child_name[i]= document.getElementById('child_name_'+i).value;
			}

			var child_img = [];
			for (var i = 0; i < this.state.child_qty; i++) {
			 child_img[i]= document.getElementById('child_img_'+i).value;
			}

			var hotel_discount=this.state.hotel_discount;
			var extra_discount=this.state.extra_discount;
			hotel_discount=parseFloat(hotel_discount)+parseFloat(extra_discount);
			var book_ = {
				lmr_block:this.state.lmr_block,
				hotel_id: hotel_id,
				hotel_name: this.state.hotel_name,
				check_in : date1,
				check_out : date2,
				room : room,
				adult : adult,
				children : child,
				amount : this.state.grand_total,
				pay_amount : 0,
				hotel_discount : hotel_discount,
				booster_discount : this.state.booster_discount,
				bookmystay_offer : this.state.bookmystay_offer,
				child_charges : this.state.child_charges,
				gst : this.state.gst_total,
				payment_type : this.state.payment_type,
				room_list_array : room_list_array,
				plan_list_array : plan_list_array,
				room_qty_array : room_qty_array,
				child_age : child_age,
				base_rate:this.state.base_rate,
				name:this.state.name,
				phone:this.state.phone,
				email:this.state.email,
				company_name:this.state.company_name,
				company_address:this.state.company_address,
				gstin_no:this.state.gstin_no,
				room_list:this.state.room_list,
				adult_name:adult_name,
				adult_img:adult_img,
				child_name:child_name,
				child_img:child_img,
			}
			getData('extranet/offline_add_booking',book_).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['status'] == '200'){
						var booking_id = result['data']['booking_id'];
						localStorage.setItem('booking_id',booking_id);
						 window.location.href = '/booking/offline_booking';
					}
				}
			});
		}
    }

	onChange_(e){

			this.setState({[e.target.name]: e.target.value});

	}


render() {
	if (this.state.redirectToReferrer){
		//this.props.history.push("/property/contact_info");
		window.location.href = '/property/contact_info';
	}

	const checking_value = curr_checkin;
	const checkout_value = curr_checkout;

	function onChange(time, timeString) {
	  console.log(time, timeString);
	}

	let { question, options } = this.state;

	var adult = [];
	for (var i = 0; i < this.state.adult_qty; i++) {

		adult.push(<div><Row><Col sm="6">

								  <FormGroup>
									<Label>Name</Label>
									<Input type="text" name={`adult_name_${i}`} id={`adult_name_${i}`}  autoComplete="off" placeholder="Please Enter Name" onChange={this.onChange_} />
								  </FormGroup>
								 </Col>
								 <Col sm="6">
								  <FormGroup>
								  <Label>Photo Id</Label>
									 <div className="custom-file">

										<Input type="file" className="custom-file-input" name={`adult_img_${i}`} id={`adult_img_${i}`}  />
										<label className="custom-file-label" htmlFor="customFile1">Choose file</label>
									  </div>
								  </FormGroup>
								  </Col> </Row></div>);
	}

	var child = [];
	for (var i = 0; i < this.state.child_qty; i++) {

		child.push(<div><Row><Col sm="6">

								  <FormGroup>
									<Label>Name</Label>
									<Input type="text" name={`child_name_${i}`} id={`child_name_${i}`}  autoComplete="off" placeholder="Please Enter Name" onChange={this.onChange_} />
								  </FormGroup>
								 </Col>
								 <Col sm="6">
								  <FormGroup>
								  <Label>Photo Id</Label>
									 <div className="custom-file">

										<Input type="file" className="custom-file-input"  name={`child_img_${i}`} id={`child_img_${i}`} />
										<label className="custom-file-label" htmlFor="customFile1">Choose file</label>
									  </div>
								  </FormGroup>
								  </Col> </Row></div>);
	}


return (
	<div>
    <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
			<CardTitle className="bg-light border-bottom p-3 mb-0">Offline Booking</CardTitle>
            <CardBody>

			<div className="row row-col-static" id="sticky-parent">
            <div className="col-md-8 mt-45 h-gallery">
                <div className="theme-hero-area search mb-60">
						<div className="hotel_list">
							<div className="theme-search-results-item theme-search-results-item-">
								<div className="theme-search-results-item-preview">
									<div className="row " data-gutter="20">
										<div className="col-md-4 col-4 col-xs-4 search-img-pad">
										{this.state.ListData.map((list) => (
											<div className="theme-search-results-item-img-wrap">
												<img className="theme-search-results-item-img" src={`${list.image}`} alt="bungalowsinlonavala.com" title="bungalowsinlonavala.com" />
											</div>
										))}
										</div>
										 {this.state.ListData.map((list) => (
										<div className="col-md-8 col-8 col-xs-8 mob-left">
											<h5 className="theme-search-results-item-title theme-search-results-item-title-lg">{list.hotel_name}

											 {(() => {
											 if (list.star_rating =='1') {
											 return (
											 <div className="star-c" style={{float:'none'}}><i className="fas fa-star"></i></div>
											 )
											 }else if (list.star_rating =='2') {
											 return (
											 <div className="star-c" style={{float:'none'}}><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
											 )
											 }
											 else if (list.star_rating =='3') {
											 return (
											 <div className="star-c" style={{float:'none'}}> <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div>
											 )
											 }
											 else if (list.star_rating =='4') {
											 return (
											 <div className="star-c" style={{float:'none'}}><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
											 )
											 }
											 else if (list.star_rating =='5') {
											 return (
											 <div className="star-c" style={{float:'none'}}><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
											 )
											 }
											 })()}

											 {(() => {
											  if (list.pay_hotel =='1') {
											 return (
											<div className="pay-at-hotel right hidden-xs">Pay At Hotel</div>
											)}
											 })()}
											</h5>
											<p className="theme-search-results-item-location"><i className="fa fa-map-marker"></i>{list.street_address}</p>
											<p className="t_tag row no-padd mt-10">
												<div className="col-md-6 col-xs-6">
													<span className="review-ckhout"><b>Check In :</b></span><br/>
													<span  className="review-chk">{this.state.checkin.substring(0,15)}</span>
												</div>
												<div className="col-md-6 col-xs-6">
													<span className="review-ckhout"><b>Check Out :</b></span><br/>
													<span className="review-chk">{this.state.checkout.substring(0,15)}</span>
												</div>


											</p>
									  </div>
									 ))}

									</div>
								</div>
							</div>
					 </div>

					 <div className="hotel_list">
							<div className="theme-search-results-item theme-search-results-item-">
								<div className="theme-search-results-item-preview">
									<div className="row mobile_selected_room" data-gutter="20">

									{this.state.RoomData.map((room) => (
                              <div className="col-md-12">
                                 <div className="row">

                                    <div className="col-md-12">
                                       <div className="col-md-12 no-padd">
										{(room.rate_plan).map((rate) =>
                                          <div className="rooms room-amen">
                                             <div className="row hidden-xs">
                                                <div className="col-md-7">
												    <div>
														<h3 className="review-title multi_qty">{rate.room_qty} &nbsp; x &nbsp;<span className="multi_plan">{rate.plan}</span></h3>
												    </div>
                                                </div>
                                                <div className="col-md-5 room_price">
                                                   <div className="theme-item-page-rooms-table-price-night review-price-night">
                                                      <p className="theme-search-results-item-price-tag font-s">
                                                         <span className="theme-item-page-rooms-table-price-night-amount multi_amount">INR &nbsp;
                                                         {rate.plan_amount} / <span className="per_night">Base Price Per Night</span>
                                                         </span>
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>

                                          </div>

										)}

                                       </div>
                                    </div>




                                 </div>
                              </div>
                              ))}


									</div>
								</div>
							</div>
					 </div>




                <Row className="traveller">
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Form>
						<h5 className="theme-search-results-item-title theme-search-results-item-title-lg">Traveller Information</h5>
                         <Row>
						  <Col sm="6">
						  <FormGroup>
                            <Label>Name</Label>
                            <Input type="text" name="name" autoComplete="off" placeholder="Please Enter Name" onChange={this.onChange} defaultValue={this.state.name}/>
                          </FormGroup>
						 </Col>
						 <Col sm="6">
                          <FormGroup>
                            <Label>Phone No</Label>
                            <Input type="text" name="phone" autoComplete="off" placeholder="Please Enter Phone No" onChange={this.onChange}  defaultValue={this.state.phone}/>
                          </FormGroup>
						  </Col>
						 <Col sm="6">
                          <FormGroup>
                            <Label>Email</Label>
                            <Input type="email" name="email" autoComplete="off" placeholder="Please Email ID" onChange={this.onChange}  defaultValue={this.state.email}/>
                          </FormGroup>
						   </Col>
						   <Col sm="6">
                          <FormGroup>
                            <Label>Payment Type</Label>
                            <Input type="select"  name="payment_type" id="payment_type" onChange={this.onChange} className="custom-select" required>
												<option value='' >Select Payment Type</option>
												<option value="PREPAID">PREPAID</option>
												<option value="PAH-HALF">PAH-HALF</option>
												<option value="PAH-FULL">PAH-FULL</option>

											  </Input>
                          </FormGroup>
						   </Col>
						   <Col sm="12">
                           <FormGroup>
                           <Checkbox onChange={this.gstNumber.bind(this)}><b>I have a GST number</b> (optional)</Checkbox>
							</FormGroup>
						   </Col>
						</Row>
						<Row className={`${this.state.gst_visible==true?'':'gst_hide'}`}>
						  <Col sm="6">
						  <FormGroup>
                            <Label>GST Number</Label>
                            <Input type="text" name="gstin_no" autoComplete="off" placeholder="Enter GST Number" onChange={this.onChange} />
                          </FormGroup>
						 </Col>
						 <Col sm="6">
                          <FormGroup>
                            <Label>Company Name</Label>
                            <Input type="text" name="company_name" autoComplete="off" placeholder="Enter Company Name" onChange={this.onChange} />
                          </FormGroup>
						  </Col>
						 <Col sm="6">
                          <FormGroup>
                            <Label>Company Address</Label>
                            <Input type="text" name="company_address" autoComplete="off" placeholder="Enter Company Address" onChange={this.onChange} />
                          </FormGroup>
						   </Col>
						</Row>

                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

				<Row className="traveller" style={{display:'none'}}>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Form>


						 {(() => {
                                 if (this.state.adult_qty > 0) {
                                 return (

								<div>
								<h5 className="theme-search-results-item-title theme-search-results-item-title-lg">Adult Information</h5>
									{adult}


								</div>
                                 )}
                                 })()}

						 {(() => {
                                 if (this.state.child_qty > 0) {
                                 return (

								<div>
								<h5 className="theme-search-results-item-title theme-search-results-item-title-lg">Child Information</h5>
									{child}


								</div>
                                 )}
                                 })()}



                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


				</div>


            </div>
			 <div className="col-md-4 hidden-xs">

				  <div className="sticky-col ">
                     <div className="theme-search-area _mb-20 _p-20 _b _bc-dw theme-search-area-vert _bg-w">
                        <div className="theme-search-area-form">



						   <h3 className="center mb-30"><b>Booking Details</b></h3>

                           <div className="theme-sidebar-section-charges">
                        <ul className="theme-sidebar-section-charges-list">



                           <li className="theme-sidebar-section-charges-item">
                              <h5 className="theme-sidebar-section-charges-item-title">{this.state.adult_qty} Adult + {this.state.days} nights + {this.state.room_qty} {(() => {
									if (this.state.room_qty > 1) {
										return (
										<span> Rooms</span>
									  )
									}
									else{
										return (
										<span> Room</span>
									  )
									}
								})()}</h5>

                              <p className="theme-sidebar-section-charges-item-price">
                                 INR &nbsp;
                                 {this.state.base_rate}
                              </p>
                           </li>


							    {(() => {
                                 if (this.state.child_qty > 0) {
                                 return (

								 <li className="theme-sidebar-section-charges-item">
                                 <h5 className="theme-sidebar-section-charges-item-title">{this.state.child_qty} children {this.state.child_age}</h5>

								 <span>
                                  <p className="theme-sidebar-section-charges-item-title"></p>


								  {this.state.child_age_rows.map(row => (
									<div className="children_age2">
										{row.content}
									</div>
									))}
								 </span>

								  <p className="theme-sidebar-section-charges-item-price">

								 {(() => {
                                 if (this.state.child_qty > 0) {
                                 return (
                                  <span>
								 + INR {this.state.child_charges}
								  </span>

                                 )}
                                 })()}


                              </p>

								  </li>
                                 )}
                                 })()}



                           {(() => {
                           if (this.state.hotel_discount > 0) {
                           return (
                           <li className="theme-sidebar-section-charges-item">
                              <h5 className="theme-sidebar-section-charges-item-title">Hotel Discount</h5>
                              <p className="theme-sidebar-section-charges-item-price">- INR {this.state.hotel_discount}</p>
                           </li>
                           )
                           }
                           })()}
						   {(() => {
							  var booster_discount = this.state.booster_discount;
							  var bookmystay_offer = this.state.bookmystay_offer;
                           if (this.state.booster_discount > 0 || this.state.bookmystay_offer>0) {
                           return (
                           <li className="theme-sidebar-section-charges-item">
                              <h5 className="theme-sidebar-section-charges-item-title">BookMyStay Discount</h5>
                              <p className="theme-sidebar-section-charges-item-price">- INR {booster_discount+bookmystay_offer}</p>
                           </li>
                           )
                           }
                           })()}
                           <li className="theme-sidebar-section-charges-item">
                              <h5 className="theme-sidebar-section-charges-item-title">Taxes, Fees &amp; Surcharges</h5>
                              <p className="theme-sidebar-section-charges-item-subtitle"></p>
                              <p className="theme-sidebar-section-charges-item-price">+ INR {this.state.gst_total}</p>
                           </li>

						   <li className="theme-sidebar-section-charges-item">
                              <h5 className="theme-sidebar-section-charges-item-title">Extra Discount</h5>
                              <p className="theme-sidebar-section-charges-item-subtitle"></p>
                              <p className="theme-sidebar-section-charges-item-price">
							  <Input type="text" className="form-control extra_discount" onChange={this.extraDiscount} name="extra_discount" /></p>
                           </li>
                        </ul>



						{(() => {
							if(this.state.discount_total > 0){
								return (
									<p className="theme-sidebar-section-charges-total">Total
										<span>INR {this.state.discount_total}</span>
									</p>
								)
							}
							else{
								return (
									<p className="theme-sidebar-section-charges-total">Total
										<span>INR {this.state.grand_total}</span>
									</p>
								)
							}
						})()}

                     </div>
					{(() => {
                     if(this.state.grand_total > 0){
                     return (
					 <div>
					 <Button
											className="thank utheme-search-area-submit _mt-0 _fw-n _ls-0 _tt-uc theme-search-area-submit-primary theme-search-area-submit-no-border theme-search-area-submit-curved book_now"
											type="primary"
											loading={this.state.bookingLoading}
											onClick={this.booking}
										>
										  Book Now
										</Button>



					 </div>
                     )
                     }else{
                     return (
                     <button className="theme-search-area-submit _mt-0 _fw-n _ls-0 _tt-uc theme-search-area-submit-dan theme-search-area-submit-no-border theme-search-area-submit-curved" >Not Available</button>
                     )
                     }
                     })()}


                        </div>
                     </div>
                  </div>

               </div>



         </div>




            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default AddNewHotel;
