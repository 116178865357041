import React from 'react';
import { connect } from 'react-redux';
import {getData} from '../../../services/index';
import { message } from 'antd';
import {Redirect,Link} from 'react-router-dom';

import {
	Nav,
	Button,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledCarousel,
	Progress,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Form,
	FormGroup,
	Input
} from 'reactstrap';
import * as data from './data';

import bookmystaylogo from '../../../assets/images/logo.png';
import profilephoto from '../../../assets/images/users/1.jpg';
import hotelpic from '../../../assets/images/users/hotel.png';
import newimage from '../../../assets/images/lo.png';
import newimage1 from '../../../assets/images/lo1.png';

const mapStateToProps = state => ({
	...state
});

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.state = {
			isOpen: false,
			redirectToReferrer: false,
			ListData: [],
			full_name:'',
			email_id:'',
			hotel_name:'',
			redirectToLogin:false,
			hotel_id:0,
			is_block:0,
			url:''
		};
		this.logout = this.logout.bind(this);
		this.hotelset = this.hotelset.bind(this);
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};

	componentDidMount() {
		this.checkData();
	}

	checkData(){
		if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
				hotel_name: storedArray['data'][0]['hotel_name'],
			}));
			if(storedArray['data'][0]['login_type']=='single'){
				var hotel_id = storedArray['data'][0]['hotel_id'];
				var app = {
					user_id : storedArray['data'][0]['user_id'],
					hotel_id : hotel_id,
					login_type : 'multi',
					full_name : storedArray['data'][0]['full_name'],
					email_id: storedArray['data'][0]['email']
				}
				getData('extranet/single_hotel_list',app).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['status']== '200'){
							this.setState({
								url:result['data'][0]['url'],
								is_block:result['data'][0]['is_block'],
								hotel_id:result['data'][0]['hotel_id']
							})
						}
					}
				});
			}
			if(storedArray['data'][0]['login_type']=='multi'){
				var hotel_id = storedArray['data'][0]['hotel_id'];
				var app = {
					user_id : storedArray['data'][0]['user_id'],
					hotel_id : hotel_id,
					login_type : 'multi',
					full_name : storedArray['data'][0]['full_name'],
					email_id: storedArray['data'][0]['email']
				}
				getData('extranet/single_hotel_list',app).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['status']== '200'){
							this.setState({
								url:result['data'][0]['url'],
								is_block:result['data'][0]['is_block'],
								hotel_id:result['data'][0]['hotel_id']
							})
						}
					}
				});
				var app = {
					user_id : storedArray['data'][0]['user_id']
				}
				getData('extranet/multiple_hotel_list',app).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['status']== '200'){
							this.setState({
								ListData: result['data']
							})
						}
					}
				});
			}

		}
	}

	hotelset = (event) => {
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = event;
		var app = {
			user_id : storedArray['data'][0]['user_id'],
			hotel_id : hotel_id,
			login_type : 'multi',
			full_name : storedArray['data'][0]['full_name'],
			email_id: storedArray['data'][0]['email']
		}
		getData('extranet/single_hotel_list',app).then((result) => {
			if(result.status==400){
				alert(result.message);
			}
			if(result.status==200){
				let responseJson = result;
				if(responseJson.data){
					message.config({ top: 150});
					message.success('Hotel Change Succecfull!!');
					localStorage.setItem('extranetData',JSON.stringify(responseJson));
					this.checkData();
					this.setState({
						url:result['data'][0]['url'],
						is_block:result['data'][0]['is_block'],
						hotel_id:result['data'][0]['hotel_id']
					})
					window.location.reload(true);
				}
			}
		});
	}

	logout(){
		localStorage.setItem("extranetData",'');
		localStorage.clear();
		window.location.href = '/login';
	}

	render() {



		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>
						<NavbarBrand href="/">

							<span className="logo-text">
								<img
									src={bookmystaylogo}
									className="light-logo"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<Nav className="float-left" navbar>


						</Nav>
						<Nav className="ml-auto float-right" navbar>

							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<img src={hotelpic} alt="hotel" className="rounded-circle" width="31" />
									<span className="mb-0 hotel-name">{this.state.hotel_name}
									<div className="block-name not_active">{this.state.is_block>0?'Not Active':'Active'}</div>
									</span>

								</DropdownToggle>
								<DropdownMenu right className="user-dd overflow_scroll">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>

									<DropdownItem divider />
									{this.state.ListData.map((list) => (
									<div>
									<DropdownItem onClick={this.hotelset.bind(this,list.hotel_id)}>
										<i className="fa fa-bed mr-1 ml-1" /> {list.hotel_name}
									</DropdownItem>
									</div>
									))}
								</DropdownMenu>
							</UncontrolledDropdown>

							<li class="dropdown nav-item">
								<a class="dropdown-toggle nav-link" href={`https://bungalowsinlonavala.com/hotel/${this.state.url}/${this.state.hotel_id}`} target="_blank">
									<i class="mdi mdi-eye-outline font-24"></i>
								</a>
							</li>


							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<img
										src={profilephoto}
										alt="user"
										className="rounded-circle"
										width="31"
									/>
								</DropdownToggle>
								<DropdownMenu right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
										<div className="">
											<img
												src={profilephoto}
												alt="user"
												className="rounded-circle"
												width="60"
											/>
										</div>
										<div className="ml-2">
											<h4 className="mb-0">{this.state.full_name}</h4>
											<p className=" mb-0">{this.state.email_id}</p>
										</div>
									</div>
									<DropdownItem divider />
									<DropdownItem onClick={this.logout}>
										<i className="fa fa-power-off mr-1 ml-1" /> Logout
									</DropdownItem>
									<DropdownItem divider />
								</DropdownMenu>
							</UncontrolledDropdown>


						</Nav>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);
