import React from 'react';

import moment from 'moment';

import { DatePicker,Checkbox,Radio,Select  } from "antd";

import 'antd/dist/antd.css';

import { message } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';

import {getData} from '../../services/index';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const defaultCheckedList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const { Option } = Select;



class ManageRoom extends React.Component {
	constructor(props) {
		super(props);
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		this.state = {
			user_id : storedArray['data'][0]['user_id'],
			room_type : [],
			indeterminate: false,
			checkAll: false,
			room_id : '',
			start_end_date : '',
			end_date : '',
			available : '',
			is_block : '',
			daylist : ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeText = this.onChangeText.bind(this);
		this.onChangeRadio = this.onChangeRadio.bind(this);
		this.onChangeDate = this.onChangeDate.bind(this);
	}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/room_list',this.state).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							room_type: result['data']
						})
					}
				}
			});
		}
	}


	handleSubmit(){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var room_id = document.getElementById("room_id").value;

		if(room_id==''){
			message.error('Please select room',2);
		}
		else if(this.state.start_end_date==''){
			message.error('Please select date',2);
		}
		else if(this.state.daylist==''){
			message.error('Please select days of week',2);
		}
		else{
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id'],
				room_id : room_id,
				start_end_date : this.state.start_end_date,
				available : this.state.available,
				is_block : this.state.is_block,
				daylist : this.state.daylist,
			}
			getData('extranet/manage_rooms_update',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['status']==200){
					message.config({ top: 150});
					if(result['data']['tag']=='0'){
						message.error(result['data']['msg'],2);
					}else{
						message.success(result['data']['msg'],2);
					}


					}
					else{
					message.error('Room Not Update!',2);
					}
				}
			});
		}
	}

	onChangeText(e){
		this.setState({[e.target.name]:e.target.value});
	}

	onChangeRadio(event) {
		this.setState({
			is_block: event.target.value
		});
	}

	onChangeDate(event) {
		this.setState({
			start_end_date: event
		});
	}

	onChange = checkedList => {
		this.setState({
		  checkedList,
		  daylist : checkedList,
		  indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
		  checkAll: checkedList.length === plainOptions.length,
		});
	}

	onCheckAllChange = e => {
		this.setState({
		  daylist : defaultCheckedList,
		  checkedList: e.target.checked ? plainOptions : [],
		  indeterminate: false,
		  checkAll: e.target.checked,
		});
	}

render() {

return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Manage Room Inventory - Bulk Update</CardTitle>
            <CardBody>
              <form className="login100-form validate-form">
             <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2"  className="mt10">
                  <Label for="exampleCustomSelect">Room Type *</Label>
				</Col>



				<Col sm="12" md="4" >

							<select class="form-control" id="room_id" name="room_id">
                                 <option value="" selected="selected">Select Room</option>
								 {this.state.room_type.map((list) => (
                                 <option value={`${list.room_id}`} >{list.display_name}</option>
								 ))}
                              </select>
				</Col>

				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Select Date *</Label>
				</Col>
				<Col sm="12" md="4" >
					  <FormGroup>
						<RangePicker
						  disabledDate={disabledDate}
						  disabledTime={disabledRangeTime}
						  format="DD MMM, YYYY"
						  name ="start_end_date"
						  onChange={this.onChangeDate}
						/>
					  </FormGroup>
				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Days Of Week</Label>
				</Col>
				<Col sm="12" md="6" >
                  <Form inline>
				  <div className="col-md-10 ant-checkbox-wrapper-check-all">
				 <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}

          >
            Check all
          </Checkbox>
		  </div>
		  <br />
		  <div className="col-md-12 no-paddding">
		  <CheckboxGroup
          options={plainOptions}
          value={this.state.checkedList}
          onChange={this.onChange}
		  name="daylist"
        />
		 </div>
				  </Form>
				  <br/>



				  <Radio.Group name="radiogroup" defaultValue={0}>
      <Radio name="is_block" onChange={this.onChangeRadio} value={1} >Block Inventory</Radio>
      <Radio name="is_block" onChange={this.onChangeRadio} value={0} >UnBlock Inventory</Radio>
    </Radio.Group>

				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Available Inventory *</Label>
				</Col>
				<Col sm="12" md="4" >
                  <Input type="text" name="available" placeholder="Only Digit.." onChange={this.onChangeText}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" className="hidden-xs">
                  &nbsp;
				</Col>
				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit}  >Update Inventory</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
            </form>
            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ManageRoom;
