import React from 'react';
import 'react-datetime/css/react-datetime.css';
import {Accordion, List} from 'antd-mobile';
import '../../assets/scss/antd-mobile.min.css';
import { Link,} from 'react-router-dom';
import { message } from 'antd';
import {
	Table,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	FormGroup,
	CustomInput,
	Input,
	Button
} from 'reactstrap';

import {getData} from '../../services/index';

class documents extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			room_type : [],
			amenities_list : [],
			amenities : [],
			amenities_name : [],
			ep_plan:true,
			cp_plan:false,
			map_plan:false,
			ap_plan:false,
			ep_default_plan:true,
			cp_default_plan:false,
			map_default_plan:false,
			ap_default_plan:false,
			ep_default_div:'hide_div',
			cp_default_div:'show_div',
			map_default_div:'show_div',
			ap_default_div:'show_div'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.activeRateChange = this.activeRateChange.bind(this);
	}



	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.room_list();
			this.amenities();
		}
	}

    activeRateChange(name,event){
		this.setState({[name]: event.target.checked });
	}


    handleChange(event){
		var amenities_id = event.target.value;
		if (event.target.checked == true) {
			var is_active = '1';
		} else {
			var is_active = '0';
		}
		var items = document.getElementsByName('amenities');
		var selectedItems="";
		for(var i=0; i<items.length; i++){
			if(items[i].type=='checkbox' && items[i].checked==true){
				selectedItems+=items[i].value+",";
			}
		}
		this.setState({
			amenities_name: selectedItems
		})
	}


	room_list = () => {
	if (localStorage.getItem('extranetData')){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		this.state.hotel_id = storedArray['data'][0]['hotel_id'];
		getData('extranet/room_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					room_type: result['data']
				})
				}
				else{
					this.setState({
					room_type: []
				})
				}
			}
		});


	}
		}

	amenities() {
		if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id : this.state.hotel_id
			}
			getData('extranet/room_amenities_list',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					amenities_list: result['data']
				})
				}
			}
		});
		}
	}

		AddRoom = () => {
			if (localStorage.getItem('extranetData')) {
				var cp_flag=1;
				var map_flag=1;
				var ap_flag=1;
				var rate_plan=[];

				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_type = document.getElementById("room_type").value;
				var display_name = document.getElementById("display_name").value;
				var room_size_length = document.getElementById("room_size_length").value;
				var room_size_width = document.getElementById("room_size_width").value;
				var total_rooms = document.getElementById("total_rooms").value;
				var bed_type = document.getElementById("bed_type").value;
				var room_view = document.getElementById("room_view").value;
				var extra_bed_type = document.getElementById("extra_bed_type").value;
				var long_description = document.getElementById("long_description").value;
				var adults_base = document.getElementById("adults_base").value;
				var child_base = document.getElementById("child_base").value;
				var adults_max = document.getElementById("adults_max").value;
				var child_max = document.getElementById("child_max").value;
				var infant_max = document.getElementById("infant_max").value;
				var guest_max = document.getElementById("guest_max").value;
				var room_amenities = this.state.amenities_name;

				if(document.getElementById("active_rate_plan2").checked==true){
					var cp_will_be 		= document.getElementById("cp_will_be").value;
					var cp_than 		= document.getElementById("cp_than").value;
					var cp_by_amount 	= document.getElementById("cp_by_amount").value;
					var cp_rate_type 	= document.getElementById("cp_rate_type").value;
					var cp_extra_amount = document.getElementById("cp_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan2").value;
					if(cp_by_amount>0){
						cp_flag=1;
						rate_plan.push({
							will_be:cp_will_be,
							than:cp_than,
							by_amount:cp_by_amount,
							rate_type:cp_rate_type,
							extra_amount:cp_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter CP rate plan amount',2);
						cp_flag=0;
					}
				}

				if(document.getElementById("active_rate_plan3").checked==true){
					var map_will_be 	 = document.getElementById("map_will_be").value;
					var map_than 		 = document.getElementById("map_than").value;
					var map_by_amount 	 = document.getElementById("map_by_amount").value;
					var map_rate_type 	 = document.getElementById("map_rate_type").value;
					var map_extra_amount = document.getElementById("map_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan3").value;
					if(map_by_amount>0){
						map_flag=1;
						rate_plan.push({
							will_be:map_will_be,
							than:map_than,
							by_amount:map_by_amount,
							rate_type:map_rate_type,
							extra_amount:map_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter MAP rate plan amount',2);
						map_flag=0;
					}
				}

				if(document.getElementById("active_rate_plan4").checked==true){
					var ap_will_be 	     = document.getElementById("ap_will_be").value;
					var ap_than 		 = document.getElementById("ap_than").value;
					var ap_by_amount 	 = document.getElementById("ap_by_amount").value;
					var ap_rate_type 	 = document.getElementById("ap_rate_type").value;
					var ap_extra_amount  = document.getElementById("ap_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan4").value;
					if(ap_by_amount>0){
						ap_flag=1;
						rate_plan.push({
							will_be:ap_will_be,
							than:ap_than,
							by_amount:ap_by_amount,
							rate_type:ap_rate_type,
							extra_amount:ap_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter AP rate plan amount',2);
						ap_flag=0;
					}
				}
				if(room_amenities.length==0){
					room_amenities='0';
				}


				var app = {
					hotel_id:hotel_id,
					room_type:room_type,
					display_name:display_name,
					room_size_length:room_size_length,
					room_size_width:room_size_width,
					total_rooms:total_rooms,
					bed_type:bed_type,
					room_view:room_view,
					extra_bed_type:extra_bed_type,
					long_description:long_description,
					adults_base:adults_base,
					child_base:child_base,
					adults_max:adults_max,
					child_max:child_max,
					infant_max:infant_max,
					guest_max:guest_max,
					room_amenities:room_amenities,
					rate_plan:rate_plan
				}


				if(display_name==''){
					message.config({ top: 150});
					message.warning('Display name can not be blank!',2);
				}
				else if(total_rooms==0){
					message.config({ top: 150});
					message.warning('Total rooms can not be 0',2);
				}
				else{
					if(cp_flag>0 && map_flag>0 && ap_flag>0){
						getData('extranet/add_rooms', app).then((result) => {
						let responseJson = result;
						if (responseJson) {
							if (result['status']==200) {
								this.room_list();
								message.config({ top: 150});
								message.success('New Room Added!',2);
								var frm = document.getElementsByName('room-form')[0];
								frm.reset();
							}
							else{
								message.config({ top: 150});
								message.success(result['message'],2);
							}
						}
						});
					}
				}



			}

		};

		DeleteRoom = (room_id) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_id = room_id;
				var app = {
					hotel_id:hotel_id,
					room_id:room_id
				}
				getData('extranet/delete_rooms', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Room Deleted!',2);
							this.room_list();
						}
					}
				});
			}
		};
	handleSubmit(){
		this.setState({redirectToReferrer: true});
	}
	render() {
	var EditRooms;
		if (this.state.redirectToReferrer){
		this.props.history.push("/property/map");
	}
return (
<div>
   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
      <Card>
         <CardTitle className="bg-light border-bottom p-3 mb-0">
            Rooms
         </CardTitle>
         <CardBody>
            <Col sm="12" md="12" lg="12" className="border-bottom mb-20 no-padding">
            <Accordion defaultActiveKey="0" className="my-accordion">
               <Accordion.Panel header="Existing Rooms">


							 <Col sm="12">
						 	<div className="hidden-lg hidden-md hidden-sm">
						 	{this.state.room_type.map((list) => (
						 	<table class="table table-bordered mob_booking_box">
						 		<tbody>
						 		<tr>
						 			<td className="text-left">
						 				 <b>Room Name</b><br/>
										 {list.display_name}
						 			</td>
									<td className="text-right">
									<Link to={`/property/edit_rooms/${list.room_id}`} component={EditRooms}>
									<Button color="primary" className="mr-2 edit_room"><i class="mr-2 mdi mdi-table-edit"></i> Edit</Button>
									</Link><br/>
						 			</td>
						 		</tr>
						 		<tr>
						 			<td colspan="2" className="text-left">
						 				<b>Rate Plan</b><br/>
										<CustomInput checked="checked" type="checkbox" className="mob_edit_rate" id={`rateplans_ep${list.room_id}`} label="EP" />
									 {(list.room_rate_plan).map((inv) =>
																	<CustomInput checked="checked" className="mob_edit_rate" type="checkbox" id={`rateplans_ep${list.room_id}`} label={inv.plan_type} />
									 )}
						 			</td>
						 		</tr>
						 		</tbody>
						 		</table>
						 		))}
						 	</div>
							</Col>

                  <Col md="12" className="no_pad hidden-xs">
                  <Card className="table_list ">
                     <Table hover bordered responsive>
                        <thead>
                           <tr class="bg-light">
                              <th scope="col">Room name</th>
                              <th scope="col">Description</th>
                              <th scope="col">Actions</th>
                              <th scope="col">Rateplan</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.room_type.map((list) => (
                           <tr>
                              <td>{list.display_name}</td>
                              <td>Total Room:  <span className="small_span">{list.display_name}</span><br/>
                                 Room Size: <span className="small_span">{list.room_size_length} Sq.ft. * {list.room_size_width} Sq.ft.</span>
                              </td>
                              <td>
                                 <Link to={`/property/edit_rooms/${list.room_id}`} component={EditRooms}>
                                 <Button color="primary" className="mr-2"><i class="mr-2 mdi mdi-table-edit"></i> Edit</Button>
                                 </Link>
                              </td>
                              <td class="plan-label">
							  <CustomInput checked="checked" type="checkbox" id={`rateplans_ep${list.room_id}`} label="EP" />
								{(list.room_rate_plan).map((inv) =>
                                 <CustomInput checked="checked"  type="checkbox" id={`rateplans_ep${list.room_id}`} label={inv.plan_type} />
								)}
                              </td>
                           </tr>
                           ))}
                        </tbody>
                     </Table>
                  </Card>
                  </Col>
               </Accordion.Panel>
               <Accordion.Panel header="Add Room" className="pad">
                  <form name="room-form">
                     <fieldset>
                        <legend class="m-legend">Rooms Info</legend>
                        <div class="row">
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_roomtype">Room Type<span class="text-error fontBold"></span></label>
                                 <select class="form-control" data-required="true" id="room_type" name="room_type" required="">
                                    <option value="" selected="selected">Select Roomtype</option>
                                    <option value="Deluxe">Deluxe</option>
                                    <option value="Standard">Standard</option>
                                    <option value="Luxury">Luxury</option>
                                    <option value="Master">Master</option>
                                    <option value="Common">Common</option>
                                    <option value="Tent">Tent</option>
                                    <option value="Other">other</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_roomtypename">Display Name<span class="text-error fontBold">*</span></label>
                                 <input class="form-control" data-required="true" id="display_name" maxlength="100" name="display_name" type="text"/>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad" id="room_size">
                              <div class="col-md-12 no_pad">
                                 <label for="id_roomsize">Room size</label>
                                 <div class="row">
                                    <div class="col-md-5 col-xs-5 no_pad">
                                       <input name="room_size_length" id="room_size_length" data-error-message="Value should be a valid length" pattern="^\s*[0-9]+([\.][0-9]+)?\s*$" class="form-control pull-left parsley-validated parsley-error" placeholder="Length (Sq.ft.)" type="text"/>
                                    </div>
                                    <div class="col-md-1 col-xs-2 text-center">
                                       <span class="pull-left"> X </span>
                                    </div>
                                    <div class="col-md-5 col-xs-5 no_pad">
                                       <input pattern="^\s*[0-9]+([\.][0-9]+)?\s*$" name="room_size_width" id="room_size_width" class="form-control pull-left parsley-validated parsley-error" placeholder="Breadth (Sq.ft.)" data-error-message="Value should be a valid breadth" type="text"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label>Total Rooms<span class="text-error fontBold">*</span></label>
                                 <input class="form-control" data-required="true" data-type="digits" required="" onkeyup="check_room()" id="total_rooms" name="noofrooms" type="text"/>
                                 <span class="text-success" id="room_errors"></span>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_bedtype">Bed Type</label>
                                 <select class="form-control" id="bed_type" name="bed_type">
                                    <option value="" selected="selected">---------</option>
                                    <option value="Twin">Twin</option>
                                    <option value="King">King</option>
                                    <option value="Queen">Queen</option>
                                    <option value="Double">Double</option>
                                    <option value="Single">Single</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_roomview">Room View</label>
                                 <select class="form-control" id="room_view" name="room_view">
                                    <option value="Sea View">Sea View</option>
                                    <option value="Valley View">Valley View</option>
                                    <option value="Hill View">Hill View</option>
                                    <option value="Pool View">Pool View</option>
                                    <option value="Garden View">Garden View</option>
                                    <option value="River View">River View</option>
                                    <option value="Lake View">Lake View</option>
                                    <option value="Palace View">Palace View</option>
                                    <option value="Bay View">Bay View</option>
                                    <option value="Jungle View">Jungle View</option>
                                    <option value="City View">City View</option>
                                    <option value="No View">No View</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_extra_bed">Extra Bed Type</label>
                                 <select class="form-control" id="extra_bed_type" name="extra_bed">
                                    <option value="0" selected="selected">No Extra Bed</option>
                                    <option value="Matress">Matress</option>
                                    <option value="Cot">Cot</option>
                                    <option value="Sofa cum bed">Sofa cum bed</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-8 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_description">Long Description</label>
                                 <textarea class="form-control" cols="40" id="long_description" name="long_description" rows="5"></textarea>
                              </div>
                           </div>
                        </div>
                     </fieldset>
                     <fieldset>
                        <legend class="m-legend">Room Occupancy</legend>
                        <div class="row">
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_base_adult_occupancy">Adults (base)</label>
                                 <select class="form-control" id="adults_base" name="adults_base">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2" selected="selected">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_base_child_occupancy">Child (Base)</label>
                                 <select class="form-control" id="child_base" name="child_base">
                                    <option value="0" selected="selected">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_max_adult_occupancy">Adults (Max)</label>
                                 <select class="form-control" id="adults_max" name="adults_max">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3" selected="selected">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_max_child_occupancy">Child (Max)</label>
                                 <select class="form-control" id="child_max" name="child_max">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2" selected="selected">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_max_infant_occupancy">Infant (Max)</label>
                                 <select class="form-control" id="infant_max" name="infant_max">
                                    <option value="0">0</option>
                                    <option value="1" selected="selected">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                           <div class="form-group col-md-4 no_pad no_pad">
                              <div class="col-md-12 no_pad">
                                 <label for="id_max_guest_occupancy">Total Guest (Max)</label>
                                 <select class="form-control" id="guest_max" name="guest_max">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4" selected="selected">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </fieldset>


										 <fieldset class="rate_plan hidden-md hidden-lg hidden-sm">
										 	 <legend class="m-legend">Rateplan</legend>
										 	 <div class="row">
										 			<div class="col-md-12 no_pad">
										 				 <table class="table table-bordered">

										 						<tbody>

										 <tr>

										 <td>
										  <CustomInput checked="true" type="checkbox" id="active_rate_plan1" name="active_rate_plan" label="EP" defaultValue="ep_plan"/>
										 </td>
										 <td>
												<CustomInput checked="true" type="checkbox" id="default_rate_plan_1" name="default_rate_plan" defaultValue="ep" label="Default"/>
										 </td>

										 							 </tr>
										 							 <tr>

										 <td>
										 <CustomInput onChange={this.activeRateChange.bind(this,'cp_plan')} checked={this.state.cp_plan} type="checkbox" id="active_rate_plan2" name="active_rate_plan" label="CP" defaultValue="cp"/>
										 </td>
										 									<td>
										 										 <div class={`form-group ${this.state.cp_default_div}`}>
										 												<div class="rate_plan_pd">
										 													 <label> will be higher than Ep by</label>
										 												</div>
										 										 </div>
																				 <br/>

																				 <div class={`form-group ${this.state.cp_default_div}`}>
																					 <div class="rate_plan_pd">
																							<input class="form-control" name="cp_by_amount" id="cp_by_amount" placeholder="amount" type="text"/>
																							<select class="form-control" id="cp_rate_type" name="cp_rate_type">
																								 <option value="flat">Flat Rate</option>
																								 <option value="percentage">Percentage (%)</option>
																							</select>
																					 </div>
																				</div>
																			<br/>
																			<div class={`form-group ${this.state.cp_default_div}`}>
																			<div class="rate_plan_pd">
																				 <label> Extra Guest Linkage Value</label>
																			</div>
																					<div class="rate_plan_pd">
																						<input class="form-control" name="cp_extra_amount" id="cp_extra_amount" placeholder="amount" type="text"/>
																					</div>
																			 </div>
	                                   </td>
										 							 </tr>
										 							 <tr>

										 <td>
										<CustomInput onChange={this.activeRateChange.bind(this,'map_plan')} checked={this.state.map_plan} type="checkbox" id="active_rate_plan3" name="active_rate_plan" label="MAP" defaultValue="map"/>
										 </td>
										 									<td>
										 										 <div class={`form-group ${this.state.map_default_div}`}>
										 												<div class="rate_plan_pd">
										 													 <label> will be higher than Ep by</label>
										 												</div>
										 										 </div>
										 									<br/>
										 										 <div class={`form-group ${this.state.map_default_div}`}>
										 												<div class="rate_plan_pd">
										 													 <input class="form-control" name="map_by_amount" id="map_by_amount" placeholder="amount" type="text"/>
																							 <select class="form-control" id="map_rate_type" name="map_rate_type">
										 															<option value="flat">Flat Rate</option>
										 															<option value="percentage">Percentage (%)</option>
										 													 </select>
										 												</div>
										 										 </div>
										 										<br/>
										 										<div class={`form-group ${this.state.map_default_div}`}>
																				<div class="rate_plan_pd">
																					 <label> Extra Guest Linkage Value</label>
																				</div>
										 												<div class="rate_plan_pd">
										 													<input class="form-control" name="map_extra_amount" id="map_extra_amount" placeholder="amount" type="text"/>
										 												</div>
										 										 </div>
										 									</td>
										 							 </tr>
										 							 <tr>

										 <td>
										<CustomInput onChange={this.activeRateChange.bind(this,'ap_plan')} checked={this.state.ap_plan} type="checkbox" id="active_rate_plan4" name="active_rate_plan" label="AP" defaultValue="ap"/>
										 </td>
										 									<td>
										 										 <div class={`form-group ${this.state.ap_default_div}`}>
										 												<div class="rate_plan_pd">
										 													 <label> will be higher than Ep by</label>
										 												</div>
										 										 </div>
										 									<br/>
										 										 <div class={`form-group ${this.state.ap_default_div}`}>
										 												<div class="rate_plan_pd">
										 													 <input class="form-control" name="ap_by_amount" id="ap_by_amount" placeholder="amount" type="text"/>
																							 <select class="form-control" id="ap_rate_type" name="ap_rate_type">
										 															<option value="flat">Flat Rate</option>
										 															<option value="percentage">Percentage (%)</option>
										 													 </select>
										 												</div>
										 										 </div>
										 									<br/>
										 										<div class={`form-group ${this.state.ap_default_div}`}>
																				<div class="rate_plan_pd">
																					 <label> Extra Guest Linkage Value</label>
																				</div>
										 												<div class="rate_plan_pd">
										 													<input class="form-control" name="ap_extra_amount" id="ap_extra_amount" placeholder="amount" type="text"/>
										 												</div>
										 										 </div>
										 									</td>
										 							 </tr>
										 						</tbody>
										 				 </table>
										 			</div>
										 	 </div>
										 </fieldset>


                     <fieldset class="rate_plan hidden-xs">
                        <legend class="m-legend">Rateplan</legend>
                        <div class="row">
                           <div class="col-md-12 no_pad">
                              <table class="table table-bordered">
							  <thead>
      <tr>
        <th colspan="4"></th>
        <th>Extra Guest Linkage Value</th>
      </tr>
    </thead>
                                 <tbody>

									<tr>
                                       <td>
                                          <CustomInput checked="true" type="checkbox" id="active_rate_plan1" name="active_rate_plan" label="Active" defaultValue="ep_plan"/>
                                          <CustomInput checked="true" type="checkbox" id="default_rate_plan_1" name="default_rate_plan" defaultValue="ep" label="Default"/>
                                       </td>
									   <td>
									   EP
									   </td>
                                       <td>

                                       </td>
                                       <td>

                                       </td>
                                       <td class="td100">

                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <CustomInput onChange={this.activeRateChange.bind(this,'cp_plan')} checked={this.state.cp_plan} type="checkbox" id="active_rate_plan2" name="active_rate_plan" label="Active" defaultValue="cp"/>
                                       </td>
									   <td>
									   CP
									   </td>
                                       <td>
                                          <div class={`form-group ${this.state.cp_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="cp_will_be" name="cp_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.cp_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="cp_than" name="cp_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class={`form-group ${this.state.cp_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" name="cp_by_amount" id="cp_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.cp_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="cp_rate_type" name="cp_rate_type">
                                                   <option value="flat">Flat Rate</option>
                                                   <option value="percentage">Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class={`form-group ${this.state.cp_default_div}`}>
                                             <div class="rate_plan_pd">
                                               <input class="form-control" name="cp_extra_amount" id="cp_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                           <CustomInput onChange={this.activeRateChange.bind(this,'map_plan')} checked={this.state.map_plan} type="checkbox" id="active_rate_plan3" name="active_rate_plan" label="Active" defaultValue="map"/>
                                       </td>
									   <td>
									   MAP
									   </td>
                                       <td>
                                          <div class={`form-group ${this.state.map_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="map_will_be" name="map_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.map_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="map_than" name="map_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class={`form-group ${this.state.map_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" name="map_by_amount" id="map_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.map_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="map_rate_type" name="map_rate_type">
                                                   <option value="flat">Flat Rate</option>
                                                   <option value="percentage">Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class={`form-group ${this.state.map_default_div}`}>
                                             <div class="rate_plan_pd">
                                               <input class="form-control" name="map_extra_amount" id="map_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                           <CustomInput onChange={this.activeRateChange.bind(this,'ap_plan')} checked={this.state.ap_plan} type="checkbox" id="active_rate_plan4" name="active_rate_plan" label="Active" defaultValue="ap"/>
                                       </td>
									   <td>
									   AP
									   </td>
                                       <td>
                                          <div class={`form-group ${this.state.ap_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="ap_will_be" name="ap_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.ap_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="ap_than" name="ap_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class={`form-group ${this.state.ap_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" name="ap_by_amount" id="ap_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class={`form-group ${this.state.ap_default_div}`}>
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="ap_rate_type" name="ap_rate_type">
                                                   <option value="flat">Flat Rate</option>
                                                   <option value="percentage">Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class={`form-group ${this.state.ap_default_div}`}>
                                             <div class="rate_plan_pd">
                                               <input class="form-control" name="ap_extra_amount" id="ap_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </fieldset>
                     <fieldset>
                        <legend class="m-legend">Room Amenities</legend>
                        <div class="form-group">
                           {this.state.amenities_list.map((list) => (
                           <div className="bg-light border-bottom mb-20">
                              <FormGroup>
                                 <Input type="text" value={`${list.amenities_type}`} disabled />
                              </FormGroup>
                              <Col sm="12" md="12" >
                              <FormGroup>
                                 <Row>
                                    <Col sm="12" md="12" >
                                    {(list.amenities).map((inv) =>
                                    <div className="form-check form-check-inline col-md-4 mr-0">
                                       <CustomInput type="checkbox" id={`amenities_${inv.id}`} name="amenities_name" label={`${inv.name}`} defaultValue={`${inv.id}`} onChange={this.handleChange} />
                                    </div>
                                    )}
                                    </Col>
                                 </Row>
                              </FormGroup>
                              </Col>
                           </div>
                           ))}
                        </div>
                     </fieldset>
                     <Col sm="12" md="12" lg="12">
                     <div class="form-group">
                        <Row>
                           <Col sm="12" md="23" >
                           <Button color="primary" className="pull-right" onClick={this.AddRoom}>Save & Add New Room</Button>
                           </Col>
                        </Row>
                     </div>
                     </Col>
                  </form>
               </Accordion.Panel>
            </Accordion>
            </Col>
            <Col sm="12" md="12" lg="12">
            <FormGroup>
               <Row>
                  <Col sm="12" md="12" className="center">
                  <Button color="primary" onClick={this.handleSubmit}>Continue</Button>
                  </Col>
               </Row>
            </FormGroup>
            </Col>
         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default documents;
