import React from 'react';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';
import {getData} from '../../services/index';
import { message } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



class BankDetails extends React.Component {
constructor(props) {
super(props);
this.state = {
	account_no : '',
	account_holder_name : '',
	branch_name : '',
	ifsc_code : '',
	branch_code : '',
	bank_name : '',
	bank_code : '',
	pan_number : '',
	name_on_pan_card : '',
	persons : [],
};

this.handleSubmit = this.handleSubmit.bind(this);
}


	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/bank_details',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							ListData: result['data']
						});
						document.getElementById("account_no").value = result['data'][0]['account_no'];
						document.getElementById("account_holder_name").value = result['data'][0]['account_holder_name'];
						document.getElementById("branch_name").value = result['data'][0]['branch_name'];
						document.getElementById("ifsc_code").value = result['data'][0]['ifsc_code'];
						document.getElementById("branch_code").value = result['data'][0]['branch_code'];
						document.getElementById("bank_name").value = result['data'][0]['bank_name'];
						document.getElementById("bank_code").value = result['data'][0]['bank_code'];
						document.getElementById("pan_number").value = result['data'][0]['pan_number'];
						document.getElementById("name_on_pan_card").value = result['data'][0]['name_on_pan_card'];
						this.state.account_no = result['data'][0]['account_no'];
						this.state.branch_name = result['data'][0]['branch_name'];
						this.state.account_holder_name = result['data'][0]['account_holder_name'];
						this.state.ifsc_code = result['data'][0]['ifsc_code'];
						this.state.branch_code = result['data'][0]['branch_code'];
						this.state.bank_name = result['data'][0]['bank_name'];
						this.state.bank_code = result['data'][0]['bank_code'];
						this.state.pan_number = result['data'][0]['pan_number'];
						this.state.name_on_pan_card = result['data'][0]['name_on_pan_card'];
					}
				}
			});
		}
	}


	handleSubmit(){
		this.setState({redirectToReferrer: true});
	}

render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/rooms");
}
return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
             <CardTitle className="bg-light border-bottom p-3 mb-0">
				Account Info
			  </CardTitle>
            <CardBody>

			 <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Account No *</Label>
				   <Input type="text" name="account_no" id="account_no" value="" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Account Holder Name *</Label>
				   <Input type="text" name="account_holder_name" id="account_holder_name" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10">
					<Label for="exampleCustomSelect">Branch Name</Label>
					<Input type="text" name="branch_name" id="branch_name" disabled/>

				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4">
                  <Label for="exampleCustomSelect">IFSC Code *</Label>
				   <Input type="text" name="ifsc_code" id="ifsc_code" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Branch Code</Label>
				   <Input type="text" name="branch_code" id="branch_code" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10" >
					<Label for="exampleCustomSelect">Bank Name *</Label>
					<Input type="text" name="bank_name" id="bank_name" disabled/>

				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4">
                  <Label for="exampleCustomSelect">Bank Code</Label>
				   <Input type="text" name="bank_code" id="bank_code" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Pan Number</Label>
				   <Input type="text" name="pan_number" id="pan_number" disabled/>

				</Col>
				<Col sm="12" md="4" className="mt10">
					<Label for="exampleCustomSelect">Name On Pan Card</Label>
					<Input type="text" name="name_on_pan_card" id="name_on_pan_card" disabled/>

				</Col>
				</Row>
                </FormGroup>
			</Col>


			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="12" className="center">
                  <Button color="primary" onClick={this.handleSubmit}>Save & Continue</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default BankDetails;
