import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Select,message  } from "antd";
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'antd/dist/antd.css';
import $ from 'jquery';
import {getData} from '../../services/index';
import {
  CardImg,
  CardImgOverlay,
  CardText,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
  ModalBody,
  ModalHeader,
  Modal
} from 'reactstrap';

import photo_preload from '../../assets/images/photo_preload.png';
const { Option } = Select;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class Photos extends React.Component {
constructor(props) {
super(props);
this.state = {
photo_list:[],
room_type:[],
photo_tag:[],
modal1: false,
persons : [],
roommaster: [],
showhide: [],
deletephoto: [],
masterupdate: [],
file:null,
imgSrc:null,
imgTitle:[],
hotel_id:'4',
previewVisible: false,
    previewImage: '',
    fileList: [],
};

this.onChange = this.onChange.bind(this);
this.toggle = this.toggle.bind(this);
this.onSubmit = this.onSubmit.bind(this);
this.MoveImg = this.MoveImg.bind(this);
this.uploadFile = this.uploadFile.bind(this);
}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.photo_list();
		}
	}

	photo_list(){
		if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/hotel_photos',this.state).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						photo_list: []
					});
					this.setState({
						photo_list: result['data']
					});
				}
			}
			});
			getData('extranet/room_list',this.state).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					room_type: result['data']
				})
				}
			}
			});
			getData('extranet/photo_tag',this.state).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					photo_tag: result['data']
				})
				}
			}
		});
		}
  }
		ChangeHotelMaster = (pid,event) => {
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			if (event.target.checked == true) {
				var is_active = '1';
			} else {
				var is_active = '0';
			}
			var app = {
				hotel_id:hotel_id,
				photo_id:pid,
				is_active:is_active
			}
			getData('extranet/hotel_master', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
				}
			});
  }

  ChangeRoomMaster = (pid,rid,event) => {
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			if (event.target.checked == true) {
				var is_active = '1';
			} else {
				var is_active = '0';
			}
			var app = {
				hotel_id:hotel_id,
				photo_id:pid,
				room_id:rid,
				is_active:is_active
			}
			getData('extranet/room_master', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
					if (result['status']==400) {
						message.error('Please select room first',2);
					}
				}
			});
  }

    ChangeRoom = (pid,event) => {
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var rid = event.target.value;
			var app = {
				hotel_id:hotel_id,
				photo_id:pid,
				room_id:rid
			}
			getData('extranet/photo_change_room', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
				}
			});
  }

     ChangeTag = (pid,event) => {
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var rid = event.target.value;
			var app = {
				hotel_id:hotel_id,
				photo_id:pid,
				tag:rid
			}
			getData('extranet/tag_change_room', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
				}
			});
  }

	DeletePhoto = (pid,event) => {
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id:hotel_id,
				photo_id:pid
			}
			getData('extranet/delete_photo', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
				}
			});
	}


 toggle() {
    this.setState({
      modal1: !this.state.modal1
    });
  }





	async onSubmit(e){
		document.getElementById("img_preview").style = "display: block";
        e.preventDefault();
        let res = await this.uploadFile(this.state.file);

		document.getElementById('file_upload').value='';
		//document.getElementById("img_preview").style = "display: none";
		this.setState({
			imgSrc:null
		});
		setTimeout(() => {
			this.photo_list();
		}, 100);
		setTimeout(() => {
			this.photo_list();
		}, 2000);
    }

	onChange(e) {
		document.getElementById("img_preview").style = "display: block";
        this.setState({file:e.target.files});
		this.setState({
			imgSrc: e.target.files
		});
		setTimeout(function(){
			$('.rcw-send').eq(0).click();
		}, 2000);
    }


    async uploadFile(file){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		for(var x = 0; x<this.state.file.length; x++) {
			document.getElementById("img_preview").style = "display: block";
			const formData = new FormData();
			formData.append('hotel_id',hotel_id);
			formData.append('avatar',this.state.file[x]);
			axios.post("https://api.bungalowsinlonavala.com/v1/extranet/image_upload", formData, {
				//
			})
			.then((response) => {
				if(response['status']==200) {
				  document.getElementById("img_preview").style = "display: none";
				  this.photo_list();
				}
			 })
			setTimeout(() => {
				this.photo_list();
			}, 100);
		}
    }

	MoveImg = (event) => {
		var imageids_arr = [];
		$('#sortable .photo_list').each(function(){
			var id = $(this).attr('id');
			var split_id = id.split("_");
			imageids_arr.push(split_id[1]);
		});
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id:hotel_id,
				photo_list:imageids_arr
			}
			getData('extranet/sort_photo', app).then((result) => {
				let responseJson = result;
				if (responseJson) {
					if (result['status']==200) {
						this.photo_list();
					}
				}
			});
	}

render() {
const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
})(LinearProgress);
		 const { previewVisible, previewImage, fileList } = this.state;



return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
             <CardTitle className="bg-light border-bottom p-3 mb-0">
				Photos
			  </CardTitle>
            <CardBody>

			<form onSubmit={ this.onSubmit }>
	<div class="clearfix img_upload">
	<label for="file_upload">
    <span class="ant-upload-picture-card-wrapper">
      <div class="ant-upload-list ant-upload-list-picture-card"></div>
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
         <span tabindex="0" class="ant-upload" role="button">
			<input id="file_upload" name="file_upload" multiple type="file"  onChange={this.onChange}  />
            <div>
               <i class="mr-2 mdi mdi-cloud-upload"></i>
               <div class="ant-upload-text">Upload</div>
            </div>
         </span>
      </div>
   </span>
   </label>
</div>
<button type="submit" class="rcw-send"></button>
 </form>

			<hr/>


			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
					<Row id="sortable">
					<div id="img_preview" class="mb-20 photo_list col-sm-12 col-md-3 img_preview">
   <div class="mb-0 card">
      <img width="100%" src={photo_preload} class="card-img-top"/>
      <div class="image-title-pad card-body">
		<ColorLinearProgress />
      </div>
   </div>
</div>

					{this.state.photo_list.map((list) => (
						<Col sm="12" md="3" className="mb-20 photo_list col-xs-6" id={`image_${list.photo_id}`}>
							  <Card className="mb-0">
								<CardImg top width="100%" src={list.image} onMouseUp={this.MoveImg.bind(this)}/>
								<span class="delete-icon" onClick={this.DeletePhoto.bind(this,list.photo_id)}><i class="fa fa-trash white" aria-hidden="true"></i></span>
								<CardBody className="image-title-pad">
								  <Input type="select" className="custom-select" onChange={this.ChangeRoom.bind(this,list.photo_id)}>
								  <option value="">Select Room</option>
									{this.state.room_type.map((room) =>

									<option value={`${room.room_id}`} selected={`${list.room_type==room.room_id?'selected':''}`}>{room.display_name}</option>


									)}
								  </Input>
								  <Input type="select" className="custom-select" onChange={this.ChangeTag.bind(this,list.photo_id)}>
								  <option value="">Select Tag</option>
									{this.state.photo_tag.map((tag_list) =>

									<option value={`${tag_list.tag}`} selected={`${list.view_name==tag_list.tag?'selected':''}`}>{tag_list.tag}</option>


									)}
								  </Input>
								</CardBody>
							  </Card>





					<Col sm="12" md="12" className="no-pad master_tag">
					<CustomInput type="checkbox" id={`photo_${list.photo_id}`} checked={`${list.master>0?'true':''}`} label="Set Hotel Master" onChange={this.ChangeHotelMaster.bind(this,list.photo_id)} />
					<CustomInput type="checkbox" id={`rphoto_${list.photo_id}`} checked={`${list.room_master>0?'true':''}`} label="Set Room Master"  onChange={this.ChangeRoomMaster.bind(this,list.photo_id,list.room_type)} />



					 </Col>
						</Col>
						))}

					</Row>
                </FormGroup>
			</Col>






            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default Photos;
