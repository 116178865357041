import React from 'react';
import axios from 'axios';
import {getData} from '../../services/index';
import { message,TimePicker } from 'antd';
import moment from 'moment';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



const format = 'HH:mm';
class BasicInfo extends React.Component {
constructor(props) {
super(props);
this.state = {
persons: [],
persons1: [],
hotel_name : '',
built_year : '',
hotel_type : '',
star_rating : '',
locality : '',
hotelier_name : '',
no_restaurant : '',
checkin_time : '',
checkout_time : '',
tac : '',
street_address : '',
zipcode : '',
hotel_review : '',
hotel_description : '',
country : '',
city : '',
state : '',
hotel_id : '',
ListData : [],
UpdateData : [],
};


this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
}

handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		 var app = {
				hotel_id : storedArray['data'][0]['hotel_id'],
				hotel_name : this.state.hotel_name,
				hotelier_name : this.state.hotelier_name,
				built_year : this.state.built_year,
				no_restaurant : this.state.no_restaurant,
				checkin_time : this.state.checkin_time,
				checkout_time: this.state.checkout_time,
				street_address: this.state.street_address,
				locality : this.state.locality,
				country : this.state.country,
				state : this.state.state,
				city : this.state.city,
				tac : this.state.tac,
				zipcode : this.state.zipcode,
				hotel_type : this.state.hotel_type,
				star_rating : this.state.star_rating
			}


	  getData('extranet/basic_info_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				message.success('Basic Info Update Successfull!',2);
				this.setState({redirectToReferrer: true});
				}
				else{

				}
			}
		});

}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/basic_info',this.state).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
					this.setState({
						ListData: result['data']
					});
					this.setState(() => ({
						hotel_name : result['data'][0]['hotel_name'],
						hotelier_name : result['data'][0]['hotelier_name'],
						hotel_type : result['data'][0]['hotel_type'],
						star_rating : result['data'][0]['star_rating'],
						tac : result['data'][0]['tac'],
						hotelier_name : result['data'][0]['hotelier_name'],
						built_year : result['data'][0]['built_year'],
						no_restaurant : result['data'][0]['no_restaurant'],
						checkin_time : result['data'][0]['checkin_time'],
						checkout_time : result['data'][0]['checkout_time'],
						street_address : result['data'][0]['street_address'],
						locality : result['data'][0]['locality'],
						country : result['data'][0]['country'],
						state : result['data'][0]['state'],
						city : result['data'][0]['city'],
						zipcode : result['data'][0]['zipcode'],
						hotel_description : result['data'][0]['hotel_description'],
						hotel_review : result['data'][0]['hotel_review']
					}));
					}
				}
			});
		}
	}



	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/contact_info");
}
function onChange(time, timeString) {
  console.log(time, timeString);
}
return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
			<CardTitle className="bg-light border-bottom p-3 mb-0">Basic Info</CardTitle>
            <CardBody>
			<form className="login100-form validate-form">
             <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Hotelier Name *</Label>
				   <Input type="text" defaultValue={this.state.hotelier_name} name="hotelier_name" id="hotelier_name" onChange={this.onChange} disabled/>
				</Col>
				<Col sm="12" md="4" className="mt10">
                  <Label for="exampleCustomSelect">Display Name *</Label>
				   <Input type="text" defaultValue={this.state.hotel_name} name="hotel_name" id="hotel_name" onChange={this.onChange} disabled/>
				</Col>
				<Col sm="12" md="4" className="mt10">
					<Label for="exampleCustomSelect">Hotel Type</Label>
                  <Input type="text" defaultValue={this.state.hotel_type} name="hotel_type" id="hotel_type" onChange={this.onChange} className="custom-select" disabled>

                  </Input>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			 <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Star Rating *</Label>
				   <Input type="text" defaultValue={this.state.star_rating} name="star_rating" id="star_rating" onChange={this.onChange} className="custom-select" disabled>

                  </Input>
				</Col>
				<Col sm="12" md="4" className="mt10" >
                  <Label for="exampleCustomSelect">Built Year</Label>
				   <Input type="text" defaultValue={this.state.built_year} name="built_year" id="built_year" onChange={this.onChange}/>
				</Col>
				<Col sm="12" md="4" className="mt10" >
					<Label for="exampleCustomSelect">No. of Restaurant</Label>
					<Input type="text" defaultValue={this.state.no_restaurant} name="no_restaurant" id="no_restaurant" onChange={this.onChange}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Checkin Time *</Label>
				   <div>
				   <TimePicker use12Hours format="h:mm a" onChange={onChange} name="checkin_time" id="checkin_time"/>
				   Selected Time:{this.state.checkin_time}
				  </div>
				</Col>
				<Col sm="12" md="4"  className="mt10">
                  <Label for="exampleCustomSelect">Checkout Time *</Label>
				   <div >
					<TimePicker use12Hours format="h:mm a" onChange={onChange} name="checkout_time" id="checkout_time"/>
					Selected Time:{this.state.checkout_time}
				  </div>
				</Col>
				<Col sm="12" md="4"  className="mt10" className="mt10" className="mt10">
					<Label for="exampleCustomSelect">TAC (in %)</Label>
					<Input type="number" name="tac" id="tac"  defaultValue={this.state.tac} onChange={this.onChange} disabled/>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			<FormGroup>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
				PROPERTY ADDRESS
			  </CardTitle>
			</FormGroup>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Street Address *</Label>
				    <Input type="textarea" defaultValue={this.state.street_address} name="street_address" id="street_address" onChange={this.onChange} rows="5" />
				</Col>
				 <Col md="8" className="row">
				<Col sm="12" md="6"  className="no_pad mt10">
				<FormGroup>
                  <Label for="exampleCustomSelect">Country *</Label>
				   <Input type="select" name="country" id="country" onChange={this.onChange} className="custom-select" >
					{this.state.ListData.map((list) => (
					list.country_list.map(data =>
                    <option value={data.country_name} selected={ data.country_name === list.country }>{data.country_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6"  className="no_pad">
				<FormGroup>
                  <Label for="exampleCustomSelect">State *</Label>
				   <Input type="select" name="state" id="state" onChange={this.onChange}  className="custom-select" >
                    {this.state.ListData.map((list) => (
					list.state_list.map(data2 =>
                    <option value={data2.state_name} selected={ data2.state_name === list.state }>{data2.state_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6"  className="no_pad">
				  <FormGroup>
				  <Label for="exampleCustomSelect">City *</Label>
				   <Input type="select" name="city" id="city" onChange={this.onChange} className="custom-select" >
                    {this.state.ListData.map((list) => (
					list.city_list.map(data1 =>
                    <option value={data1.city_name} selected={ data1.city_name === list.city }>{data1.city_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6"  className="no_pad">
				  <FormGroup>
				  <Label for="exampleCustomSelect">Locality *</Label>
				   <Input type="text" name="locality" defaultValue={this.state.locality} id="locality" onChange={this.onChange} className="custom-select" >

                  </Input>
				  </FormGroup>
				</Col>
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Zipcode *</Label>
				    <Input type="text" name="zipcode" defaultValue={this.state.zipcode} id="zipcode" onChange={this.onChange}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="12" className="center">
                  <Button color="primary" onClick={this.handleSubmit}>Save & Continue</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default BasicInfo;
