import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        All Rights Reserved by <b>Bungalows In Lonavala</b>
      </footer>
    );
  }
}
export default Footer;
