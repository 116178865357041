import React from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {getData} from '../../services/index';
import { message } from 'antd';


import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



//var date = new Date();



class ChangePassword extends React.Component {
constructor(props) {
super(props);
this.state = {
			new_pass: '',
			conf_pass: '',
			hotel_id: '',
			persons : [],
		};
this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
}



  handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		 var app = {
				hotel_id : storedArray['data'][0]['user_id'],
				conf_pass : this.state.conf_pass,
				new_pass : this.state.new_pass,
			}

	  getData('extranet/extranet_change_pass',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				message.config({ top: 150});
				if(result['data']['tag'] == '0'){
					message.error(result['data']['msg'],2);
				}else{
					message.success(result['data']['msg'],2);
				}


				}
				else{
				message.error('Room Not Update!',2);
				}

			}
		});
  }

  onChange(e){
		this.setState({[e.target.name]:e.target.value});

	}

render() {

if (this.state.redirectToReferrer){
			window.location.reload(false);

		}


return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Change Password</CardTitle>
            <CardBody>

             <form className="login100-form validate-form">
			 <Col sm="12" md="12" lg="12">
			 <div id="alert" className="m-b-10"></div>
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">New Password</Label>
							<Input type="password" name="new_pass" onChange={this.onChange}/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="12" lg="12">
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">Confirm Password</Label>
							<Input type="password" name="conf_pass" onChange={this.onChange}/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>



			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>

				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit} >Change Now</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ChangePassword;
