import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import {
Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Table,UncontrolledDropdown,DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { message } from 'antd';
import { Link, NavLink} from 'react-router-dom';

import {getData} from '../../services/index';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class ManageHotel extends React.Component {
constructor(props) {
super(props);
this.state = {
ListData : []
};
}

componentDidMount() {
	this.checkData();


  }

checkData(){
	if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
			}));


		var user_id = storedArray['data'][0]['user_id'];
		var app = {user_id : user_id}
		getData('extranet/manage_hotel',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					ListData: result['data']
				})
				}else{
					//
				}
			}
		});
	}
}

  submit = (event) => {
    confirmAlert({
      title: 'Are you sure, you want to delete?',
      message: '',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

		var user_id = event;
		var app = {
				hotel_id : user_id,
			}

	  getData('extranet/hotel_delete',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				   message.config({ top: 150});
				   message.success('Hotel Delete Succecfull!',2);
					this.checkData();
				}
				else{
				message.error('User Not Delete!',2);
				}

			}
		});


	}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


render() {

var BookingDetails;
return (
<div>

   <Row>
      <Col md="12">
      <Card>
		  <CardTitle className="bg-light border-bottom p-3 mb-0">All Hotels</CardTitle>
         <CardBody>
            <Table responsive>
              <thead>
                <tr>

                  <th>Hotel ID</th>
                  <th>Name</th>
				  <th>Hotel Name</th>
                  <th>Hotel Email</th>
                  <th>Hotel Phone</th>
                  <th>city</th>
				  <th>Action</th>
                </tr>
              </thead>
              <tbody>
			  {this.state.ListData.map((list) => (
                <tr>
                  <td>{list.hotel_id}</td>
                  <td>{list.full_name}</td>
                  <td>{list.hotel_name}</td>
                  <td>{list.email}</td>
                  <td>{list.phone}</td>
                  <td>{list.city}</td>
				  <td>

				  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Action
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={this.submit.bind(this,list.hotel_id)}><i className="fas fa-trash-alt"></i> Delete</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
				  </td>
                </tr>
			  ))}
              </tbody>
            </Table>

         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default ManageHotel;
