import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button

} from 'reactstrap';


import {getData} from '../services/index';
import loader from '../assets/images/please_wait.gif';


class Login2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentWillMount() {
			var token = this.props.match.params.id;
			var app = {
				token: token
			}
			getData('extranet/verify_token',app).then((result) => {
				let responseJson = result;
				if(result.status==200){
					let responseJson = result;
					if(responseJson.data){
						localStorage.setItem('extranetData',JSON.stringify(responseJson));
						this.props.history.push("/property/contact_info");
					}
				}
				});
	}

	render() {
		return <div className="auth-wrapper  align-items-center d-flex" >
			<div className="container">
				<div>
					<Row className="no-gutters justify-content-center">
						<Col md="6" lg="6" >
								<div><img src={loader} class="width100"/></div>
						</Col>
					</Row>
				</div>
			</div>
		</div>;
	}
}

export default Login2;
