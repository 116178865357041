import React from 'react';

import moment from 'moment';

import { DatePicker,Checkbox,Select  } from "antd";

import 'antd/dist/antd.css';

import { message } from 'antd';

import MultipleDatePicker from '../../lib';

import { Slider, InputNumber } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';


import {getData} from '../../services/index';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

class ManageOffer extends React.Component {


	constructor(props) {
		super(props);
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		this.state = {
			hotel_id : storedArray['data'][0]['hotel_id'],
			indeterminate: false,
			start_end_date : '',
			discount : '5',
			daylist : '',
			blackout_days : ''
		};
		this.onChangeDate = this.onChangeDate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChangeSlider = this.onChangeSlider.bind(this);
	}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
	}

	onChangeDate(event) {
	  this.setState({
		start_end_date: event,

	  });
	}

	onSubmit(event) {
		this.setState({
			start_end_date: event,
		});
	}

	handleSubmit(){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		if(this.state.blackout_days!='')
		{
			var blackout=this.state.blackout_days;
		}
		else{
			var blackout='';
		}
		if(this.state.start_end_date==''){
			message.error('Please select date',2);
		}
		else{
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id'],
				start_end_date : this.state.start_end_date,
				discount : this.state.discount,
				blackout:blackout
			}

			getData('extranet/manage_booster_update',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['status']==200){
						if(result['data']['tag']=='0'){
							message.error(result['data']['msg'],2);
						}else{
							message.success(result['data']['msg'],2);
						}
					}
				}
			});
		}
	}

	onChangeSlider = value => {
		this.setState({
			discount: value,
		});
	};

render() {
const { discount } = this.state;
return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Add New Booster</CardTitle>
            <CardBody>

             <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="3"  className="mt10">
                  <Label for="exampleCustomSelect">Choose the coupon you wish to offer *</Label>
				</Col>
				<Col sm="12" md="4" >
					<Slider
						min={5}
						max={35}
						onChange={this.onChangeSlider}
						value={typeof discount === 'number' ? discount : 0}
					  />

				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="3" >
                  <Label for="exampleCustomSelect">Select Date *</Label>
				</Col>
				<Col sm="12" md="4" >
					  <FormGroup>
						<RangePicker
      disabledDate={disabledDate}
      disabledTime={disabledRangeTime}
      format="DD MMM, YYYY"
	  name ="start_end_date"
	  onChange={this.onChangeDate}
    />
					  </FormGroup>
				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="3" >
                  <Label for="exampleCustomSelect">Blackout Dates</Label>
				</Col>
				<Col sm="12" md="4" className="multipledatepicker">
					<MultipleDatePicker  onSubmit={dates => this.setState({blackout_days : dates})} minDate={new Date()} />
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="3" >
                  <Label for="exampleCustomSelect">&nbsp;</Label>
				</Col>
				<Col sm="12" md="4">
					<Button color="primary" onClick={this.handleSubmit}>Update Inventory</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>


            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ManageOffer;
