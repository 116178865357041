import React from 'react';

import 'react-datetime/css/react-datetime.css';
import axios from 'axios';
import {getData} from '../../services/index';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
  
} from 'reactstrap';



class Amenities extends React.Component {
constructor(props) {
super(props);
this.state = {
	amenities_list : []
};

this.handleSubmit = this.handleSubmit.bind(this);
this.handleChange = this.handleChange.bind(this);
}

	handleChange(event){
		if (localStorage.getItem('extranetData')) {
			var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = extranetArray['data'][0]['hotel_id'];
			var amenities_id = event.target.value;
			if (event.target.checked == true) {
				var is_active = '1';
			} else {
				var is_active = '0';
			}
			var app = {
				hotel_id: hotel_id,
				amenities_id: amenities_id,
				is_active: is_active
			}
			getData('extranet/amenities_update', app).then((result) => { this.amenities();  });	
		}
	}
	
	amenities() {
		if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/amenities_list',this.state).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({ 
					amenities_list: result['data']					
				})				
				}
			}
		});	
		}
	}
	
	
	
	handleSubmit = (event) => {	
		this.setState({redirectToReferrer: true});
	}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/amenities_list',this.state).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
					this.setState({ 
						amenities_list: result['data']					
					})				
					}
				}
			});	
		}
	}	

render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/policy");
}

return (
<div>

   
   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
				Amenities
			  </CardTitle>
            <CardBody>
			
			<form className="login100-form validate-form">
			
			{this.state.amenities_list.map((list) => (
			
             <div className="bg-light border-bottom mb-20">
			 <FormGroup>
				<Input type="text" value={`${list.amenities_type}`} disabled />
			 </FormGroup>
				<Col sm="12" md="12" >
                <FormGroup>
				<Row>
				<Col sm="12" md="12" >
				
				{(list.amenities).map((inv) =>			
					<div className="form-check form-check-inline col-md-4 mr-0">
					  <CustomInput type="checkbox" id={`amenities_${inv.id}`} name="amenities[]" label={`${inv.name}`} defaultValue={`${inv.id}`} checked={`${inv.is_active>0?'true':''}`} onChange={this.handleChange} />
					</div>
					)}

				</Col>
				</Row>				
                </FormGroup>
				</Col>	
			</div>
			
			))}
		
			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="12" >	
                  <Button color="primary" onClick={this.handleSubmit} >Continue</Button>
				</Col>	
				</Row>				
                </FormGroup>
			</Col>
			
			</form>
             
            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default Amenities;