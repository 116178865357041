import React from 'react';
import axios from 'axios';
import {getData} from '../../services/index';
import {Link} from 'react-router-dom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';


import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  Button,
  FormFeedback
} from 'reactstrap';

import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import { DatePicker,Popover,message,Checkbox,Drawer } from 'antd';

import ac_img from "../../assets/icons/ac.png";
import breakfast_img from "../../assets/icons/breakfast.png";
import pure_veg from "../../assets/icons/pure_veg.png";
import pet_img from "../../assets/icons/pet.png";
import pool_img from "../../assets/icons/pool.png";
import restaurant_img from "../../assets/icons/restaurant.png";
import wifi_img from "../../assets/icons/wifi.png";
import $ from 'jquery';


const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'MMM DD, YYYY';
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  return current && current < moment().startOf('day');
}
function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}
function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const now = new Date();
var yesterday = Datetime.moment().subtract(1, "day");
var valid = function(current) {
  return current.isAfter(yesterday);
};
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
var d = new Date();
const curr_checkin = monthNames[d.getMonth()] + ' ' + (d .getDate()) + ', ' + d .getFullYear();
const curr_checkout = monthNames[d.getMonth()] + ' ' + (d .getDate()+1) + ', ' + d .getFullYear();

class AddNewHotel extends React.Component {
	 
	constructor(props) {
	
		super(props);
		this.defaultOption = { "id": "-1", "value": "" };
		this.state = {
			RoomData: [],
			persons1: [],
			child_age_rows: [],
			question: "",
            options: [this.defaultOption],
			rows: [],
			room_double_sell_rate : '',
			room_single_sell_rate : '',
			checkin_time : '',
			checkout_time : '',
			adult_qty:2,
			child_qty:0,
			room_qty:1,
			email : '',
			phone : '',
			no_rooms:0,
			hotel_id : '',
			ListData : [],
			UpdateData : [],
			CityData:[],
			StateData:[]
		};
		
		this.onChange = this.onChange.bind(this);
		this.booking = this.booking.bind(this);
	}

	
	componentDidMount() {
		window.addEventListener('scroll', this.rightfilterScroll, true);
	}

	rightfilterScroll = () => {
		var sticky = document.querySelectorAll('.sticky');
		var s_height=$(document).height();
		if(s_height>=2200){
			var hh=430;
		}
		else{
			var hh=370;
		}
		if(document.body.scrollTop > 100 && ((document.body.scrollTop+$(window).height())<=$(document).height()-hh)) {
			for (var i = 0; i < sticky.length; ++i) {
				sticky[i].classList.add('scroll_sticky');
				sticky[i].classList.remove('scroll_sticky_bottom');
			}
		}
		else if((document.body.scrollTop+$(window).height()) > $(document).height() - hh) {
			for (var i = 0; i < sticky.length; ++i) {
				sticky[i].classList.add('scroll_sticky_bottom');
			}
		}
		else {
			for (var i = 0; i < sticky.length; ++i) {
				sticky[i].classList.remove('scroll_sticky');
				sticky[i].classList.remove('scroll_sticky_bottom');
			}
		}
    }

	componentWillMount() {
		window.scrollTo(0, 0);
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
		document.querySelector(".loader-wrapper").style = "display: none";
		}, 500);
		document.getElementsByTagName('body')[0].classList.add("hide_mobile_footer");
		this.checkdata();
		var tempDate = new Date();
		var date = new Date(+now) ;
		var edate = date.setDate(date.getDate() + 1);
		this.setState({
			default_checkin: tempDate,
			default_checkout: date,
		});
	}

	checkdata() {
		
		var tempDate = new Date();
		var date = new Date(+now);
		const cur_date = new Date();
		var next_date = new Date(cur_date.setTime(cur_date.getTime() + 1 * 86400000));
		var room = parseInt(localStorage.getItem("room"));
		var adult = parseInt(localStorage.getItem("adult"));
		var child = parseInt(localStorage.getItem("child"));
		var checkin = localStorage.getItem("checkin");
		var checkout = localStorage.getItem("checkout");
		if(child > 0){
			child = child;
		}else{
			child = parseInt('0');
		}
		function convert(str) {
			var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
			return [date.getFullYear(), mnth, day].join("-");
		}
		if(localStorage.getItem("checkin")){
			var date1 = convert(checkin);
			var date2 = convert(checkout);
			var checkin_Date = new Date(checkin);
			var checkout_Date = new Date(checkout);
			if(date1<convert(date)){
				var date1 = convert(date);
				var date2 = convert(next_date);
				var checkin_Date = new Date(date);
				var checkout_Date = new Date(next_date);
				localStorage.setItem("checkin",date);
				localStorage.setItem("checkout",next_date);
			}
		}
		else{
			localStorage.setItem('room_default', 'true');
			var date1 = convert(date);
			var date2 = convert(next_date);
			var checkin_Date = new Date(date);
			var checkout_Date = new Date(next_date);
			localStorage.setItem("checkin",date);
			localStorage.setItem("checkout",next_date);
			localStorage.setItem("room","1");
		    localStorage.setItem("adult","2");
		    localStorage.setItem("child","0");
			var room = 1;
			var adult = 2;
			var child = 0;
		}

		
		var hotel_id = this.props.match.params.id;

		this.setState({
			checkin_Date: checkin_Date,
			checkout_Date: checkout_Date,
			checkin: localStorage.getItem("checkin"),
			checkout: localStorage.getItem("checkout"),
			room_qty: room,
			adult_qty: adult,
			child_qty: child,
		});

		this.state.hotel_id = hotel_id
		
		
		var adult = parseInt(localStorage.getItem("adult"));
		var app = {
			hotel_id: hotel_id,
			checkin: checkin_Date,
			checkout: checkout_Date,
			room_qty: room,
			adult_qty: adult,
			child_qty: child,
		}
		getData('hotel/room_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						RoomData: result['data'],
						room_count:result['count']
					})
					localStorage.setItem('plan_array_count', result['plan_array_count']);
					if(localStorage.getItem("room_default")=='true'){
						var plan_id=result['data'][0]['rate_plan'][0]['plan_id'];
						var plan_array_no=result['data'][0]['rate_plan'][0]['plan_array_no'];
						if(localStorage.getItem("room"+plan_array_no)>0){
							var room = parseInt(localStorage.getItem("room"+plan_array_no));
							var room_id = result['data'][0]['room_id'];
						}
						else{
							var room = parseInt(localStorage.getItem("room"));
							var room_id = result['data'][0]['room_id'];
						}
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
						
						document.getElementById("input_"+plan_array_no).value=room;
						
						localStorage.setItem('room'+plan_array_no, parseInt(room));
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
						this.selectrate();
					}
					else{
						var plan_id=localStorage.getItem("plan_id")
						var room_id=localStorage.getItem("room_id")
						this.selectrate();
					}
				}
			}
		});
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 500);
	}

	selectrate = () => {
		this.setState({
			//skeleton1:true,
			//skeleton2:true,
			skeleton3:true,
			skeleton4:true,
			skeleton5:true,
			skeleton6:true,
			skeleton7:true
		});


		var room = parseInt(localStorage.getItem("room"));
		var adult = parseInt(localStorage.getItem("adult"));
		var child = parseInt(localStorage.getItem("child"));
		var checkin_Date = localStorage.getItem("checkin_Date");
		var checkout_Date = localStorage.getItem("checkout_Date");
		var checkin = localStorage.getItem("checkin");
		var checkout = localStorage.getItem("checkout");

		function convert(str) {
		    var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		    return [date.getFullYear(), mnth, day].join("-");
		}

		if(localStorage.getItem("checkin")){
			var date1 = convert(checkin);
			var date2 = convert(checkout);
			var checkin_Date_ = new Date(checkin);
			var checkout_Date_ = new Date(checkout);
		}

		var page= this.props.location.pathname;
		var hotel_id = this.props.match.params.id;
		var Difference_In_Time = checkout_Date_.getTime() - checkin_Date_.getTime();
		var Difference_In_Days = Math.round(Difference_In_Time/(1000*3600*24));

		var room_type = '0';
		var plan_list_array='';
		var plan_array_count=localStorage.getItem("plan_array_count");
		var comma = '';
		for(var j=1;j<plan_array_count;j++){
			if(localStorage.getItem('plan_list'+j)>0){
				plan_list_array += comma+localStorage.getItem('plan_list'+j);
				comma=',';
			}
		}

		var room_list_array='';
		var comma = '';
		var room_qty_array = '';
		for(var k=1;k<plan_array_count;k++){
			if(localStorage.getItem('room_list'+k)>0){
				room_list_array += comma+localStorage.getItem('room_list'+k);
				room_qty_array += comma+localStorage.getItem('room'+k);
				comma=',';
			}
		}
		var child_age = "";
		if(child>0){
			var comma = '';
			for(var i=0;i<child;i++){
				child_age += comma+localStorage.getItem('age_value'+i);
				comma=',';
			}
		}
		var app = {
			hotel_id: hotel_id,
			start : date1,
			end : date2,
			rooms : room,
			adult : adult,
			children : child,
			room_list_array : room_list_array,
			plan_list_array : plan_list_array,
			room_qty_array : room_qty_array,
			child_age : child_age
		}
		getData('hotel/get_hotelcheck',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						get_hotelcheck: result['data']
					})
					this.setState({
						'base_rate': result['data'][0]['base_rate'],
						'child_charges': result['data'][0]['child_charges'],
						'hotel_discount': result['data'][0]['hotel_discount'],
						'booster_discount': result['data'][0]['booster_discount'],
						'bookmystay_offer': result['data'][0]['bookmystay_offer'],
						'gst_total': result['data'][0]['gst_total'],
						'grand_total': result['data'][0]['grand_total']
					});
					if(child>0){
						var appendhtml = "(";
						var comma = '';
						for(var i=0;i<child;i++){
							appendhtml += comma+localStorage.getItem('age_value'+i)+' years';
							comma=', ';
						}
						appendhtml += ')';
						this.setState({
							child_age: appendhtml,
							child_charges:result['data'][0]['child_charges']
						})
					}
					localStorage.setItem("hotel_discount",result['data'][0]['hotel_discount']);
					localStorage.setItem("booster_discount",result['data'][0]['booster_discount']);
					localStorage.setItem("bookmystay_offer",result['data'][0]['bookmystay_offer']);

					setTimeout(() => {
						this.setState({
							skeleton1:false,
							skeleton2:false,
							skeleton3:false,
							skeleton4:false,
							skeleton5:false,
							skeleton6:false,
							skeleton7:false
					}	);
					}, 1000);
				}
			}
		});
		this.setState({
			'days': Difference_In_Days,
		});
		var app = {
			hotel_id: hotel_id,
			checkin: date1,
			checkout: date2,
			room_qty: room,
			adult_qty: adult,
			child_qty: child,
		}
		getData('extranet/offline_room_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						RoomData: result['data'],
						room_count:result['count']
					})
					localStorage.setItem('plan_array_count', result['plan_array_count']);
					if(localStorage.getItem("room_default")=='true'){
						var plan_id=result['data'][0]['rate_plan'][0]['plan_id'];
						var plan_array_no=result['data'][0]['rate_plan'][0]['plan_array_no'];
						if(localStorage.getItem("room"+plan_array_no)>0){
							var room = parseInt(localStorage.getItem("room"+plan_array_no));
							var room_id = result['data'][0]['room_id'];
						}
						else{
							var room = parseInt(localStorage.getItem("room"));
							var room_id = result['data'][0]['room_id'];
						}
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
						
						document.getElementById("input_"+plan_array_no).value=room;
						
						localStorage.setItem('room'+plan_array_no, parseInt(room));
						localStorage.setItem('plan_id', plan_id);
						localStorage.setItem('room_id', room_id);
						localStorage.setItem('plan_list'+plan_array_no, plan_id);
						localStorage.setItem('room_list'+plan_array_no, room_id);
					}
					else{
						var plan_id=localStorage.getItem("plan_id")
						var room_id=localStorage.getItem("room_id")
					}
				}
			}
		});

	}
	
	onInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}


   toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab': tab
			});
		}
	}

	toggle1(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab1': tab
			});
		}
	}
  
   goBack(){
		this.props.history.goBack();
	}
	
	
	
	
	
	
	
	
	
	
	

	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue
		});
	};
   
	minusQty_Room = (plan_array_no,plan_id,room_id) => {
		var total_room=localStorage.getItem("room");
		localStorage.setItem('room_default', '');
		var qty=document.getElementById("input_"+plan_array_no).value;
		if(qty>1) {
			document.getElementById("input_"+plan_array_no).value=parseInt(qty)-1;
			
			localStorage.setItem('room'+plan_array_no, parseInt(qty)-1);
			localStorage.setItem('room', parseInt(total_room)-1);
			this.setState({
				room_qty: parseInt(total_room)-1
			});
		}
		else{
			if(total_room>1) {
				document.getElementById("input_"+plan_array_no).value=0;
				
				localStorage.setItem('room'+plan_array_no, '0');
				document.getElementById("btn_"+plan_array_no).classList.remove('btn_close');
				localStorage.setItem('room', parseInt(total_room)-1);
				this.setState({
					room_qty: parseInt(total_room)-1
				});
				localStorage.setItem('plan_list'+plan_array_no, '0');
				localStorage.setItem('room_list'+plan_array_no, '0');
			}
		}
		localStorage.setItem('plan_id', plan_id);
		localStorage.setItem('room_id', room_id);
		this.selectrate();
    }

    plusQty_Room = (plan_array_no,plan_id,room_id) => {
		//localStorage.setItem('room_default', '');
		//localStorage.setItem('room'+plan_array_no, parseInt(0));
		var qty=document.getElementById("input_"+plan_array_no).value;
		document.getElementById("input_"+plan_array_no).value=parseInt(qty)+1;
		
		localStorage.setItem('room'+plan_array_no, parseInt(qty)+1);
		var total_room=localStorage.getItem("room");
		localStorage.setItem('room', parseInt(total_room)+1);

		this.setState({
			room_qty: parseInt(total_room)+1
		});
		localStorage.setItem('plan_id', plan_id);
		localStorage.setItem('room_id', room_id);
		localStorage.setItem('plan_list'+plan_array_no, plan_id);
		localStorage.setItem('room_list'+plan_array_no, room_id);
		this.selectrate();
    }

	open_Room = (plan_array_no,plan_id,room_id) => {
		localStorage.setItem('room_default', 'false');
		var qty=document.getElementById("input_"+plan_array_no).value;
		document.getElementById("input_"+plan_array_no).value=parseInt(qty)+1;
		
		localStorage.setItem('room'+plan_array_no, parseInt(qty)+1);
		document.getElementById("btn_"+plan_array_no).classList.add('btn_close');

		var total_room=localStorage.getItem("room");
		localStorage.setItem('room', parseInt(total_room)+1);
		this.setState({
			room_qty: parseInt(total_room)+1
		});

		localStorage.setItem('plan_id', plan_id);
		localStorage.setItem('room_id', room_id);
		localStorage.setItem('plan_list'+plan_array_no, plan_id);
		localStorage.setItem('room_list'+plan_array_no, room_id);
		this.selectrate();
    }

    changeQty_Room = (e) => {
		this.setState({ room_qty: parseInt(e.target.value) })
		this.selectrate();
    }

	minusQty_Adult = () => {
        if(this.state.adult_qty > 1) {
            this.setState({adult_qty: this.state.adult_qty - 1})
			localStorage.setItem('adult', this.state.adult_qty - 1);
			this.selectrate();
        }
		setTimeout(() => {
			this.selectrate();
		}, 500);
    }

    plusQty_Adult = () => {
		this.setState({adult_qty: this.state.adult_qty+1})
		localStorage.setItem('adult', this.state.adult_qty+1);
		this.selectrate();
		setTimeout(() => {
			this.selectrate();
		}, 500);
    }

    changeQty_Adult = (e) => {
        this.setState({ adult_qty: parseInt(e.target.value) })
		this.selectrate();
    }

	minusQty_Child = () => {
        if(this.state.child_qty > 0) {
            this.setState({child_qty: this.state.child_qty - 1})
			localStorage.setItem('child', this.state.child_qty - 1);
        }
		this.setState((prevState, props) => {
			return { child_age_rows: prevState.child_age_rows.slice(1) };
		});
		this.selectrate();
		setTimeout(() => {
			this.selectrate();
		}, 500);
    }

	changeChildAge = (e) => {
		var child = parseInt(localStorage.getItem("child"));
		if(child>0){
			for(var i=0;i<child;i++){
				//
			}
			localStorage.setItem('age_value'+(i-1), parseInt(e.target.value));
		}
		this.selectrate();
    }

	plusQty_Child = () => {
		if(this.state.child_qty<(this.state.room_qty*4)){
        this.setState({
			child_qty: parseInt(this.state.child_qty)+1
		})
		localStorage.setItem('child', this.state.child_qty+1);
		this.setState((prevState, props) => {
		const row = { content:
			<select name="age" className="form-control" onChange={this.changeChildAge.bind(this)}>
			<option value="0">0 year old</option>
			<option value="1">1 year old</option>
			<option value="2">2 years old</option>
			<option value="3">3 years old</option>
			<option value="4">4 years old</option>
			<option value="5">5 years old</option>
			<option value="6">6 years old</option>
			<option value="7">7 years old</option>
			<option value="8">8 years old</option>
			<option value="9">9 years old</option>
			<option value="10">10 years old</option>
			<option value="11">11 years old</option>
			<option value="12" selected>12 years old</option>
			</select> };
			return { child_age_rows: [...prevState.child_age_rows, row]};
			});
			var child = parseInt(localStorage.getItem("child"));
			if(child>0){
				for(var i=0;i<child;i++){
					//
				}
				localStorage.setItem('age_value'+(i-1), '12');
			}
			this.selectrate();
		}
		setTimeout(() => {
			this.selectrate();
		}, 500);
    }

    changeQty_Child = (e) => {
        this.setState({ child_qty: parseInt(e.target.value) })
		this.selectrate();
    }

	checkquantity(){
		var room     = document.getElementById('room').value;
		var adult    = document.getElementById('adult').value;
		var child    = document.getElementById('child').value;
		localStorage.setItem('room', room);
		localStorage.setItem('adult', adult);
		localStorage.setItem('child', child);
	}

	onChangeDate = (dates, dateStrings) => {
		this.setState({
			checkin: dateStrings[0],
			checkout: dateStrings[1],
		});
		localStorage.setItem('checkin', dateStrings[0]);
		localStorage.setItem('checkout', dateStrings[1]);
		this.selectrate();
	}

	booking(){
		
			if (localStorage.getItem('room_id')!='' && localStorage.getItem('room_id')!='null')
			{
				var room_id = localStorage.getItem("room_id")
			}
			else{
				var room_id = document.getElementById('room_id').value;
			}
			var gst_amount = this.state.gst_amount;
			var grand_total = this.state.grand_total;
			var room_single_sell_rate = this.state.room_single_sell_rate;
			var room_double_sell_rate = this.state.room_double_sell_rate;
			var plan_type = localStorage.getItem("plan_type");
			var hotel_id = this.props.match.params.id;
			localStorage.setItem('hotel_id', hotel_id);
			localStorage.setItem('room_id', room_id);
			localStorage.setItem('gst_amount', gst_amount);
			localStorage.setItem('grand_total', grand_total);
			localStorage.setItem('plan_type', plan_type);
			localStorage.setItem('room_single_sell_rate', room_single_sell_rate);
			localStorage.setItem('room_double_sell_rate', room_double_sell_rate);
			window.location.href = '/offline-hotel-booking/';
		
	}
    

render() {
	if (this.state.redirectToReferrer){
		//this.props.history.push("/property/contact_info");
		window.location.href = '/property/contact_info';
	}
	
	const checking_value = curr_checkin;
	const checkout_value = curr_checkout;

	function onChange(time, timeString) {
	  console.log(time, timeString);
	}
	
	let { question, options } = this.state;

return (
	<div>
    <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
			<CardTitle className="bg-light border-bottom p-3 mb-0">Offline Booking</CardTitle>
            <CardBody>
			
			<Row>
           <div className="col-md-4 hidden-sm hidden-xs scroll_main">

            <div className="sticky-col sticky">
               <div className="theme-search-area _mb-20 _p-20 _b _bc-dw theme-search-area-vert _bg-w">
                  <div className="theme-search-area-form">
                     <div className="theme-search-area-section theme-search-area-section-sm theme-search-area-section-curved">
                        <div className="theme-search-area-section-inner h-details">
                           <RangePicker
                           disabledDate={disabledDate}
                           disabledTime={disabledRangeTime}
                           format="MMM DD, YYYY"
                           defaultValue={[
                           moment(checking_value, dateFormat),
                           moment(checkout_value, dateFormat)
                           ]}
                           name ="start_end_date"
                           id="start_end_date"
                           onChange={this.onChangeDate}
                           />
                        </div>
                     </div>
                     <div className="theme-search-area-section theme-search-area-section-sm theme-search-area-section-curved quantity-selector hotel_detail" data-increment="Adults">
                        <div className="theme-search-area-section-inner">
                           <i className="icon-left ti-user"></i>
                           <input className="theme-search-area-section-input"  value={this.state.adult_qty} onChange={this.changeQty_Adult} id="adult"  type="text" readOnly/>
                           <span className="selection_name">Adult</span>
                           <ul className="quantity-selector-controls">
                              <li className="quantity-selector-decrement"  onClick={this.minusQty_Adult} >
                                 <i className="tround ti-minus"></i>
                              </li>
                              <li className="quantity-selector-increment" onClick={this.plusQty_Adult} >
                                 <i className="tround ti-plus"></i>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="theme-search-area-section theme-search-area-section-sm theme-search-area-section-curved quantity-selector hotel_detail" data-increment="Child">
                        <div className="theme-search-area-section-inner">
                           <i className="icon-left ti-user"></i>
                           <input className="theme-search-area-section-input "  value={this.state.child_qty} onChange={this.changeQty} id="child"  type="text" readOnly/>
                           <span className="selection_name">Child</span>
                           <ul className="quantity-selector-controls">
                              <li className="quantity-selector-decrement"  onClick={this.minusQty_Child} >
                                 <i className="tround ti-minus"></i>
                              </li>
                              <li className="quantity-selector-increment" onClick={this.plusQty_Child} >
                                 <i className="tround ti-plus"></i>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="theme-sidebar-section-charges">
                        <ul className="theme-sidebar-section-charges-list">
							{(() => {
								if (this.state.skeleton1==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton1"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton1_right"></span>
											</div>
										</li>
									)
								}
								else{
									return (
										<li className="theme-sidebar-section-charges-item">
										  <h5 className="theme-sidebar-section-charges-item-title">{this.state.days} nights + {this.state.room_qty}
										  {(() => {
												if (this.state.room_qty > 1) {
													return (
													<span> Rooms</span>
												  )
												}
												else{
													return (
													<span> Room</span>
												  )
												}
											})()}
										  </h5>
										  <p className="theme-sidebar-section-charges-item-price">
											 INR &nbsp;
											 {this.state.base_rate}
										  </p>
									   </li>
									)
								}
							})()}


							{(() => {
								if (this.state.skeleton2==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton2"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton2_right"></span>
											</div>
										</li>
									)
								}
								if (this.state.child_qty > 0 && this.state.skeleton2==false) {
                                 return (
								 <li className="theme-sidebar-section-charges-item">
                                 <h5 className="theme-sidebar-section-charges-item-title">{this.state.child_qty} children {this.state.child_age}</h5>

								 <span>
                                  <p className="theme-sidebar-section-charges-item-title"></p>


								  {this.state.child_age_rows.map(row => (
									<div className="children_age2">
										{row.content}
									</div>
									))}
								 </span>

								  <p className="theme-sidebar-section-charges-item-price">

								 {(() => {
                                 if (this.state.child_qty > 0) {
                                 return (
                                  <span>
								 + INR {this.state.child_charges}
								  </span>

                                 )}
                                 })()}


                              </p>

								  </li>
                                 )}
							})()}


							{(() => {
								if (this.state.skeleton3==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton3"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton3_right"></span>
											</div>
										</li>
									)
								}
								if (this.state.hotel_discount > 0 && this.state.skeleton3==false) {
								   return (
								   <li className="theme-sidebar-section-charges-item">
									  <h5 className="theme-sidebar-section-charges-item-title">Hotel Discount</h5>
									  <p className="theme-sidebar-section-charges-item-price">- INR {this.state.hotel_discount}</p>
								   </li>
								   )
								}
							})()}

							{(() => {
								if (this.state.skeleton4==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton4"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton4_right"></span>
											</div>
										</li>
									)
								}
								var booster_discount = this.state.booster_discount;
								var bookmystay_offer = this.state.bookmystay_offer;
								if ((this.state.booster_discount > 0 || this.state.bookmystay_offer>0) && this.state.skeleton4==false) {
								   return (
									   <li className="theme-sidebar-section-charges-item">
										  <h5 className="theme-sidebar-section-charges-item-title">BookMyStay Discount</h5>
										  <p className="theme-sidebar-section-charges-item-price">- INR {booster_discount+bookmystay_offer}</p>
									   </li>
									)
								}
							})()}

						   {(() => {
								if (this.state.skeleton5==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton5"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton5_right"></span>
											</div>
										</li>
									)
								}
								else{
									return (
										<li className="theme-sidebar-section-charges-item">
										  <h5 className="theme-sidebar-section-charges-item-title">Taxes, Fees &amp; Surcharges</h5>
										  <p className="theme-sidebar-section-charges-item-subtitle"></p>
										  <p className="theme-sidebar-section-charges-item-price">+ INR {this.state.gst_total}</p>
									    </li>
									)
								}

							})()}

                        </ul>
						{(() => {
								if (this.state.skeleton6==true) {
									return (
										<li className="theme-sidebar-section-charges-item">
											<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton6"></span>
												<span className="ant-skeleton-input ant-skeleton-input-sm skeleton6_right"></span>
											</div>
										</li>
									)
								}
								else{
									return (
										<p className="theme-sidebar-section-charges-total">Total
											<span>INR {this.state.grand_total}</span>
										</p>
									)
								}
							})()}
                     </div>



                     {(() => {
						if (this.state.skeleton7==true) {
						return (
							<li className="theme-sidebar-section-charges-item">
								<div className="ant-skeleton ant-skeleton-element ant-skeleton-active">
									<span className="ant-skeleton-input ant-skeleton-input-sm skeleton7"></span>
									<span className="ant-skeleton-input ant-skeleton-input-sm skeleton7_right"></span>
								</div>
							</li>
						)
						}
                     else if(this.state.grand_total > 0){
                     return (
					 <div>
                     <button className="theme-search-area-submit _mt-0 _fw-n _ls-0 _tt-uc theme-search-area-submit-primary theme-search-area-submit-no-border theme-search-area-submit-curved" onClick={this.booking}>Book This Now</button>
					 {(() => {
							var base_rate = this.state.base_rate;
							var hotel_discount = this.state.hotel_discount;
							var booster_discount = this.state.booster_discount;
							var bookmystay_offer = this.state.bookmystay_offer;
							var grand_total = this.state.grand_total;
							var total_rate = base_rate-hotel_discount-booster_discount-bookmystay_offer;
							if((hotel_discount+booster_discount+bookmystay_offer)>0){
								return (
									<p className="theme-search-results-item-price-tag font-s saved">You Saved INR {hotel_discount+booster_discount+bookmystay_offer}</p>
								)
							}
					})()}
					 </div>
                     )
                     }else{
                     return (
                     <button className="theme-search-area-submit _mt-0 _fw-n _ls-0 _tt-uc theme-search-area-submit-dan theme-search-area-submit-no-border theme-search-area-submit-curved" >Not Available</button>
                     )
                     }
                     })()}
                  </div>
               </div>
            </div>
         </div>
			
			<div className="col-md-8 mt-45">


               <div className="p-t-10 p-b-10">


                              {this.state.RoomData.map((room) => (
                              <div className="col-md-12 rooms">
                                 <div className="row">
                                    
                                    <div className="col-md-12">
                                       <h3 className="theme-item-page-rooms-table-type-title">{room.display_name}</h3>
                                       <div className="row p-15">
                                          <div className="btn-group type col-md-12">
                                             <button type="button" className="btn_1"><small>BED TYPE</small><br/><b>{room.bed_type}</b></button>
                                             <button type="button" className="btn_1"><small>SIZE</small><br/><b>{room.room_size_sqft} sq.ft.</b></button>
                                             <button type="button" className="btn_2"><small>Accomodates</small><br/><b>{room.adults_base} Adults + {room.child_base} Child</b></button>
                                          </div>


                                       </div>
                                       <div className="col-md-12 no-padd">
										{(room.rate_plan).map((rate) =>
                                          <div className="rooms room-amen">
                                             <div className="row">
                                                <div className="col-md-8">
												    <div>
														{(rate.plan_list).map((plan_arr) =>
															<p><i className="mr-2 mdi mdi-check"></i> {plan_arr.plan}</p>
														)}
														{(() => {
                     if(room.count_refund>0){
						 return (
							<p><i className="mr-2 mdi mdi-check"></i> {room.free_cancellation_till}</p>
						 )
                     }else{
						 return (
							<p><i className="mr-2 mdi mdi-check"></i> Non Refundable</p>
						 )
                     }
                     })()}
												    </div>
                                                </div>
                                                <div className="col-md-4 room_price">
                                                   <div className="theme-item-page-rooms-table-price-night">
                                                      <p className="theme-item-page-rooms-table-price-sign">Base Price Per Night</p>
                                                      <p className="theme-search-results-item-price-tag font-s">
                                                         <span className="theme-item-page-rooms-table-price-night-amount">INR &nbsp;
                                                         {rate.plan_amount}
                                                         </span>
                                                      </p>
                                                   </div>

                                                   <div className="room_selection">


                           <ul className="quantity-selector-controls" id={`qty_${rate.plan_array_no}`}>
								<li className="quantity-selector-increment" onClick={this.minusQty_Room.bind(this,rate.plan_array_no,rate.plan_id,room.room_id)} >
                                 <i className="tround ti-minus"></i>
                              </li>
<input className="theme-search-area-section-input" defaultValue={`${localStorage.getItem('room'+rate.plan_array_no)>0?localStorage.getItem('room'+rate.plan_array_no):'0'}`}  id={`input_${rate.plan_array_no}`} type="text" readOnly/>
                              <li className="quantity-selector-increment" onClick={this.plusQty_Room.bind(this,rate.plan_array_no,rate.plan_id,room.room_id)} >



                                 <i className="tround ti-plus"></i>
                              </li>
                           </ul>
	<div className={`btn-group ${localStorage.getItem('room'+rate.plan_array_no)>0?'btn_close':''} `} onClick={this.open_Room.bind(this,rate.plan_array_no,rate.plan_id,room.room_id)} id={`btn_${rate.plan_array_no}`}>
															<a className="btn btn-primary-inverse btn-block book-btn">Select</a>
													</div>
                                                   </div>


                                                </div>
                                             </div>
                                          </div>

										)}

                                       </div>
                                    </div>
                                 </div>
                              </div>
                              ))}
                           </div>
			   <div id="alert"></div>

            </div>
			
			</Row>
			 
				
			

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default AddNewHotel;
