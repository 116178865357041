import React from 'react';

import moment from 'moment';

import { DatePicker,Checkbox,Select,Radio   } from "antd";

import 'antd/dist/antd.css';
import { message } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';


import {getData} from '../../services/index';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const defaultCheckedList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const { Option } = Select;

class ManageRate extends React.Component {



constructor(props) {
super(props);
this.state = {
	room_type : [],
    indeterminate: false,
    checkAll: false,
	room_id : '',
    start_end_date : '',
    daylist : '',
	single_sell_rate : '',
	single_net_rate : '',
	double_sell_rate : '',
	double_net_rate : '',
	pah : 0,
	value: 1,

};

this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
this.onChangeText = this.onChangeText.bind(this);
this.onChangeDate = this.onChangeDate.bind(this);
this.onChangecheck = this.onChangecheck.bind(this);
this.onChangeRadio = this.onChangeRadio.bind(this);

}

componentDidMount() {
	if (localStorage.getItem('extranetData')){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		this.state.user_id = storedArray['data'][0]['user_id'];
		getData('extranet/lmr_room_list',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					room_type: result['data']
				})
				}
			}
		});
	}

}

handleSubmit(){
	  var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	  var room_id = document.getElementById("room_id").value;
	  if(room_id==''){
		message.error('Please select room',2);
	  }
	  else if(this.state.start_end_date==''){
		message.error('Please select date',2);
	  }
	  else if(this.state.daylist==''){
		message.error('Please select days of week',2);
	  }
	  else if(this.state.single_sell_rate==''){
		message.error('Please enter single sell rate',2);
	  }
	  else if(this.state.double_sell_rate==''){
		message.error('Please enter double sell rate',2);
	  }
	  else{
	  var app = {
		user_id : storedArray['data'][0]['user_id'],
		room_id : room_id,
		start_end_date : this.state.start_end_date,
		daylist : this.state.daylist,
		single_sell_rate : this.state.single_sell_rate,
		double_sell_rate : this.state.double_sell_rate,
		pah : this.state.pah,
	  }


	  getData('extranet/manage_lmr_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				if(result['data']['tag']=='0'){
					message.error(result['data']['msg'],2);
				}else{
					message.success(result['data']['msg'],2);
					this.setState({redirectToReferrer: true});
				}


				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});

}

}

onChangeText(e){
		this.setState({[e.target.name]:e.target.value});

	}



onChangeDate(event) {
	  this.setState({
		start_end_date: event,

	  });
	}



  onChange = checkedList => {
    this.setState({

      checkedList,
	  daylist : checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = e => {
    this.setState({
	  daylist : defaultCheckedList,
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onChangecheck(e) {
  console.log(`checked = ${e.target.checked}`);
  if(e.target.checked){
	 this.setState({
	  pah : '1',
	});
  }else{
   this.setState({
	  pah : '0',
	});
  }

}

onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });

  };

render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/dashboard/index");
		}
return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Sell Rooms at Last Minute Rate</CardTitle>
            <CardBody>
              <form className="login100-form validate-form">
             <Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Room Type (Rate Plan)</Label>
				</Col>
				<Col sm="12" md="4" >

				  <select class="form-control" id="room_id" name="room_id">
								 {this.state.room_type.map((list) => (
                                 <option value={`${list.plan_id}`} >{list.display_name}</option>
								 ))}
                              </select>
				</Col>

				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Select Date *</Label>
				</Col>
				<Col sm="12" md="4" >
					  <FormGroup>
						<RangePicker
						  disabledDate={disabledDate}
						  disabledTime={disabledRangeTime}
						  format="DD MMM, YYYY"
						  name ="start_end_date"
						  onChange={this.onChangeDate}
						/>
					  </FormGroup>
				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Days Of Week</Label>
				</Col>
				<Col sm="12" md="6" >
                  <Form inline>
				  <div className="col-md-10 ant-checkbox-wrapper-check-all">
				 <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            Check all
          </Checkbox>
		  </div>
		  <br />
		  <div className="col-md-12 no-paddding">
		  <CheckboxGroup
          options={plainOptions}
          value={this.state.checkedList}
          onChange={this.onChange}
		  name="daylist"
        />
		 </div>
				  </Form>



				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Single occupancy Sell Rate</Label>
				</Col>
				<Col sm="12" md="3" >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" onChange={this.onChangeText}  name="single_sell_rate" id='Minutes1'/>
                  </InputGroup>
				</Col>

				</Row>
                </FormGroup>
			</Col>
			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Double occupancy Sell Rate</Label>
				</Col>
				<Col sm="12" md="3"  >

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" onChange={this.onChangeText}  name="double_sell_rate" id='Minutes2'/>
                  </InputGroup>
				</Col>

				</Row>
                </FormGroup>
			</Col>
			<Col sm="12" md="12" lg="12" className="m-t-20 ">
								<FormGroup>
								<Row>
								<Col sm="12" md="12" >
								<Checkbox onChange={this.onChangecheck}>Applicable on PAH</Checkbox>
								<br/>
								<br/>
								{/* <p className="lmr-p-strong">Last Minute Rate will run for A.C Deluxe Room with Wi-Fi - EP/- valid for Today.</p> */}
								<p className="lmr-p">The price entered by you is valid for bookings made Today and valid for stay dates as Today. Rates for any other stay date in the booking will not get impacted. Restriction on minimum length of stay & cutoff shall be removed. This rate will be applicable for current available inventory of the room & rateplan selected. Last Minute Rates will be Commissionable by 5%.</p>
								</Col>
								</Row>
								</FormGroup>
		  </Col>

			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>

				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit}>Update Rates</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
             </form>
            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ManageRate;
