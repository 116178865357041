import React from 'react';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';
import {getData} from '../../services/index';
import { message } from 'antd';
import { Switch, Icon } from 'antd';
import { DatePicker,Checkbox,Radio,Select  } from "antd";
import { confirmAlert } from 'react-confirm-alert';


import 'antd/dist/antd.css';

import moment from 'moment';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
  Table,TabContent,
  TabPane,
} from 'reactstrap';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

class Policy extends React.Component {
constructor(props) {
super(props);
this.state = {
	hotel_policy : '',
	standard_cancellation_policy : '',
	hotel_services : '',
	ListData : [],
	opened: false,
	value: 1,
	days_: '',
	is_refundable:0,
	start_end_date: '',
};
this.onChange = this.onChange.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggleBox = this.toggleBox.bind(this);
this.onChangeDate = this.onChangeDate.bind(this);
this.onChangeradio = this.onChangeradio.bind(this);
this.onChangeText = this.onChangeText.bind(this);
}

	onChangeText(e){
		if(e.target.value==0){
			this.setState({is_refundable:"1"});
		}
		else{
			this.setState({is_refundable:"0"});
		}
	}

toggleBox() {
		const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
	}

	hotel_policy = (e) =>{
        this.setState( {
            hotel_policy: e.editor.getData()
        } );
    }

	standard_cancellation_policy = (e) =>{
        this.setState( {
            standard_cancellation_policy: e.editor.getData()
        } );
    }

	hotel_services = (e) =>{
        this.setState( {
            hotel_services: e.editor.getData()
        } );
    }

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/policy_details',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							ListData: result['data'],
							hotel_policy: result['data'][0]['hotel_policy'],
							standard_cancellation_policy: result['data'][0]['standard_cancellation_policy'],
							hotel_services: result['data'][0]['hotel_services']
						});
					}
				}
			});
		}
	}

	handleSubmit(){
		var days_ = this.state.value;
		if(days_ > 3){
			var days = this.state.days;
		}else{
			var days = this.state.value;
		}

		if(this.state.opened == true){
			var is_cancel = '1';
		}else{
			var is_cancel = '0';
		}

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var app = {
			hotel_id : storedArray['data'][0]['hotel_id'],
			hotel_policy : this.state.hotel_policy,
			standard_cancellation_policy : this.state.standard_cancellation_policy,
			hotel_services : this.state.hotel_services,
			days_before : days,
			start_end_date : this.state.start_end_date,
			cancel : is_cancel,
			is_refundable:this.state.is_refundable
		}
		getData('extranet/policy_update',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					message.config({ top: 150});
					message.success('Policy Update Successfull!',2);
					this.setState({redirectToReferrer: true});
				}
			}
		});
	}

	delete = (event) => {
		confirmAlert({
			title: 'Are you sure',
			message: 'are you sure u want to delete this policy',
			buttons: [
			{
				label: 'Yes',
				onClick: () => {
					var storedArray = JSON.parse(localStorage.getItem("extranetData"));
					var cancel_id = event;
					var app = {
						hotel_id : storedArray['data'][0]['hotel_id'],
						cancel_id : cancel_id,
					}
					getData('extranet/delete_policy_cancel',app).then((result) => {
						let responseJson = result;
						if(responseJson.data){
							if(result['status']==200){
								this.componentDidMount();
							}
							else{
							message.error('Policy Not Delete!',2);
							}
						}
					});
				}
			},
			{
				label: 'No',
				onClick: () => {}
			}
			]
		});
	}

onChangeDate(event) {
	  this.setState({
		start_end_date: event,

	  });
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

	onChangeradio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };


render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/bank_details");
		}
const radioStyle = {

      height: '30px',
      lineHeight: '30px',

    };
return (
<div>

  
</div>
);
}
}
export default Policy;
