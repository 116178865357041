import React from 'react';
import 'react-datetime/css/react-datetime.css';
import {getData} from '../../services/index';
import { message } from 'antd';
import {
CardImg,
CardImgOverlay,
CardText,
Row,
Col,
Card,
CardBody,
CardTitle,
CardSubtitle,
Form,
FormGroup,
Input,
FormText,
Label,
CustomInput,
InputGroup,
InputGroupAddon,
InputGroupText,
Button,
FormFeedback
} from 'reactstrap';
import img1 from '../../assets/images/big/img1.jpg';
class documents extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectToReferrer:false
		};
		this.setState = this.setState.bind(this);
	}



	componentDidMount() {

		const $ = window.$;
		const google = window.google;
		var geocoder;
		var map;
		var marker;
		var autocomplete;
		var search_location=document.getElementById('search_location');
		function get_location() {
			autocomplete = new google.maps.places.Autocomplete(
			(document.getElementById('search_location')),
			{ types: ['geocode'] });
			window.google.maps.event.addListener(search_location, 'place_changed', function() {
			});
		}
		var lat = '';
		var lng = '';
		var input = document.getElementById('search_location');
		var autocomplete = new google.maps.places.Autocomplete(input);
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			var place = autocomplete.getPlace();
			var lat  = place.geometry.location.lat();
			var lng = place.geometry.location.lng();
			document.getElementById('search_latitude').value = place.geometry.location.lat();
			document.getElementById('search_longitude').value = place.geometry.location.lng();
			document.getElementById('search_addr').value = place.formatted_address;
			initialize(lat,lng);
		});
		initialize(lat,lng);
		function initialize(lat,lng) {
			var initialLat = document.getElementById('search_latitude').value
			var initialLong = document.getElementById('search_longitude').value
			if(lat==''){
				initialLat = initialLat?initialLat:19.157811199999998;
				initialLong = initialLong?initialLong:72.84326399999999;
			}
			else{
				initialLat = lat;
				initialLong = lng;
			}
			var latlng = new google.maps.LatLng(initialLat, initialLong);
			var options = {
				zoom: 16,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};
			map = new google.maps.Map(document.getElementById("geomap"), options);
			geocoder = new google.maps.Geocoder();
			marker = new google.maps.Marker({
				map: map,
				draggable: true,
				position: latlng
			});
			window.google.maps.event.addListener(marker, "dragend", function () {
				var point = marker.getPosition();
				map.panTo(point);
				geocoder.geocode({'latLng': marker.getPosition()}, function (results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						marker.setPosition(results[0].geometry.location);
						document.getElementById('search_addr').value=results[0].formatted_address;
						document.getElementById('search_latitude').value=marker.getPosition().lat();
						document.getElementById('search_longitude').value=marker.getPosition().lng();
					}
				});
			});
		}

		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/map_details',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							ListData: result['data'],
							geo_lang : result['data'][0]['geo_lang'],
							geo_lati : result['data'][0]['geo_lati'],
							address : result['data'][0]['address']
						});
						initialize(result['data'][0]['geo_lati'],result['data'][0]['geo_lang']);
					}
				}
			});
		}

	}


handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));

		 var app = {
				hotel_id : storedArray['data'][0]['hotel_id'],
				address : document.getElementById("search_addr").value,
				geo_lati : document.getElementById("search_latitude").value,
				geo_lang : document.getElementById("search_longitude").value,
			}

	  getData('extranet/update_location',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				message.success('Map Info Update Successfull!',2);
				//this.setState({redirectToReferrer: true});
				}
				else{
				message.error('Map Info Not Update??',2);
				}
			}
		});

}
render() {
	if (this.state.redirectToReferrer){
	this.props.history.push("/property/photos");
	}
return (
<div>
   <Row className="mob_form">
      <Col sm="12" md="12" lg="12" className="map">
      <Card>
         <CardTitle className="bg-light border-bottom p-3 mb-0">
            Map Info
         </CardTitle>
         <CardBody>
            <Col sm="12" md="12" lg="12" className="border-bottom mb-20">

                  <div class="form-group  col-md-12 no_pad">
                     <div class="col-md-12 input-group no_pad">
                        <input class="form-control" data-required="true" id="search_location" placeholder="Search location" type="text"/>
                     </div>
                  </div>
                  <div class="form-group col-md-12 no_pad">
                     <div class="col-md-12 no_pad">
                        <div id="geomap"></div>
                     </div>
                  </div>
                   <div class="form-group col-md-12 no_pad">
                     <h4>&nbsp;&nbsp; Location Details</h4>
                  </div>
                  <div class="form-group col-md-12 no_pad">
                     <div class="col-md-12 no_pad">
                        <label for="map_location" class="control-label">Map Location:</label>
                        <input type="text" id="search_addr" defaultValue={this.state.address} class="search_addr form-control col-md-6" name="address"  readOnly/>
                     </div>
                  </div>
                  <div class="form-group col-md-12 no_pad">
                     <div class="col-md-12 no_pad">
                        <label for="Pharmacy Store Name" class="control-label">Latitude:</label>
                        <input type="text" id="search_latitude" defaultValue={this.state.geo_lati} class="search_latitude form-control col-md-6" name="geo_lati"  readOnly/>
                     </div>
                  </div>
                  <div class="form-group col-md-12 no_pad">
                     <div class="col-md-12 no_pad">
                        <label for="Pharmacy Store Name" class="control-label">Longitude:</label>
                        <input type="text" id="search_longitude" defaultValue={this.state.geo_lang} class="search_longitude form-control col-md-6" name="geo_lang"  readOnly/>
                     </div>
                  </div>

				  <Col sm="12" md="12" lg="12">
					<FormGroup>
					<Row>
					<Col sm="12" md="12" className="center">
					  <Button color="primary" onClick={this.handleSubmit}>Save & Continue</Button>
					</Col>
					</Row>
					</FormGroup>
				</Col>


            </Col>
         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default documents;
