import React from "react";
import {getData} from '../../../services/index';
import {
	Progress,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	ListGroup,
	ListGroupItem,
	Tooltip
} from 'reactstrap';

class Reviews extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			current_review: 0,
			total_review: 0,
			positive_review: 0,
			negative_review: 0,
			neutral_review: 0
		};
	}
	
	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.checkData();
		}
	}
	
	checkData(){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {hotel_id : hotel_id}
		getData('extranet/hotel_review',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
				if(result['count']>0){
					this.setState({
						current_review: result['data'][0]['current_review'],
						total_review: result['data'][0]['total_review'],
						positive_review: result['data'][0]['positive_review'],
						negative_review: result['data'][0]['negative_review'],
						neutral_review: result['data'][0]['neutral_review'],
					});
				}
			}
		});
	}

	render() {
		return (
			<Card>
				<Row>
					<Col sm="12" lg="8">
						<Row>
							<Col sm="12" lg="6">
								<CardBody>
									<CardTitle>Reviews</CardTitle>
									<CardSubtitle>Overview of Review</CardSubtitle>
									<h2 className="font-medium mt-5 mb-0">{this.state.total_review}</h2>
									<span className="text-muted">This month we got {this.state.current_review} New Reviews</span>
									<a href="/" className="btn btn-lg btn-info waves-effect waves-light">Checkout All Reviews</a>
								</CardBody>
							</Col>
							<Col sm="12" lg="6" className="border-left">
								<CardBody>
									<ListGroup>
										<ListGroupItem className="p-0 mt-4 border-0">
											<div className="d-flex align-items-center">
												<i className="mdi mdi-emoticon-happy display-5 text-muted"></i>
												<div className="ml-2">
													<h5 className="mb-0">Positive Reviews</h5>
													<span className="text-muted">{this.state.positive_review} Reviews</span></div>
											</div>
											<Progress color="success" value="47" />
										</ListGroupItem>
										<ListGroupItem className="p-0 mt-5 border-0">
											<div className="d-flex align-items-center">
												<i className="mdi mdi-emoticon-sad display-5 text-muted"></i>
												<div className="ml-2">
													<h5 className="mb-0">Negative Reviews</h5>
													<span className="text-muted">{this.state.negative_review} Reviews</span></div>
											</div>
											<Progress color="orange" value="33" />
										</ListGroupItem>
										<ListGroupItem className="p-0 mt-5 mb-5 border-0">
											<div className="d-flex align-items-center">
												<i className="mdi mdi-emoticon-neutral display-5 text-muted"></i>
												<div className="ml-2">
													<h5 className="mb-0">Neutral Reviews</h5>
													<span className="text-muted">{this.state.neutral_review} Reviews</span></div>
											</div>
											<Progress color="info" value="20" />
										</ListGroupItem>
									</ListGroup>
								</CardBody>
							</Col>
						</Row>
					</Col>
					<Col sm="12" lg="4" className="m-t-20 border-left">
								<CardTitle className="lmr">Last Minute Rate</CardTitle>
								<CardSubtitle>Sell your unsold inventory at 5% commission & increase your occupancy.</CardSubtitle>
								<a href="/offers/last_minute_rate_view">
									<button type="button" class="btn btn-primary">Enroll Now</button>
								</a>
								<CardSubtitle className="mt-2 mb-0 strong">*Commissionable by 5%.</CardSubtitle>
								
					</Col>
				</Row>
			</Card>
		);
	}
}

export default Reviews;
