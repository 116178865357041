import React from 'react';
import {connect} from 'react-redux';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import BigCalendar from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { events } from "../calendar/event-data";
import { message, Button,Modal } from 'antd';
import 'antd/dist/antd.css';
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Form,
	FormGroup,
	Input,
	FormText,
	Label,
	CustomInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	FormFeedback
} from 'reactstrap';
import { getData } from '../../services/index';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: moment(),
      selectedDay: moment().startOf("day"),
			prev_next: [],
			date_list: [],
			room_list: [],
			confirmLoading: false,
			mobile_room_list: [],
			set_interval: false,
			loading: false,
			visible: false,
			model_title: '',
			room_avl: 0,
			current_date: '',
			roomid: 0,
			show_loader:false
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);
  }




		checkData = () => {

		}

  previous() {
    const currentMonthView = this.state.selectedMonth;

    this.setState({
      selectedMonth: currentMonthView.subtract(1, "month")
    });
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.add(1, "month")
    });
  }

  select(day) {
    this.setState({
			show_loader:true,
      selectedMonth: day.date,
      selectedDay: day.date.clone()
    });
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {
			date: day.date,
			hotel_id:hotel_id
		}
		getData('extranet/mobile_offers_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					this.setState({
						current_date : '',
						mobile_room_list : []
					})
					this.setState({
						current_date : result['data'][0]['date'],
						mobile_room_list : result['data'][0]['room_list'],
						show_loader:false
					});
				}
			}
		});

  }

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
			}));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];


			if(window.innerWidth>768){
				// for desktop
				getData('extranet/offers_list',this.state).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['count']>0){
							this.setState({
								prev_next: result['data'][0]['prev_next'],
								date_list: result['data'][0]['date_list'],
								room_list: result['data'][0]['room_list']
							})
						}
					}
				});
			}
			else{
				// for mobile
				getData('extranet/mobile_offers_list',this.state).then((result) => {
				let responseJson = result;
					if(responseJson.data){
						if(result['count']>0){
							this.setState({
								mobile_room_list : []
							})
							this.setState({
								current_date : result['data'][0]['date'],
								mobile_room_list    : result['data'][0]['room_list']
							})
						}
					}
				});
			}



		}
		}

	NextInventory = (date,event) => {
		if (localStorage.getItem('extranetData')) {
			var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = extranetArray['data'][0]['hotel_id'];
			var app = {
				date: date,
				hotel_id:hotel_id
			}
			getData('extranet/offers_list', app).then((result) => {
				let responseJson = result;
				if (responseJson.data) {
					if (result['count'] > 0) {
						this.setState({
							prev_next: [],
							date_list: [],
							room_list: []
						})
						this.setState({
							prev_next: result['data'][0]['prev_next'],
							date_list: result['data'][0]['date_list'],
							room_list: result['data'][0]['room_list']
						})
					}
				}
			});
		}
	};

  goToCurrentMonthView(){
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: moment()
    });
  }

  showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay
    });
  }

  renderMonthLabel() {
    const currentMonthView = this.state.selectedMonth;
    return (
      <span className="box month-label">
        {currentMonthView.format("MMMM YYYY")}
      </span>
    );
  }

  renderDayLabel() {
    const currentSelectedDay = this.state.selectedDay;
    return (
      <span className="box month-label">
        {currentSelectedDay.format("DD MMMM YYYY")}
      </span>
    );
  }

  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;

    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Monday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();

    while (!done) {
      weeks.push(
        <Week
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          selected={currentSelectedDay}
          select={day => this.select(day)}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }


  render() {
    const currentMonthView = this.state.selectedMonth;
		const { visible, loading, model_title } = this.state;

    return (



				<div>
				   <Row>

						 <Col md="12" className="hidden-lg hidden-md hidden-sm event_calender">
						 <section className="main-calendar">
							 <header className="calendar-header">
								 <div className="row title-header">
									 <i
										 className="box arrow fa fa-angle-left"
										 onClick={this.previous}
									 />
									 <div className="box header-text">
									 {this.renderMonthLabel()}
									 </div>
									 <i className="box arrow fa fa-angle-right" onClick={this.next} />
								 </div>
								 <DayNames />
							 </header>
							 <div className="days-container">
								 {this.renderWeeks()}
							 </div>
						 </section>
				        <div class="current_date">{this.state.current_date}</div>

				        <div className="tab_card">
                <div className={`data_loader ${this.state.show_loader==false?'hide_loader':''}`}><img src="https://bungalowsinlonavala.com/img/loading.gif" /></div>

				{this.state.mobile_room_list.map((list) => (
								<table class="table table-bordered mob_booking_box">
									<tbody>
									<tr>
										<td colspan="2"  className="text-left">
											 <b>{list.display_name}</b>
										</td>
										<td className="text-right status"></td>
									</tr>
									<tr>
										<td colspan="2"  className="text-left">
										  Offer Percentage
										</td>
										<td className="text-right">
									    {list.discount} %
										</td>
									</tr>

									</tbody>
									</table>
				))}

				</div>
						 </Col>
				      <Col md="12" className="hidden-xs">
							<Card>
				         <CardTitle className="bg-light border-bottom p-3 mb-0">Offers View</CardTitle>
				         <CardBody className="room_view">
				            <Col sm="12" md="12" lg="12" className="no-paddding">
							{this.state.prev_next.map((prevnext) => (
				            <div class="row">
				               <div class="col-md-6 pull-left topMargin text-left deviceFloatarrow">
				                  <a onClick={this.NextInventory.bind(this,prevnext.prev_date)} className="badge badge-secondary badge-pill prev_next">
				                  &lt; &nbsp; Prev 14 Days
				                  </a>
				               </div>
				               <div class="col-md-6 pull-right topMargin text-right deviceFloatRightArrow">
				                  <a onClick={this.NextInventory.bind(this,prevnext.next_date)} className="badge badge-secondary badge-pill prev_next">Next 14 Days  &nbsp; &gt;
				                  </a>
				               </div>
				               <div class="clearfix"></div>
				            </div>
							))}
				            </Col>
				            <Col sm="12" md="12" lg="12" className="no-paddding">
				            <div class="table-responsive">
				               <div class="well well2">
				                  <div class="tab-content">
				                     <div class="tab-pane active" id="roomsview">
				                        <div id="inv_cal">
				                           <div class="topMargin">
				                              <div id="calender_grid">

				                                 <div className="row grid_hd" id="grid_hd">
				                                    <div class="cont_lt col-md-2 col-xs-3"><strong>Rooms </strong><br/></div>
				                                    <div class="srhd_rt col-md-10 col-xs-9">
													{this.state.date_list.map((datelist) => (
				                                       <div class="grid_srhd">
				                                          <small>{datelist.month}</small>
				                                          <em>{datelist.day}</em>
				                                          <strong>{datelist.week}</strong>
				                                       </div>
													))}
				                                    </div>
				                                 </div>

												 {this.state.room_list.map((list) => (

												 <div className="row grid_hd" id="grid_hd">
				                                    <div class="cont_lt col-md-2 col-xs-3"><strong>{list.display_name} </strong>
													</div>
														<div class="cont_rt col-md-10 col-xs-9" id="roominventory">
														{(list.inventory).map((inv) =>
				                                          <div class="grid_cont">
				                                             <div>
				                                               <p><button class="btn btn-xs btn-primary" type="button">{inv.discount}</button></p>
				                                                <p>%</p>
				                                             </div>

				                                          </div>
														)}


				                                       </div>
				                                 </div>

												))}
				                              </div>
				                           </div>
				                           <div class="clearfix"></div>
				                           <div class="">
				                              <div class="pull-right">
				                                 <span class="badge badge-primary badge-pill inv-info">Discount</span>
				                              </div>
				                              <div class="clearfix"></div>
				                           </div>
				                        </div>
				                     </div>
				                  </div>
				               </div>
				            </div>
				            </Col>
				         </CardBody>
				      </Card>
				      </Col>
				   </Row>
				</div>


      );
  }
}


class DayNames extends React.Component {
  render() {
    return (
      <div className="row days-header day_head">
        <span className="box day-name">Mon</span>
        <span className="box day-name">Tue</span>
        <span className="box day-name">Wed</span>
        <span className="box day-name">Thu</span>
        <span className="box day-name">Fri</span>
        <span className="box day-name">Sat</span>
        <span className="box day-name">Sun</span>
      </div>
    );
  }
}

class Week extends React.Component {
  render() {
    let days = [];
    let date = this.props.previousCurrentNextView;
    let currentMonthView = this.props.currentMonthView;
    let selected = this.props.selected;
    let select = this.props.select;

    for (var i = 0; i < 7; i++) {

      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isToday: date.isSame(new Date(), "day"),
        date: date
      };

      days.push(<Day day={day} selected={selected} select={select} />);
      date = date.clone();
      date.add(1, "d");
    }
    return (
      <div className="row week">
        {days}
      </div>
    );
  }
}

class Day extends React.Component {
  render() {
    let day = this.props.day;
    let selected = this.props.selected;
    let select = this.props.select;

    return (
      <div
        className={
          "day" +
          (day.isToday ? " today" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(selected) ? " selected" : "")
        }
        onClick={() => select(day)}
      >
        <div className="day-number">{day.number}</div>
      </div>
    );
  }
}

export default Calendar;
