export function getData(type, userData) {
	let BaseURL = 'https://api.bungalowsinlonavala.com/v1/';
	let headers = new Headers();
	return new Promise((resolve, reject) =>{
		headers.append('auth-key', 'bookmystayrestapi');
		headers.append('authorizations', '25iwFyq/LSO1U');
		headers.append('client-service', 'frontend-client');
		headers.append('content-type', 'application/x-www-form-urlencoded');
		headers.append('user-id','1');

		fetch(BaseURL+type, {
			method: 'POST',
            //headers: headers,
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}
