import React from 'react';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';
import {getData} from '../../services/index';
import { message } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';





class ContactInfo extends React.Component {


	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/contact_details',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
					this.setState({
						ListData: result['data']
					});
					this.setState(() => ({
						hotel_phone : result['data'][0]['hotel_phone'],
						hotel_mobile : result['data'][0]['hotel_mobile'],
						hotel_email : result['data'][0]['hotel_email'],
						email_list : result['data'][0]['email_list']
					}));
					}
				}
			});
		}
	}

   onChange(e){
		this.setState({[e.target.name]:e.target.value});

	}

	handleSubmit(){

	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		 var app = {
				hotel_id : storedArray['data'][0]['hotel_id'],
				hotel_phone : this.state.hotel_phone,
				hotel_mobile : this.state.hotel_mobile,
				hotel_email : this.state.hotel_email,
				email_list : this.state.email_list,
			}

	  getData('extranet/contact_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				message.success('Contact Update Successfull!',2);

				this.setState({redirectToReferrer: true});
				}
				else{

				}
			}
		});

  }

  constructor(props) {
	super(props);
	this.state = {
		hotel_phone : '',
		hotel_mobile : '',
		hotel_email : '',
		phone_list : '',
		email_list : '',
		website_list : '',
		persons : [],
		update : [],
		ListData : []
	};
	this.onChange = this.onChange.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
	}


render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/amenities");
}
return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Contact Info</CardTitle>
            <CardBody>

			<form className="login100-form validate-form">


             <Col sm="12" md="12" lg="12" className="no_pad">
			 <div id="alert" className="m-b-10"></div>
                <FormGroup>
				<Row>
				<Col sm="12" md="4" className="mt10" >
                  <Label for="exampleCustomSelect">Hotel Phone *</Label>
				   <Input type="number" defaultValue={this.state.hotel_phone} name="hotel_phone" id="hotel_phone" onChange={this.onChange}/>
				</Col>
				<Col sm="12" md="4" className="mt10" >
                  <Label for="exampleCustomSelect">Hotel Mobile *</Label>
				   <Input type="number" defaultValue={this.state.hotel_mobile} name="hotel_mobile" id="hotel_mobile" onChange={this.onChange}/>
				</Col>
				<Col sm="12" md="4" className="mt10" >
					<Label for="exampleCustomSelect">Hotel Email</Label>
					<Input type="email" defaultValue={this.state.hotel_email} name="hotel_email" id="hotel_email" onChange={this.onChange}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			 <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>

				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Email List *</Label>
				   <Input type="textarea"  defaultValue={this.state.email_list} rows="5" name="email_list" id="email_list" onChange={this.onChange}/>
					<FormText>separate two emails with comma", "</FormText>
				</Col>
				</Row>
                </FormGroup>
			</Col>




			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="12" className="center">
                  <Button color="primary" onClick={this.handleSubmit} > Save & Continue</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ContactInfo;
