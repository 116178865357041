import React from 'react';

import moment from 'moment';

import { DatePicker,Checkbox,Select  } from "antd";

import 'antd/dist/antd.css';

import { message } from 'antd';

import MultipleDatePicker from '../../lib';


import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';


import {getData} from '../../services/index';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const defaultCheckedList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const { Option } = Select;

class ManageOffer extends React.Component {


constructor(props) {
super(props);
var storedArray = JSON.parse(localStorage.getItem("extranetData"));
this.state = {
	hotel_id : storedArray['data'][0]['hotel_id'],
	room_type : [],
    indeterminate: false,
    checkAll: false,room_id : '',
    start_end_date : '',
    end_date : '',
    discount : '',
    daylist : '',
    blackout_days : ''
};
this.onChangeDate = this.onChangeDate.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.onChangeText = this.onChangeText.bind(this);

}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/room_list',this.state).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							room_type: result['data']
						})
					}
				}
			});
		}
	}

    onChangeDate(event) {
	  this.setState({
		start_end_date: event,

	  });
	}

  onChange = checkedList => {
    this.setState({
      checkedList,
	  daylist : checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };
onChangeText(e){
		this.setState({[e.target.name]:e.target.value});

	}
  onCheckAllChange = e => {
    this.setState({
	  daylist : defaultCheckedList,
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };


  onSubmit(event) {
	  this.setState({
		start_end_date: event,

	  });
	}

handleSubmit(){
	  var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	  var room_id = document.getElementById("room_id").value;
	  if(this.state.blackout_days!='')
	  {
		var blackout=this.state.blackout_days;
	  }
	  else{
		var blackout='';
	  }

	  if(room_id==''){
		message.error('Please select room',2);
	  }
	  else if(this.state.start_end_date==''){
		message.error('Please select date',2);
	  }
	  else if(this.state.daylist==''){
		message.error('Please select days of week',2);
	  }
	  else if(this.state.discount==''){
		message.error('Please enter discount percentage',2);
	  }
	  else{

	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		room_id : room_id,
		start_end_date : this.state.start_end_date,
		discount : this.state.discount,
		daylist : this.state.daylist,
		blackout:blackout
	  }

	  getData('extranet/manage_offer_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				if(result['data']['tag']=='0'){
					message.error(result['data']['msg'],2);
				}else{
					message.success(result['data']['msg'],2);
				}


				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});
	  }

}

render() {

return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Manage Offers</CardTitle>
            <CardBody>

             <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Room Type *</Label>
				</Col>
				<Col sm="12" md="4" >

				  <select class="form-control" id="room_id" name="room_id">
                                 <option value="" selected="selected">Select Room</option>
								 {this.state.room_type.map((list) => (
                                 <option value={`${list.room_id}`} >{list.display_name}</option>
								 ))}
                              </select>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Select Date *</Label>
				</Col>
				<Col sm="12" md="4" >
					  <FormGroup>
						<RangePicker
      disabledDate={disabledDate}
      disabledTime={disabledRangeTime}
      format="DD MMM, YYYY"
	  name ="start_end_date"
	  onChange={this.onChangeDate}
    />
					  </FormGroup>
				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Days Of Week</Label>
				</Col>
				<Col sm="12" md="6" >
                  <Form inline>
				  <div className="col-md-10 ant-checkbox-wrapper-check-all">
				 <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            Check all
          </Checkbox>
		  </div>
		  <br />
		  <div className="col-md-12 no-paddding">
		  <CheckboxGroup
          options={plainOptions}
          value={this.state.checkedList}
          onChange={this.onChange}
		  name="daylist"
        />
		 </div>
				  </Form>



				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Percentage *</Label>
				</Col>
				<Col sm="12" md="4" >
                  <Input type="text" name="discount" placeholder="Enter Discount Percentage" onChange={this.onChangeText}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Blackout Dates</Label>
				</Col>
				<Col sm="12" md="4" className="multipledatepicker">
					<MultipleDatePicker  onSubmit={dates => this.setState({blackout_days : dates})} minDate={new Date()} />
				</Col>
				</Row>
                </FormGroup>
			</Col>

<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  &nbsp;
				</Col>
				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit}>Update Inventory</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ManageOffer;
