import React from 'react';
import {Redirect} from 'react-router-dom';
import { Table,TabContent,
  TabPane,Nav, NavItem,CardText, NavLink} from 'reactstrap';
import axios from 'axios';
import {getData} from '../../services/index';
import { message } from 'antd';
import { Switch, Icon } from 'antd';
  import classnames from 'classnames';


import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



//var date = new Date();



class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			new_pass: '',
			conf_pass: '',
			hotel_id: '',
			percentage: '5',
			flat: '0',
			pay_before: '1',
			persons : [],
			activeTab : '0',
			opened: false,
			opened1: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleBox = this.toggleBox.bind(this);
		this.toggleBox1 = this.toggleBox1.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab': tab
			});
		}
	}

	toggleBox() {
		const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
	}

	toggleBox1() {
		const { opened1 } = this.state;
		this.setState({
			opened1: !opened1,
		});
	}

	handleSubmit(){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		if(this.state.opened==true){
			if(this.state.opened1==false){
				var pay_value=document.getElementById("flat").value;
			}
			else{
				var pay_value=document.getElementById("percentage").value;
			}
		}
		else{
			var pay_value=0;
		}
		var app = {
			hotel_id : storedArray['data'][0]['hotel_id'],
			pay_hotel : this.state.activeTab,
			pre_pay_policy : this.state.opened,
			pay_type : this.state.opened1,
			pay_value : pay_value,
			pay_before : this.state.pay_before
		}

		getData('extranet/pay_at_hotel',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['status']==200){
						message.config({ top: 150});
						message.success('Update Successfull!',2);
					}
					else{
						message.error('Not Update!',2);
					}
				}
			});
		}

		onChange(e){
			this.setState({[e.target.name]:e.target.value});
		}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			getData('extranet/pay_at_hotel_info',this.state).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						if(result['data'][0]['pre_pay_policy']=='1'){
							this.setState({
								opened: true
							});
						}
						if(result['data'][0]['pay_type']=='percentage'){
							this.setState({
								opened1: true,
							});
						}
						this.setState(() => ({
							activeTab : result['data'][0]['pay_hotel'],
							pay_hotel : result['data'][0]['pay_hotel'],
							pre_pay_policy : result['data'][0]['pre_pay_policy'],
							pay_type : result['data'][0]['pay_type'],
							pay_value : result['data'][0]['pay_value'],
							pay_before : result['data'][0]['pay_before'],
						}));
					}
				}
			});
		}
    }

render() {

var { title, children } = this.props;

return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Pay At Hotel</CardTitle>
            <CardBody>

             <form className="login100-form validate-form pah">
			 <Col sm="12" md="12" lg="12">
			 <div id="alert" className="m-b-10"></div>
				<Nav tabs>
				  <NavItem>
					<NavLink
					  className={classnames({
						'active': this.state.activeTab === '1'
					  })}
					  onClick={() => {
						this.toggle('1');
					  }}
					>
					Enable

					</NavLink>
				  </NavItem>
				  <NavItem>
					<NavLink
					  className={classnames({
						'active': this.state.activeTab === '0'
					  })}
					  onClick={() => {
						this.toggle('0');
					  }}
					>
					  Disable

					</NavLink>
				  </NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
				   <TabPane tabId="1">
				   <div className="box pah-border">
				    <Label for="exampleCustomSelect" className="pa m-t-10">Do you want to create Pay At Hotel Pre Pay Policy?</Label><br/>
						{this.state.opened && (
							<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" onClick={this.toggleBox}/>
						)}
						{!this.state.opened && (
							<Switch checkedChildren="Yes" unCheckedChildren="No" onClick={this.toggleBox}/>
						)}



						{this.state.opened && (
							<div class="boxContent pah-border row cus">
							  <Col md="2" className="m-t-10">
							  <Label for="exampleCustomSelect" className="pa m-t-10">Customer has to pay</Label>
							  </Col>
							  <Col md="2" className="m-t-10">
							  {this.state.opened1 && (
								 <Input type="select" name="percentage" onChange={this.onChange} id="percentage">
								  <option value="5"  selected={`${this.state.pay_value==5?'selected':''}`}>5 </option>
								  <option value="10" selected={`${this.state.pay_value==10?'selected':''}`}>10 </option>
								  <option value="15" selected={`${this.state.pay_value==15?'selected':''}`}>15 </option>
								  <option value="20" selected={`${this.state.pay_value==20?'selected':''}`}>20 </option>
								  <option value="25" selected={`${this.state.pay_value==25?'selected':''}`}>25 </option>
								  <option value="30" selected={`${this.state.pay_value==30?'selected':''}`}>30 </option>
								  <option value="35" selected={`${this.state.pay_value==35?'selected':''}`}>35 </option>
								  </Input>
							  )}
							  {!this.state.opened1 && (
							  <Input type="text" onChange={this.onChange} name="flat" defaultValue={this.state.pay_value} id="flat" placeholder="Eg : 1000"/>
							  )}
							  </Col>
							  <Col md="2" className="m-t-10">


						{this.state.opened1 && (
							<Switch defaultChecked checkedChildren="Percent" unCheckedChildren="Fixed" onClick={this.toggleBox1}/>
						)}
						{!this.state.opened1 && (
							<Switch checkedChildren="Percent" unCheckedChildren="Fixed" onClick={this.toggleBox1}/>
						)}

							  </Col>
							  <Col md="1" className="m-t-10"><p className="m-t-10">before</p></Col>
							  <Col md="2" className="m-t-10">
							  <Input type="select" name="pay_before" onChange={this.onChange} id="pay_before">
								<option value="1" selected={`${this.state.pay_before==1?'selected':''}`}>1 </option>
								<option value="2" selected={`${this.state.pay_before==2?'selected':''}`}>2 </option>
								<option value="3" selected={`${this.state.pay_before==3?'selected':''}`}>3 </option>
								<option value="4" selected={`${this.state.pay_before==4?'selected':''}`}>4 </option>
								<option value="5" selected={`${this.state.pay_before==5?'selected':''}`}>5 </option>
								<option value="6" selected={`${this.state.pay_before==6?'selected':''}`}>6 </option>
								<option value="7" selected={`${this.state.pay_before==7?'selected':''}`}>7 </option>
							  </Input>
							  </Col>
							  <Col md="2" className="m-t-10"><p className="m-t-10">days of checkin</p></Col>
							</div>
						)}
					</div>
				   </TabPane>
			   </TabContent>

			 </Col>




			<Col sm="12" md="12" lg="12" className="m-t-10">
                <FormGroup>
				<Row>

				<Col sm="12" md="4" className="m-t-10">
                  <Button color="primary" className="m-t-10" onClick={this.handleSubmit} >Save Now</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ChangePassword;
