import React from 'react';
import 'react-datetime/css/react-datetime.css';
import {Accordion, List} from 'antd-mobile';
import '../../assets/scss/antd-mobile.min.css';
import { message } from 'antd';
import {
	Table,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	FormGroup,
	CustomInput,
	Input,
	Button
} from 'reactstrap';

import {getData} from '../../services/index';

class EditRooms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			room_type : [],
			amenities_list : [],
			type_room : '',
			bed_type : '',
			extra_bed_type : '',
			room_view : '',
			adults_base : '',
			child_base : '',
			adults_max : '',
			child_max : '',
			infant_max : '',
			guest_max : '',
			ep_plan:true,
			cp_plan:false,
			map_plan:false,
			ap_plan:false,
			cp_amount:'',
			cp_type:'',
			cp_extra:'',
			map_amount:'',
			map_type:'',
			map_extra:'',
			ap_amount:'',
			ap_type:'',
			ap_extra:'',
			room_id : this.props.match.params.id,
		};
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.activeRateChange = this.activeRateChange.bind(this);
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.room_list();
		}
	}


    activeRateChange(name,event){
		this.setState({[name]: event.target.checked });
	}

	handleChange(event){
		if (localStorage.getItem('extranetData')) {
			var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = extranetArray['data'][0]['hotel_id'];
			var room_id = this.state.room_id;
			var amenities_id = event.target.value;
			if (event.target.checked == true) {
				var is_active = '1';
			} else {
				var is_active = '0';
			}
			var app = {
				hotel_id: hotel_id,
				amenities_id: amenities_id,
				is_active: is_active,
				room_id : room_id,
			}
			getData('extranet/room_amenities_update', app).then((result) => {
			message.config({ top: 150});
			message.success('Amenities Updated!',2);
			this.room_list();  });
		}
	}


	room_list = () => {
		if (localStorage.getItem('extranetData')){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var room_id = this.props.match.params.id;
		this.state.hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {
		room_id : room_id,
		hotel_id : this.state.hotel_id
		}
		getData('extranet/room_details',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
				this.setState({
					room_type: result['data']
				})
				}
				document.getElementById("display_name").value = result['data'][0]['display_name'];
				document.getElementById("room_size_length").value = result['data'][0]['room_size_length'];
				document.getElementById("room_size_width").value = result['data'][0]['room_size_width'];
				document.getElementById("long_description").value = result['data'][0]['long_description'];
				document.getElementById("total_rooms").value = result['data'][0]['total_rooms'];

				this.setState({
					type_room : result['data'][0]['room_type'],
					bed_type : result['data'][0]['bed_type'],
					extra_bed_type : result['data'][0]['extra_bed_type'],
					room_view : result['data'][0]['room_view'],
					adults_base : result['data'][0]['adults_base'],
					child_base : result['data'][0]['child_base'],
					adults_max : result['data'][0]['adults_max'],
					child_max : result['data'][0]['child_max'],
					infant_max : result['data'][0]['infant_max'],
					guest_max : result['data'][0]['guest_max'],
					amenities_list : result['data'][0]['amenities_list']
				});

				for(var i=0;i<result['data'][0]['room_rate_plan'].length;i++){
					if(result['data'][0]['room_rate_plan'][i]['plan_type']=='cp'){
						this.setState({
							cp_plan:true,
							cp_amount:result['data'][0]['room_rate_plan'][i]['by_amount'],
							cp_type:result['data'][0]['room_rate_plan'][i]['rate_type'],
							cp_extra:result['data'][0]['room_rate_plan'][i]['extra_value']
						});
					}
					if(result['data'][0]['room_rate_plan'][i]['plan_type']=='map'){
						this.setState({
							map_plan:true,
							map_amount:result['data'][0]['room_rate_plan'][i]['by_amount'],
							map_type:result['data'][0]['room_rate_plan'][i]['rate_type'],
							map_extra:result['data'][0]['room_rate_plan'][i]['extra_value']
						});
					}
					if(result['data'][0]['room_rate_plan'][i]['plan_type']=='ap'){
						this.setState({
							ap_plan:true,
							ap_amount:result['data'][0]['room_rate_plan'][i]['by_amount'],
							ap_type:result['data'][0]['room_rate_plan'][i]['rate_type'],
							ap_extra:result['data'][0]['room_rate_plan'][i]['extra_value']
						});
					}
				}


			}
		});



	}
}

		UpdateRoom = () => {
			if (localStorage.getItem('extranetData')) {
				var cp_flag=1;
				var map_flag=1;
				var ap_flag=1;
				var rate_plan=[];

				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_type = document.getElementById("room_type").value;
				var display_name = document.getElementById("display_name").value;
				var room_size_length = document.getElementById("room_size_length").value;
				var room_size_width = document.getElementById("room_size_width").value;
				var total_rooms = document.getElementById("total_rooms").value;
				var bed_type = document.getElementById("bed_type").value;
				var room_view = document.getElementById("room_view").value;
				var extra_bed_type = document.getElementById("extra_bed_type").value;
				var long_description = document.getElementById("long_description").value;
				var adults_base = document.getElementById("adults_base").value;
				var child_base = document.getElementById("child_base").value;
				var adults_max = document.getElementById("adults_max").value;
				var child_max = document.getElementById("child_max").value;
				var infant_max = document.getElementById("infant_max").value;
				var guest_max = document.getElementById("guest_max").value;
				var room_amenities = '';
				var room_id = this.props.match.params.id;


				if(document.getElementById("active_rate_plan2").checked==true){
					var cp_will_be 		= document.getElementById("cp_will_be").value;
					var cp_than 		= document.getElementById("cp_than").value;
					var cp_by_amount 	= document.getElementById("cp_by_amount").value;
					var cp_rate_type 	= document.getElementById("cp_rate_type").value;
					var cp_extra_amount = document.getElementById("cp_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan2").value;
					if(cp_by_amount>0){
						cp_flag=1;
						rate_plan.push({
							will_be:cp_will_be,
							than:cp_than,
							by_amount:cp_by_amount,
							rate_type:cp_rate_type,
							extra_amount:cp_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter CP rate plan amount',2);
						cp_flag=0;
					}
				}

				if(document.getElementById("active_rate_plan3").checked==true){
					var map_will_be 	 = document.getElementById("map_will_be").value;
					var map_than 		 = document.getElementById("map_than").value;
					var map_by_amount 	 = document.getElementById("map_by_amount").value;
					var map_rate_type 	 = document.getElementById("map_rate_type").value;
					var map_extra_amount = document.getElementById("map_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan3").value;
					if(map_by_amount>0){
						map_flag=1;
						rate_plan.push({
							will_be:map_will_be,
							than:map_than,
							by_amount:map_by_amount,
							rate_type:map_rate_type,
							extra_amount:map_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter MAP rate plan amount',2);
						map_flag=0;
					}
				}

				if(document.getElementById("active_rate_plan4").checked==true){
					var ap_will_be 	     = document.getElementById("ap_will_be").value;
					var ap_than 		 = document.getElementById("ap_than").value;
					var ap_by_amount 	 = document.getElementById("ap_by_amount").value;
					var ap_rate_type 	 = document.getElementById("ap_rate_type").value;
					var ap_extra_amount  = document.getElementById("ap_extra_amount").value;
					var plan_name = document.getElementById("active_rate_plan4").value;
					if(ap_by_amount>0){
						ap_flag=1;
						rate_plan.push({
							will_be:ap_will_be,
							than:ap_than,
							by_amount:ap_by_amount,
							rate_type:ap_rate_type,
							extra_amount:ap_extra_amount,
							plan_name:plan_name
						});
					}
					else{
						message.config({ top: 150});
						message.warning('Please enter AP rate plan amount',2);
						ap_flag=0;
					}
				}
				if(room_amenities.length==0){
					room_amenities='0';
				}




				var app = {
					hotel_id:hotel_id,
					room_id:room_id,
					room_type:room_type,
					display_name:display_name,
					room_size_length:room_size_length,
					room_size_width:room_size_width,
					total_rooms:total_rooms,
					bed_type:bed_type,
					room_view:room_view,
					extra_bed_type:extra_bed_type,
					long_description:long_description,
					adults_base:adults_base,
					child_base:child_base,
					adults_max:adults_max,
					child_max:child_max,
					infant_max:infant_max,
					guest_max:guest_max,
					room_amenities:room_amenities,
					rate_plan:rate_plan
				}

				if(display_name==''){
					message.config({ top: 150});
					message.warning('Display name can not be blank!',2);
				}
				else if(total_rooms==0){
					message.config({ top: 150});
					message.warning('Total rooms can not be 0',2);
				}
				else{
					if(cp_flag>0 && map_flag>0 && ap_flag>0){
						getData('extranet/edit_rooms', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Room Update Succesfully!',2);
							this.setState({redirectToReferrer: true});
						}
					}
				});
					}
				}




			}

		};


render() {
if (this.state.redirectToReferrer){
	this.props.history.push("/property/rooms");
}
return (
<div>
   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
      <Card>
         <CardTitle className="bg-light border-bottom p-3 mb-0 hidden-xs">
            Edit Rooms
         </CardTitle>
         <CardBody>
            <Col sm="12" md="12" lg="12" className="border-bottom mb-20 no-padding">
            <Accordion defaultActiveKey="0" className="my-accordion">
               <Accordion.Panel header="Edit Room" className="pad">

                  <fieldset>
                     <legend class="m-legend">Rooms Info</legend>
                     <div class="row">
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_roomtype">Room Type<span class="text-error fontBold"></span></label>
                              <select class="form-control" data-required="true" id="room_type" name="room_type" required="" onChange={this.onChange}>
                                 <option value="" selected={this.state.type_room === ''} >Select Roomtype</option>
                                 <option value="Deluxe" selected={this.state.type_room === 'Deluxe'}>Deluxe</option>
                                 <option value="Standard" selected={this.state.type_room === 'Standard'}>Standard</option>
                                 <option value="Luxury" selected={this.state.type_room === 'Luxury'}>Luxury</option>
                                 <option value="Master" selected={this.state.type_room === 'Master'}>Master</option>
                                 <option value="Common" selected={this.state.type_room === 'Common'}>Common</option>
                                 <option value="Tent" selected={this.state.type_room === 'Tent'}>Tent</option>
                                 <option value="Other" selected={this.state.type_room === 'Other'}>other</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_roomtypename">Display Name<span class="text-error fontBold">*</span></label>
                              <input class="form-control" data-required="true" id="display_name" maxlength="100" name="display_name" type="text" onChange={this.onChange}/>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad" id="room_size">
                           <div class="col-md-12 no_pad">
                              <label for="id_roomsize">Room size</label>
                              <div class="row">
                                 <div class="col-md-5 col-xs-5 no_pad">
                                    <input name="room_size_length" id="room_size_length" data-error-message="Value should be a valid length" pattern="^\s*[0-9]+([\.][0-9]+)?\s*$" class="form-control pull-left parsley-validated parsley-error" placeholder="Length (Sq.ft.)" type="text" onChange={this.onChange}/>
                                 </div>
                                 <div class="col-md-1 col-xs-2  text-center">
                                    <span class="pull-left"> X </span>
                                 </div>
                                 <div class="col-md-5 col-xs-5 no_pad">
                                    <input pattern="^\s*[0-9]+([\.][0-9]+)?\s*$" name="room_size_width" id="room_size_width" class="form-control pull-left parsley-validated parsley-error" placeholder="Breadth (Sq.ft.)" data-error-message="Value should be a valid breadth" type="text" onChange={this.onChange}/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label>Total Rooms<span class="text-error fontBold">*</span></label>
                              <input class="form-control" data-required="true" data-type="digits" required="" onkeyup="check_room()" id="total_rooms" name="noofrooms" type="text" onChange={this.onChange}/>
                              <span class="text-success" id="room_errors"></span>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_bedtype">Bed Type</label>
                              <select class="form-control" id="bed_type" name="bed_type" onChange={this.onChange}>
                                 <option value="" selected={this.state.bed_type === ''}>---------</option>
                                 <option value="Twin" selected={this.state.bed_type === 'Twin'}>Twin</option>
                                 <option value="King" selected={this.state.bed_type === 'King'}>King</option>
                                 <option value="Queen" selected={this.state.bed_type === 'Queen'}>Queen</option>
                                 <option value="Double" selected={this.state.bed_type === 'Double'}>Double</option>
                                 <option value="Single" selected={this.state.bed_type === 'Single'}>Single</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_roomview">Room View</label>
                              <select class="form-control" id="room_view" name="room_view" onChange={this.onChange}>
                                 <option value="Sea View" selected={this.state.room_view === 'Sea View'}>Sea View</option>
                                 <option value="Valley View" selected={this.state.room_view === 'Valley View'}>Valley View</option>
                                 <option value="Hill View" selected={this.state.room_view === 'Hill View'}>Hill View</option>
                                 <option value="Pool View" selected={this.state.room_view === 'Pool View'}>Pool View</option>
                                 <option value="Garden View" selected={this.state.room_view === 'Garden View'}>Garden View</option>
                                 <option value="River View" selected={this.state.room_view === 'River View'}>River View</option>
                                 <option value="Lake View" selected={this.state.room_view === 'Lake View'}>Lake View</option>
                                 <option value="Palace View" selected={this.state.room_view === 'Palace View'}>Palace View</option>
                                 <option value="Bay View" selected={this.state.room_view === 'Bay View'}>Bay View</option>
                                 <option value="Jungle View" selected={this.state.room_view === 'Jungle View'}>Jungle View</option>
                                 <option value="City View" selected={this.state.room_view === 'City View'}>City View</option>
                                 <option value="No View" selected={this.state.room_view === 'No View'}>No View</option>
                              </select>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_extra_bed">Extra Bed Type</label>
                              <select class="form-control" id="extra_bed_type" name="extra_bed_type" onChange={this.onChange}>
                                 <option value="0" selected="selected" selected={this.state.extra_bed_type === '0'}>No Extra Bed</option>
                                 <option value="Matress" selected={this.state.extra_bed_type === 'Matress'}>Matress</option>
                                 <option value="Cot" selected={this.state.extra_bed_type === 'Cot'}>Cot</option>
                                 <option value="Sofa cum bed" selected={this.state.extra_bed_type === 'Sofa cum bed'}>Sofa cum bed</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-8 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_description">Long Description</label>
                              <textarea class="form-control" cols="40" id="long_description" name="long_description" rows="5" onChange={this.onChange}></textarea>
                           </div>
                        </div>
                     </div>
                  </fieldset>
                  <fieldset>
                     <legend class="m-legend">Room Occupancy</legend>
                     <div class="row">
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_base_adult_occupancy">Adults (base)</label>
                              <select class="form-control" id="adults_base" name="adults_base" onChange={this.onChange}>
                                 <option value="0" selected={this.state.adults_base === '0'}>0</option>
                                 <option value="1" selected={this.state.adults_base === '1'}>1</option>
                                 <option value="2" selected={this.state.adults_base === '2'}>2</option>
                                 <option value="3" selected={this.state.adults_base === '3'}>3</option>
                                 <option value="4" selected={this.state.adults_base === '4'}>4</option>
                                 <option value="5" selected={this.state.adults_base === '5'}>5</option>
                                 <option value="6" selected={this.state.adults_base === '6'}>6</option>
                                 <option value="7" selected={this.state.adults_base === '7'}>7</option>
                                 <option value="8" selected={this.state.adults_base === '8'}>8</option>
                                 <option value="9" selected={this.state.adults_base === '9'}>9</option>
                                 <option value="10" selected={this.state.adults_base === '10'}>10</option>
                                 <option value="11" selected={this.state.adults_base === '11'}>11</option>
                                 <option value="12" selected={this.state.adults_base === '12'}>12</option>
                                 <option value="13" selected={this.state.adults_base === '13'}>13</option>
                                 <option value="14" selected={this.state.adults_base === '14'}>14</option>
                                 <option value="15" selected={this.state.adults_base === '15'}>15</option>
                                 <option value="16" selected={this.state.adults_base === '16'}>16</option>
                                 <option value="17" selected={this.state.adults_base === '17'}>17</option>
                                 <option value="18" selected={this.state.adults_base === '18'}>18</option>
                                 <option value="19" selected={this.state.adults_base === '19'}>19</option>
                                 <option value="20" selected={this.state.adults_base === '20'}>20</option>
                                 <option value="21" selected={this.state.adults_base === '21'}>21</option>
                                 <option value="22" selected={this.state.adults_base === '22'}>22</option>
                                 <option value="23" selected={this.state.adults_base === '23'}>23</option>
                                 <option value="24" selected={this.state.adults_base === '24'}>24</option>
                                 <option value="25" selected={this.state.adults_base === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_base_child_occupancy">Child (Base)</label>
                              <select class="form-control" id="child_base" name="child_base" onChange={this.onChange}>
                                 <option value="0" selected={this.state.child_base === '0'}>0</option>
                                 <option value="1" selected={this.state.child_base === '1'}>1</option>
                                 <option value="2" selected={this.state.child_base === '2'}>2</option>
                                 <option value="3" selected={this.state.child_base === '3'}>3</option>
                                 <option value="4" selected={this.state.child_base === '4'}>4</option>
                                 <option value="5" selected={this.state.child_base === '5'}>5</option>
                                 <option value="6" selected={this.state.child_base === '6'}>6</option>
                                 <option value="7" selected={this.state.child_base === '7'}>7</option>
                                 <option value="8" selected={this.state.child_base === '8'}>8</option>
                                 <option value="9" selected={this.state.child_base === '9'}>9</option>
                                 <option value="10" selected={this.state.child_base === '10'}>10</option>
                                 <option value="11" selected={this.state.child_base === '11'}>11</option>
                                 <option value="12" selected={this.state.child_base === '12'}>12</option>
                                 <option value="13" selected={this.state.child_base === '13'}>13</option>
                                 <option value="14" selected={this.state.child_base === '14'}>14</option>
                                 <option value="15" selected={this.state.child_base === '15'}>15</option>
                                 <option value="16" selected={this.state.child_base === '16'}>16</option>
                                 <option value="17" selected={this.state.child_base === '17'}>17</option>
                                 <option value="18" selected={this.state.child_base === '18'}>18</option>
                                 <option value="19" selected={this.state.child_base === '19'}>19</option>
                                 <option value="20" selected={this.state.child_base === '20'}>20</option>
                                 <option value="21" selected={this.state.child_base === '21'}>21</option>
                                 <option value="22" selected={this.state.child_base === '22'}>22</option>
                                 <option value="23" selected={this.state.child_base === '23'}>23</option>
                                 <option value="24" selected={this.state.child_base === '24'}>24</option>
                                 <option value="25" selected={this.state.child_base === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_max_adult_occupancy">Adults (Max)</label>
                              <select class="form-control" id="adults_max" name="adults_max" onChange={this.onChange}>
                                 <option value="0" selected={this.state.adults_max === '0'}>0</option>
                                 <option value="1" selected={this.state.adults_max === '1'}>1</option>
                                 <option value="2" selected={this.state.adults_max === '2'}>2</option>
                                 <option value="3" selected={this.state.adults_max === '3'}>3</option>
                                 <option value="4" selected={this.state.adults_max === '4'}>4</option>
                                 <option value="5" selected={this.state.adults_max === '5'}>5</option>
                                 <option value="6" selected={this.state.adults_max === '6'}>6</option>
                                 <option value="7" selected={this.state.adults_max === '7'}>7</option>
                                 <option value="8" selected={this.state.adults_max === '8'}>8</option>
                                 <option value="9" selected={this.state.adults_max === '9'}>9</option>
                                 <option value="10" selected={this.state.adults_max === '10'}>10</option>
                                 <option value="11" selected={this.state.adults_max === '11'}>11</option>
                                 <option value="12" selected={this.state.adults_max === '12'}>12</option>
                                 <option value="13" selected={this.state.adults_max === '13'}>13</option>
                                 <option value="14" selected={this.state.adults_max === '14'}>14</option>
                                 <option value="15" selected={this.state.adults_max === '15'}>15</option>
                                 <option value="16" selected={this.state.adults_max === '16'}>16</option>
                                 <option value="17" selected={this.state.adults_max === '17'}>17</option>
                                 <option value="18" selected={this.state.adults_max === '18'}>18</option>
                                 <option value="19" selected={this.state.adults_max === '19'}>19</option>
                                 <option value="20" selected={this.state.adults_max === '20'}>20</option>
                                 <option value="21" selected={this.state.adults_max === '21'}>21</option>
                                 <option value="22" selected={this.state.adults_max === '22'}>22</option>
                                 <option value="23" selected={this.state.adults_max === '23'}>23</option>
                                 <option value="24" selected={this.state.adults_max === '24'}>24</option>
                                 <option value="25" selected={this.state.adults_max === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_max_child_occupancy">Child (Max)</label>
                              <select class="form-control" id="child_max" name="child_max" onChange={this.onChange}>
                                 <option value="0" selected={this.state.child_max === '0'}>0</option>
                                 <option value="1" selected={this.state.child_max === '1'}>1</option>
                                 <option value="2" selected={this.state.child_max === '2'}>2</option>
                                 <option value="3" selected={this.state.child_max === '3'}>3</option>
                                 <option value="4" selected={this.state.child_max === '4'}>4</option>
                                 <option value="5" selected={this.state.child_max === '5'}>5</option>
                                 <option value="6" selected={this.state.child_max === '6'}>6</option>
                                 <option value="7" selected={this.state.child_max === '7'}>7</option>
                                 <option value="8" selected={this.state.child_max === '8'}>8</option>
                                 <option value="9" selected={this.state.child_max === '9'}>9</option>
                                 <option value="10" selected={this.state.child_max === '10'}>10</option>
                                 <option value="11" selected={this.state.child_max === '11'}>11</option>
                                 <option value="12" selected={this.state.child_max === '12'}>12</option>
                                 <option value="13" selected={this.state.child_max === '13'}>13</option>
                                 <option value="14" selected={this.state.child_max === '14'}>14</option>
                                 <option value="15" selected={this.state.child_max === '15'}>15</option>
                                 <option value="16" selected={this.state.child_max === '16'}>16</option>
                                 <option value="17" selected={this.state.child_max === '17'}>17</option>
                                 <option value="18" selected={this.state.child_max === '18'}>18</option>
                                 <option value="19" selected={this.state.child_max === '19'}>19</option>
                                 <option value="20" selected={this.state.child_max === '20'}>20</option>
                                 <option value="21" selected={this.state.child_max === '21'}>21</option>
                                 <option value="22" selected={this.state.child_max === '22'}>22</option>
                                 <option value="23" selected={this.state.child_max === '23'}>23</option>
                                 <option value="24" selected={this.state.child_max === '24'}>24</option>
                                 <option value="25" selected={this.state.child_max === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_max_infant_occupancy">Infant (Max)</label>
                              <select class="form-control" id="infant_max" name="infant_max" onChange={this.onChange}>
                                 <option value="0" selected={this.state.infant_max === '0'}>0</option>
                                 <option value="1" selected={this.state.infant_max === '1'}>1</option>
                                 <option value="2" selected={this.state.infant_max === '2'}>2</option>
                                 <option value="3" selected={this.state.infant_max === '3'}>3</option>
                                 <option value="4" selected={this.state.infant_max === '4'}>4</option>
                                 <option value="5" selected={this.state.infant_max === '5'}>5</option>
                                 <option value="6" selected={this.state.infant_max === '6'}>6</option>
                                 <option value="7" selected={this.state.infant_max === '7'}>7</option>
                                 <option value="8" selected={this.state.infant_max === '8'}>8</option>
                                 <option value="9" selected={this.state.infant_max === '9'}>9</option>
                                 <option value="10" selected={this.state.infant_max === '10'}>10</option>
                                 <option value="11" selected={this.state.infant_max === '11'}>11</option>
                                 <option value="12" selected={this.state.infant_max === '12'}>12</option>
                                 <option value="13" selected={this.state.infant_max === '13'}>13</option>
                                 <option value="14" selected={this.state.infant_max === '14'}>14</option>
                                 <option value="15" selected={this.state.infant_max === '15'}>15</option>
                                 <option value="16" selected={this.state.infant_max === '16'}>16</option>
                                 <option value="17" selected={this.state.infant_max === '17'}>17</option>
                                 <option value="18" selected={this.state.infant_max === '18'}>18</option>
                                 <option value="19" selected={this.state.infant_max === '19'}>19</option>
                                 <option value="20" selected={this.state.infant_max === '20'}>20</option>
                                 <option value="21" selected={this.state.infant_max === '21'}>21</option>
                                 <option value="22" selected={this.state.infant_max === '22'}>22</option>
                                 <option value="23" selected={this.state.infant_max === '23'}>23</option>
                                 <option value="24" selected={this.state.infant_max === '24'}>24</option>
                                 <option value="25" selected={this.state.infant_max === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                        <div class="form-group col-md-4 no_pad">
                           <div class="col-md-12 no_pad">
                              <label for="id_max_guest_occupancy">Total Guest (Max)</label>
                              <select class="form-control" id="guest_max" name="guest_max" onChange={this.onChange}>
                                 <option value="0" selected={this.state.guest_max === '0'}>0</option>
                                 <option value="1" selected={this.state.guest_max === '1'}>1</option>
                                 <option value="2" selected={this.state.guest_max === '2'}>2</option>
                                 <option value="3" selected={this.state.guest_max === '3'}>3</option>
                                 <option value="4" selected={this.state.guest_max === '4'}>4</option>
                                 <option value="5" selected={this.state.guest_max === '5'}>5</option>
                                 <option value="6" selected={this.state.guest_max === '6'}>6</option>
                                 <option value="7" selected={this.state.guest_max === '7'}>7</option>
                                 <option value="8" selected={this.state.guest_max === '8'}>8</option>
                                 <option value="9" selected={this.state.guest_max === '9'}>9</option>
                                 <option value="10" selected={this.state.guest_max === '10'}>10</option>
                                 <option value="11" selected={this.state.guest_max === '11'}>11</option>
                                 <option value="12" selected={this.state.guest_max === '12'}>12</option>
                                 <option value="13" selected={this.state.guest_max === '13'}>13</option>
                                 <option value="14" selected={this.state.guest_max === '14'}>14</option>
                                 <option value="15" selected={this.state.guest_max === '15'}>15</option>
                                 <option value="16" selected={this.state.guest_max === '16'}>16</option>
                                 <option value="17" selected={this.state.guest_max === '17'}>17</option>
                                 <option value="18" selected={this.state.guest_max === '18'}>18</option>
                                 <option value="19" selected={this.state.guest_max === '19'}>19</option>
                                 <option value="20" selected={this.state.guest_max === '20'}>20</option>
                                 <option value="21" selected={this.state.guest_max === '21'}>21</option>
                                 <option value="22" selected={this.state.guest_max === '22'}>22</option>
                                 <option value="23" selected={this.state.guest_max === '23'}>23</option>
                                 <option value="24" selected={this.state.guest_max === '24'}>24</option>
                                 <option value="25" selected={this.state.guest_max === '25'}>25</option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </fieldset>



									<fieldset class="rate_plan hidden-md hidden-lg hidden-sm">
										<legend class="m-legend">Rateplan</legend>
										<div class="row">
											 <div class="col-md-12 no_pad">
													<table class="table table-bordered">

														 <tbody>

									<tr>

									<td>
									 <CustomInput checked="true" type="checkbox" id="active_rate_plan1" name="active_rate_plan" label="EP" defaultValue="ep_plan"/>
									</td>
									<td>
										 <CustomInput checked="true" type="checkbox" id="default_rate_plan_1" name="default_rate_plan" defaultValue="ep" label="Default"/>
									</td>

																</tr>
																<tr>

									<td>
									<CustomInput onChange={this.activeRateChange.bind(this,'cp_plan')} checked={this.state.cp_plan} type="checkbox" id="active_rate_plan2" name="active_rate_plan" label="CP" defaultValue="cp"/>

									</td>
																	 <td>
																			<div class="form-group">
																				 <div class="rate_plan_pd">
																						<label> will be higher than Ep by</label>
																				 </div>
																			</div>
																			<br/>

																			<div class="form-group">
																				<div class="rate_plan_pd">
																					 <input class="form-control" name="cp_by_amount" id="cp_by_amount" placeholder="amount" type="text"/>
																					 <select class="form-control" id="cp_rate_type" name="cp_rate_type">
																							<option value="flat">Flat Rate</option>
																							<option value="percentage">Percentage (%)</option>
																					 </select>
																				</div>
																		 </div>
																	 <br/>
																	 <div class="form-group">
																	 <div class="rate_plan_pd">
																			<label> Extra Guest Linkage Value</label>
																	 </div>
																			 <div class="rate_plan_pd">
																				 <input class="form-control" name="cp_extra_amount" id="cp_extra_amount" placeholder="amount" type="text"/>
																			 </div>
																		</div>
																	</td>
																</tr>
																<tr>

									<td>
								 <CustomInput onChange={this.activeRateChange.bind(this,'map_plan')} checked={this.state.map_plan} type="checkbox" id="active_rate_plan3" name="active_rate_plan" label="MAP" defaultValue="map"/>
									</td>
																	 <td>
																			<div class="form-group">
																				 <div class="rate_plan_pd">
																						<label> will be higher than Ep by</label>
																				 </div>
																			</div>
																	 <br/>
																			<div class="form-group">
																				 <div class="rate_plan_pd">
																						<input class="form-control" name="map_by_amount" id="map_by_amount" placeholder="amount" type="text"/>
																						<select class="form-control" id="map_rate_type" name="map_rate_type">
																							 <option value="flat">Flat Rate</option>
																							 <option value="percentage">Percentage (%)</option>
																						</select>
																				 </div>
																			</div>
																		 <br/>
																		 <div class="form-group">
																		 <div class="rate_plan_pd">
																				<label> Extra Guest Linkage Value</label>
																		 </div>
																				 <div class="rate_plan_pd">
																					 <input class="form-control" name="map_extra_amount" id="map_extra_amount" placeholder="amount" type="text"/>
																				 </div>
																			</div>
																	 </td>
																</tr>
																<tr>

									<td>
								 <CustomInput onChange={this.activeRateChange.bind(this,'ap_plan')} checked={this.state.ap_plan} type="checkbox" id="active_rate_plan4" name="active_rate_plan" label="AP" defaultValue="ap"/>
									</td>
																	 <td>
																			<div class="form-group">
																				 <div class="rate_plan_pd">
																						<label> will be higher than Ep by</label>
																				 </div>
																			</div>
																	 <br/>
																			<div class="form-group">
																				 <div class="rate_plan_pd">
																						<input class="form-control" name="ap_by_amount" id="ap_by_amount" placeholder="amount" type="text"/>
																						<select class="form-control" id="ap_rate_type" name="ap_rate_type">
																							 <option value="flat">Flat Rate</option>
																							 <option value="percentage">Percentage (%)</option>
																						</select>
																				 </div>
																			</div>
																	 <br/>
																		 <div class="form-group">
																		 <div class="rate_plan_pd">
																				<label> Extra Guest Linkage Value</label>
																		 </div>
																				 <div class="rate_plan_pd">
																					 <input class="form-control" name="ap_extra_amount" id="ap_extra_amount" placeholder="amount" type="text"/>
																				 </div>
																			</div>
																	 </td>
																</tr>
														 </tbody>
													</table>
											 </div>
										</div>
									</fieldset>


									<fieldset class="rate_plan hidden-xs">
                        <legend class="m-legend">Rateplan</legend>
                        <div class="row">
                           <div class="col-md-12">
                              <table class="table table-bordered">
							  <thead>
      <tr>
        <th colspan="4"></th>
        <th>Extra Guest Linkage Value</th>
      </tr>
    </thead>
                                 <tbody>

									<tr>
                                       <td>
                                          <CustomInput checked="true" type="checkbox" id="active_rate_plan1" name="active_rate_plan" label="Active" defaultValue="ep_plan"/>
                                          <CustomInput checked="true" type="checkbox" id="default_rate_plan_1" name="default_rate_plan" defaultValue="ep" label="Default"/>
                                       </td>
									   <td>
									   EP
									   </td>
                                       <td>

                                       </td>
                                       <td>

                                       </td>
                                       <td class="td100">

                                       </td>
                                    </tr>




                                    <tr>
                                       <td>
                                          <CustomInput onChange={this.activeRateChange.bind(this,'cp_plan')} checked={this.state.cp_plan} type="checkbox" id="active_rate_plan2" name="active_rate_plan" label="Active" defaultValue="cp"/>
                                       </td>
									   <td>
									   CP
									   </td>
                                       <td>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="cp_will_be" name="cp_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="cp_than" name="cp_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" defaultValue={this.state.cp_amount} name="cp_by_amount" id="cp_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="cp_rate_type" name="cp_rate_type">
                                                   <option value="flat" selected={`${this.state.cp_type=='flat'?'selected':''}`}>Flat Rate</option>
                                                   <option value="percentage" selected={`${this.state.cp_type=='percentage'?'selected':''}`}>Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class="form-group">
                                             <div class="rate_plan_pd">
                                               <input class="form-control" defaultValue={this.state.cp_extra} name="cp_extra_amount" id="cp_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>



                                    <tr>
                                       <td>
                                           <CustomInput onChange={this.activeRateChange.bind(this,'map_plan')} checked={this.state.map_plan} type="checkbox" id="active_rate_plan3" name="active_rate_plan" label="Active" defaultValue="map"/>
                                       </td>
									   <td>
									   MAP
									   </td>
                                       <td>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="map_will_be" name="map_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="map_than" name="map_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" defaultValue={this.state.map_amount} name="map_by_amount" id="map_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="map_rate_type" name="map_rate_type">
                                                  <option value="flat" selected={`${this.state.map_type=='flat'?'selected':''}`}>Flat Rate</option>
                                                   <option value="percentage" selected={`${this.state.map_type=='percentage'?'selected':''}`}>Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class="form-group">
                                             <div class="rate_plan_pd">
                                               <input class="form-control"  defaultValue={this.state.map_extra} name="map_extra_amount" id="map_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                           <CustomInput onChange={this.activeRateChange.bind(this,'ap_plan')} checked={this.state.ap_plan} type="checkbox" id="active_rate_plan4" name="active_rate_plan" label="Active" defaultValue="ap"/>
                                       </td>
									   <td>
									   AP
									   </td>
                                       <td>
                                         <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label> will be</label>
                                                <select class="form-control" id="ap_will_be" name="ap_will_be">
                                                   <option value="higher">Higher</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>than</label>
                                                <select class="form-control" id="ap_than" name="ap_than">
                                                   <option value="ep">EP</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <label>By</label>
                                                <input class="form-control" defaultValue={this.state.ap_amount} name="ap_by_amount" id="ap_by_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                          <div class="form-group">
                                             <div class="rate_plan_pd">
                                                <select class="form-control" id="ap_rate_type" name="ap_rate_type">
                                                   <option value="flat" selected={`${this.state.ap_type=='flat'?'selected':''}`}>Flat Rate</option>
                                                   <option value="percentage" selected={`${this.state.ap_type=='percentage'?'selected':''}`}>Percentage (%)</option>
                                                </select>
                                             </div>
                                          </div>
                                       </td>
                                       <td class="td100">
                                         <div class="form-group">
                                             <div class="rate_plan_pd">
                                               <input class="form-control" defaultValue={this.state.ap_extra} name="ap_extra_amount" id="ap_extra_amount" placeholder="amount" type="text"/>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </fieldset>

                  <fieldset>

                    <legend class="m-legend">Room Amenities</legend>

                    <div class="form-group">
					{this.state.amenities_list.map((list) => (

             <div className="bg-light border-bottom mb-20">
			 <FormGroup>
				<Input type="text" value={`${list.amenities_type}`} disabled />
			 </FormGroup>
				<Col sm="12" md="12" >
                <FormGroup>
				<Row>
				<Col sm="12" md="12" >

				{(list.amenities).map((inv) =>
					<div className="form-check form-check-inline col-md-4 mr-0">
					  <CustomInput type="checkbox" id={`amenities_${inv.id}`} name="amenities[]" label={`${inv.name}`} defaultValue={`${inv.id}`} checked={`${inv.is_active>0?'true':''}`} onChange={this.handleChange} />
					</div>
					)}

				</Col>
				</Row>
                </FormGroup>
				</Col>
			</div>

			))}
                    </div>


                  </fieldset>
                  <Col sm="12" md="12" lg="12">
                  <div class="form-group">
                     <Row>
                        <Col sm="12" md="23" >
                        <Button color="primary" className="pull-right" onClick={this.UpdateRoom}>Save</Button>
                        </Col>
                     </Row>
                  </div>
                  </Col>
               </Accordion.Panel>
            </Accordion>
            </Col>
         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default EditRooms;
