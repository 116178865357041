import React from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {getData} from '../../services/index';
import { message } from 'antd';

import {Radio} from "antd";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



//var date = new Date();



class ViewUser extends React.Component {
constructor(props) {
super(props);
this.state = {
			user_id: '',
			persons : [],
			is_active : '',
		};
this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
this.onChangeRadio = this.onChangeRadio.bind(this);
}


  componentDidMount() {
	if (localStorage.getItem('extranetData')){
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));

		var user_id = this.props.match.params.id;
		var app = { user_id : user_id}
		getData('extranet/user_details',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){

				document.getElementById("user_name").value = result['data'][0]['user_name'];
				document.getElementById("user_email").value = result['data'][0]['user_email'];
				document.getElementById("user_phone").value = result['data'][0]['user_phone'];
				if(result['data'][0]['is_active'] == 'Active'){
					document.getElementById("Active").checked = true;
					}
				if(result['data'][0]['is_active'] == 'Inactive'){
					document.getElementById("Inactive").checked = true;
					}
				}else{
					//
				}
			}
		});
	}
	}


  handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var user_id = this.props.match.params.id;
		 var app = {
				user_id : user_id,
				is_active : this.state.is_active,
			}

	  getData('extranet/user_details_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				message.config({ top: 150});
				message.success('User Update Succesfull!',2);
				}
				else{
				message.error('User Not Update!',2);
				}

			}
		});
  }

  onChange(e){
		this.setState({[e.target.name]:e.target.value});

	}

	onChangeRadio(event) {
	  this.setState({
		is_active: event.target.value,

	  });
	}

render() {

if (this.state.redirectToReferrer){
			window.location.reload(false);

		}


return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Users</CardTitle>
            <CardBody>

             <form className="login100-form validate-form">
			 <Col sm="12" md="12" lg="12">
			 <div id="alert" className="m-b-10"></div>
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">User Name</Label>
							<Input type="text" name="user_name" id="user_name" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="12" lg="12">
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">Email</Label>
							<Input type="text" name="user_email" id="user_email" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="12" lg="12">
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">Phone</Label>
							<Input type="text" name="user_phone" id="user_phone" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="12" lg="12">
              <FormGroup>
					<Row>
						<Col sm="12" md="4" >
						 <Label for="exampleCustomSelect">Status</Label>
							<br/>
							<label className="m-r-10" ><input type="radio" className="p-radio" name="is_active" id="Active"  value="1"  onChange={this.onChangeRadio}/>Active</label>
							<label className="m-r-10" ><input type="radio" className="p-radio" name="is_active" id="Inactive"  value="0"  onChange={this.onChangeRadio}/>Inactive</label>
						</Col>
					</Row>
               </FormGroup>
			 </Col>



			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>

				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit} >Update Now</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ViewUser;
