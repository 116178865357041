import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import {
Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Table,UncontrolledDropdown,DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { message } from 'antd';
import { Tag } from 'antd';
import { Link, NavLink} from 'react-router-dom';

import {getData} from '../../services/index';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class ManageHotel extends React.Component {
constructor(props) {
super(props);
this.state = {

ReviewData : [] ,
};
}

componentDidMount() {
	this.checkData();


  }

	checkData(){
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
			}));
			var user_id = storedArray['data'][0]['user_id'];
			var app = {user_id : user_id}
			getData('extranet/review_list',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['status'] == 200){
						this.setState({
							ReviewData: result['data']
						})
					}
				}
			});
		}
	}	

  submit = (event) => {
    confirmAlert({
      title: 'Are you sure, you want to delete?',
      message: '',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

		var review_id = event;
		var app = {
				review_id : review_id,
			}

	  getData('extranet/review_delete',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				   message.config({ top: 150});
				   message.success('Review Delete Succecfull!',2);
					this.checkData();
				}
				else{
				message.error('Review Not Delete!',2);
				}

			}
		});


	}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


render() {

var BookingDetails;
return (
<div>

   <Row>
      <Col md="12">
      <Card>
		  <CardTitle className="bg-light border-bottom p-3 mb-0">Reviews & Ratting</CardTitle>
         <CardBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>User Name</th>
				  <th>Tag</th>
				  <th>Title</th>
                  <th>Over All Rating</th>
				  <th>Hotel Reply</th>

				  <th>Action</th>
                </tr>
              </thead>
              <tbody>
			  {this.state.ReviewData.map((list) => (
				(() => {
						if (list.is_active > 0) {
						return (
			   <tr>
                  <td>{list.user_name}</td>
				   <td>{list.review_type}</td>
                  <td>{list.title}</td>
                  <td>{list.rating}</td>
                  <td>{(() => {
						if (list.is_rply > 0) {
						return (
								<Tag color="#87d068">Replied</Tag>
								)
						}else {
								return (
								<Tag color="#f50">Not Replied</Tag>
										)
							 }
				 })()}
				 </td>
				  <td>

				  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Action
                    </DropdownToggle>
                    <DropdownMenu>
					  <Link to={`/manage_review/view_review/${list.review_id}`}><DropdownItem><i className="fas fa-eye"></i> View</DropdownItem></Link>

                    </DropdownMenu>
                  </UncontrolledDropdown>
				  </td>
                </tr>
				)
						}
				 })()
			  ))}
              </tbody>
            </Table>

         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default ManageHotel;
