import React from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {getData} from '../../services/index';
import { message } from 'antd';

import {Radio} from "antd";
import { Rate } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



//var date = new Date();



class ViewUser extends React.Component {
constructor(props) {
super(props);
this.state = {
			review_id: '',
			ReviewData : [],
			is_active : '',
			rating : 0,
			location_rating : 0,
			food_rating : 0,
			cleaning_rating : 0,
			facility_rating : 0,
			money_rating : 0,
			reply : '',

		};
this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
this.onChangeRadio = this.onChangeRadio.bind(this);
}


	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var review_id = this.props.match.params.id;
			var app = { review_id : review_id}
			getData('extranet/review_details',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['status'] == 200){
						this.setState({
							ReviewData: result['data']
						})
						document.getElementById("user_name").value = result['data'][0]['user_name'];
						document.getElementById("tag").value = result['data'][0]['review_type'];
						document.getElementById("tag").value = result['data'][0]['review_type'];
						document.getElementById("title").value = result['data'][0]['title'];
						document.getElementById("details").value = result['data'][0]['details'];
						document.getElementById("rating").value = result['data'][0]['rating'];
						document.getElementById("location_rating").value = result['data'][0]['location_rating'];
						document.getElementById("food_rating").value = result['data'][0]['food_rating'];
						document.getElementById("cleaning_rating").value = result['data'][0]['cleaning_rating'];
						document.getElementById("facility_rating").value = result['data'][0]['facility_rating'];
						document.getElementById("money_rating").value = result['data'][0]['money_rating'];
					}
				}
			});
		}
	}


  handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var review_id = this.props.match.params.id;
		 var app = {
				review_id : review_id,
				reply : this.state.reply,
			}

	  getData('extranet/review_reply_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				message.config({ top: 150});
				message.success('Review Update Succesfull!',2);
				this.setState({redirectToReferrer: true});
				}
				else{
				message.error('Review Not Update!',2);
				}

			}
		});
  }

  onChange(e){
		this.setState({[e.target.name]:e.target.value});

	}

	onChangeRadio(event) {
	  this.setState({
		is_active: event.target.value,

	  });
	}

render() {

if (this.state.redirectToReferrer){
			this.props.history.push("/manage_review/index");

		}

var a = parseInt(this.state.rating);

return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Review</CardTitle>
            <CardBody>

             <form className="login100-form validate-form">
			 <Row>

			 </Row>
			 <Row>
			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Overall Rating  </Label>
							<Input type="text" name="rating" id="rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Location Rating  </Label>
							<Input type="text" name="location_rating" id="location_rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Food Rating  </Label>
							<Input type="text" name="food_rating" id="food_rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Cleaning Rating  </Label>
						 <Input type="text" name="cleaning_rating" id="cleaning_rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Facility Rating  </Label>
							 <Input type="text" name="facility_rating" id="facility_rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			  <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Money Review  </Label>
							 <Input type="text" name="money_rating" id="money_rating" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="4" lg="4">
			 <div id="alert" className="m-b-10"></div>
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Title</Label>
							<Input type="text" name="title" id="title" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>

			 <Col sm="12" md="4" lg="4">
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Tag</Label>
							<Input type="text" name="tag" id="tag" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			  <Col sm="12" md="4" lg="4">
			 <div id="alert" className="m-b-10"></div>
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">User Name</Label>
							<Input type="text" name="user_name" id="user_name" disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 <Col sm="12" md="6" lg="6">
			 <div id="alert" className="m-b-10"></div>
              <FormGroup>
					<Row>
						<Col sm="12" md="12" >
						 <Label for="exampleCustomSelect">Details</Label>
							<Input type="textarea" name="details" id="details" rows="10"  disabled/>
						</Col>
					</Row>
               </FormGroup>
			 </Col>
			 </Row>
			</form>

			<CardTitle className="bg-light border-bottom p-3 mb-0">Hotel Reply</CardTitle>

			  {this.state.ReviewData.map((list) => (
				(() => {
						if (list.is_rply == 0) {
						return (
						<div>
							 <Col sm="12" md="6" lg="6">
							 <div id="alert" className="m-b-10"></div>
							  <FormGroup>
									<Row>
										<Col sm="12" md="12" className="m-t-20">
										 <Label for="exampleCustomSelect">Your Reply to the Review</Label>
											<Input type="textarea" name="reply" id="reply"  rows="10" onChange={this.onChange}/>
										</Col>
									</Row>
							   </FormGroup>
							 </Col>
							 <Col sm="12" md="12" lg="12">
								<FormGroup>
								<Row>

								<Col sm="12" md="4" >
								  <Button color="primary" onClick={this.handleSubmit} >Submit Now</Button>
								</Col>
								</Row>
								</FormGroup>
							</Col>
							</div>
							)
						}else{
						return (

							 <Col sm="12" md="6" lg="6">
							  <FormGroup>
									<Row>
										<Col sm="12" md="12" className="m-t-20">
										 <Label for="exampleCustomSelect">Your Reply to the Review</Label>
											<Input type="textarea" name="reply" id="reply"  rows="10" value={list.reply} disabled/>
										</Col>
									</Row>
							   </FormGroup>
							 </Col>

							)
						}
				 })()
			  ))}

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ViewUser;
