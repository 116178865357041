import AuthRoutes from './authroutes';

//dashboards
import Dashboard from '../views/dashboards/index';


//Booking
import Booking from '../views/booking/index';
import BookingDetails from '../views/booking/booking_details';
import OfflineBooking from '../views/booking/offline_booking';
import OfflineHotel from '../views/booking/offline_hotel';
import OfflineHotelBooking from '../views/booking/offline_hotel_booking';

//Offer
import ManageOffer from '../views/offers/manage_offer';
import OffersView from '../views/offers/offers_view';
import LastMinuteRateView from '../views/offers/last_minute_rate_view';

//Booster
import ManageBooster from '../views/booster/manage_booster';
import BoosterView from '../views/booster/booster_view';

//Password
import ChangePassword from '../views/password/change_password';

//Last Minute Rate
import LastMinuteRate from '../views/last_minute_rate/index';

//ManageUser
import ManageUser from '../views/manage_user/index';
import ViewUser from '../views/manage_user/view_user';

//ManageHotel
import ManageHotel from '../views/manage_hotel/index';

//ManageReview
import ManageReview from '../views/manage_review/index';
import ViewReview from '../views/manage_review/view_review';


//Inventory
import ManageRoom from '../views/inventory/manage_room';
import ManageRate from '../views/inventory/manage_rate';
import RoomsView from '../views/inventory/rooms_view';
import RateView from '../views/inventory/rate_view';


import BasicInfo from '../views/property/basic_info';
import AddNewHotel from '../views/property/add_new_hotel';
import ContactInfo from '../views/property/contact_info';
import Amenities from '../views/property/amenities';
import Policy from '../views/property/policy';
import BankDetails from '../views/property/bank_details';
import Rooms from '../views/property/rooms';
import EditRooms from '../views/property/edit_rooms';
import Map from '../views/property/map';
import Photos from '../views/property/photos';
import Documents from '../views/property/documents';
import PayAtHotel from '../views/property/pay_at_hotel';

import Vectormap from '../views/maps/vectormap';

var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		path: "/dashboards/index",
		name: "Dashboard",
		icon: "mdi mdi-view-dashboard",
		component: Dashboard
	},
	{
		path: "/booking/offline_booking",
		name: "Offline Booking",
		icon: "mdi mdi-view-dashboard",
		component: OfflineBooking
	},
	{
		collapse: true,
		name: "Bookings",
		state: "uicomponents",
		icon: "mdi mdi-widgets",
		extra: "",
		child: [
			{
				path: "/booking/index",
				name: 'Booking',
				icon: 'mdi mdi-book-minus',
				component: Booking
			},
			{
			  path: "/booking/booking_details/:id",
			  redirect: true,
			  component: BookingDetails
			 },
			 {
			  path: "/last_minute_rate/index",
			  redirect: true ,
			  component: LastMinuteRate
			 },
			 {
				 redirect: true ,
				path: "/offline_hotel/:id",
				component: OfflineHotel
			},
			{
				redirect: true ,
				path: "/offline-hotel-booking/",
				component: OfflineHotelBooking
			},
		]
	},
	{
		navlabel: true,
		name: "Inventory",
		icon: "mdi mdi-dots-horizontal",

	},
	{
		collapse: true,
		name: 'Inventory',
		state: 'Inventory',
		icon: 'mdi mdi-apps',
		extra: "",
		child: [
			{
				path: "/inventory/rooms_view",
				name: 'Rooms View',
				icon: 'mdi mdi-comment-processing-outline',
				component: RoomsView
			},
			{
				path: "/inventory/rate_view",
				name: 'Rates View',
				icon: 'mdi mdi-inbox-arrow-down',
				component: RateView
			},
			{
				path: "/inventory/manage_room",
				name: 'Manage Rooms',
				icon: 'mdi mdi-contacts',
				component: ManageRoom
			},
			{
				path: "/inventory/manage_rate",
				name: 'Manage Rates',
				icon: 'mdi mdi-calendar',
				component: ManageRate
			}
		]
	},
	{
		collapse: true,
		name: 'Offers',
		state: 'offers',
		icon: 'mdi mdi-book-open-page-variant',
		extra: "",
		child: [

			{
				path: "/offers/offers_view",
				name: 'Offer VIew',
				icon: 'mdi mdi-crop-free',
				component: OffersView
			},
			{
				path: "/offers/manage_offer",
				name: 'Manage Offer',
				icon: 'mdi mdi-account-network',
				component: ManageOffer
			},
		]
	},
	{ path: '/redirect/:id', pathTo: '/authentication/redirect/:id', name: 'Redirect', redirect: true },
	{ path: '/admin', pathTo: '/authentication/admin', name: 'Login', redirect: true },
	{ path: '/extranet', pathTo: '/authentication/extranet', name: 'Login', redirect: true },

	{ path: '/', pathTo: '/dashboards/index', name: 'Dashboard', redirect: true },

];
export default ThemeRoutes;
