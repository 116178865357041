import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import {
Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Table,UncontrolledDropdown,DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, NavLink} from 'react-router-dom';
import { message } from 'antd';
import {getData} from '../../services/index';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class ManageUser extends React.Component {
constructor(props) {
super(props);
this.state = {
ListData : []
};
}

componentDidMount() {
	if (localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
			}));


		this.state.hotel_id = storedArray['data'][0]['user_id'];
		getData('extranet/ ',this.state).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						ListData: result['data']
					})
				}
			}
		});
	}

  }

  handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var user_id = this.props.match.params.id;
		 var app = {
				user_id : user_id,
			}

	  getData('extranet/user_details_delete',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				message.config({ top: 150});
				message.success('User Delete Succesfull!',2);
				}
				else{
				message.error('User Not Delete!',2);
				}

			}
		});
  }

  submit = (event) => {
    confirmAlert({
      title: 'Are you sure, you want to delete?',
      message: '',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

		var user_id = event;
		var app = {
				user_id : user_id,
			}

	  getData('extranet/user_details_delete',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){

			   if(result['status']==200){
				window.location.reload(true);
				}
				else{
				message.error('User Not Delete!',2);
				}

			}
		});


	}
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


render() {

var BookingDetails;
return (
<div>

   <Row>
      <Col md="12">
      <Card>
		  <CardTitle className="bg-light border-bottom p-3 mb-0">Recent Users</CardTitle>
         <CardBody>
            <Table responsive>
              <thead>
                <tr>

                  <th>User ID</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>User Phone</th>
                  <th>Status</th>
				  <th>Created Date</th>
				  <th>Action</th>
                </tr>
              </thead>
              <tbody>
			  {this.state.ListData.map((list) => (
                <tr>
                  <td>{list.user_id}</td>
                  <td>{list.user_name}</td>
                  <td>{list.user_email}</td>
                  <td>{list.user_phone}</td>
                  <td>{list.is_active}</td>
                  <td>{list.create_date}</td>
				  <td>

				  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Action
                    </DropdownToggle>
                    <DropdownMenu>
                     <Link to={`/manage_user/view_user/${list.user_id}`} component={BookingDetails}> <DropdownItem> <i className="fas fa-eye"></i> View</DropdownItem></Link>
                      <DropdownItem divider />
                      <DropdownItem onClick={this.submit.bind(this,list.user_id)}><i className="fas fa-trash-alt"></i> Delete</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
				  </td>
                </tr>
			  ))}
              </tbody>
            </Table>

         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default ManageUser;
