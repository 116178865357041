import React from 'react';
import {getData} from '../../services/index';
import { message } from 'antd';
import 'react-datetime/css/react-datetime.css';
import ReactHtmlParser from 'react-html-parser';

import {Link} from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';





class BookingDetails extends React.Component {
constructor(props) {
super(props);
this.state = {
ListData : [],
adult_information: [],
child_information: []

};
}

	componentDidMount() {

		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/master';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.hotel_id = storedArray['data'][0]['user_id'];
			var booking_id = this.props.match.params.id;
			var app = {
				user_id : storedArray['data'][0]['user_id'],
				booking_id : booking_id
			}
			getData('extranet/invoice_details',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
						ListData: result['data'],
						adult_information: result['data'][0]['adult_information'],
						child_information: result['data'][0]['child_information']
						});
					}
				}
			});
		}
	}

   printDiv() {
		var printContents = document.getElementById('printableArea').innerHTML;
		window.print();
	}

render() {

return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
            <div>
			{this.state.ListData.map((list) => (
			<div id="printableArea">
				{ReactHtmlParser(list.booking_details)}
		    </div>
			))}
			<Row className="traveller" >
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Form>


								<div>

								</div>


								<div>


								</div>

                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

			<div className="login100-form-btn _bsh _bg-primary print" onClick={this.printDiv}>
				Print
			</div>
            </div>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default BookingDetails;
