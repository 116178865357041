import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Col,
	Row,
	Input
} from 'reactstrap';
import * as S from '../';
import { Line } from 'react-chartjs-2';

//Line chart
let lineData = {
	labels: [1, 2, 3, 4, 5, 6, 7, 8],
	datasets: [{
		label: 'Outcome',
		borderWidth: 1,
		backgroundColor: 'rgba(79,195,247,.1)',
		borderColor: 'rgb(79,195,247)',
		pointBorderColor: 'rgb(79,195,247)',
		pointBackgroundColor: 'rgb(79,195,247)',
		data: [0, 8, 11, 22, 8, 10, 5, 21]
	}]
};

class SalesSummary extends React.Component {
	render() {
		return (
			<Card>
				<CardBody>
					<div className="d-md-flex align-items-center">
						<div>
							<CardTitle>Booking Summary</CardTitle>
							<CardSubtitle>Overview of Booking</CardSubtitle>
						</div>
						
					</div>
					<Row>
						<Col lg="4">
							<h1 className="mb-0 mt-4"><i class="fas fa-rupee-sign"></i> 6,890.68</h1>
							<h6 className="font-light text-muted">Current Month Sales</h6>
							<h3 className="mt-4 mb-0"><i class="fas fa-rupee-sign"></i> 1,540</h3>
							<h6 className="font-light text-muted">Last Month Sales</h6>
							
						</Col>
						<Col lg="8">
							<div className="campaign ct-charts">
								<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
									<Line data={lineData} options={{ maintainAspectRatio: false, legend: { display: false, labels: { fontFamily: "Nunito Sans" } }, scales: { yAxes: [{ stacked: true, gridLines: { display: false }, ticks: { fontFamily: "Nunito Sans" } }], xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Nunito Sans" } }] } }} />
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
				
			</Card>
		);
	}
}

export default SalesSummary;
