import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import {
Button, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Table,TabContent,
  TabPane,Nav, NavItem,CardText, NavLink} from 'reactstrap';
  import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link,} from 'react-router-dom';
import classnames from 'classnames';
import {getData} from '../../services/index';
import { DatePicker,Select,Popconfirm,Popover,Modal  } from "antd";
import moment from 'moment';
import { message } from 'antd';
const { MonthPicker, RangePicker } = DatePicker;


function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

class Booking extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.onChangeDate = this.onChangeDate.bind(this);
		this.bookingSubmit = this.bookingSubmit.bind(this);
		this.bookingcheckin = this.bookingcheckin.bind(this);
		this.notshow = this.notshow.bind(this);
		this.showModal = this.showModal.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.state = {
			booking_list : [] ,
			upcoming_list : [] ,
			past_list : [] ,
			reject_list : [] ,
			cancel_list : [] ,
			pah_list : [] ,
			detail_data:[],
			activeTab : '1',
			booking_count: '0',
			upcoming_count: '0',
			past_count: '0',
			reject_count: '0',
			cancel_count: '0',
			pah_count: '0',
			visible: false
		};
	}

	showModal = (id,e) => {
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {hotel_id : hotel_id,booking_id:id}
		getData('extranet/booking_details',app).then((result) => {
			let responseJson = result;
			if(responseJson.data){
				if(result['count']>0){
					this.setState({
						detail_data: result['data']
					})
				}else{
					this.setState({
						detail_data: []
					});
				}
			}
		});
		this.setState({
			visible: true,
		});
	};

	handleCancel = (id,e) => {
		this.setState({
		  visible: false,
		});
	};

	onChangeDate(event) {
		this.setState({
			start_end_date: event
		});
	}

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.checkData();
		}
	}

	checkData(){
  	    if(localStorage.getItem('extranetData')){
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.setState(() => ({
				full_name: storedArray['data'][0]['full_name'],
				email_id: storedArray['data'][0]['email'],
			}));
			this.state.hotel_id = storedArray['data'][0]['hotel_id'];
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {hotel_id : hotel_id, type : 'pending_booking'}
			getData('extranet/booking_list',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							booking_list: result['data'],
							booking_count: result['count']
						});
					}else{
						this.setState({
							booking_list: [],
							booking_count: '0',
						});
					}
				}
			});
		var app = {hotel_id : hotel_id, type : 'upcoming_booking'}
		getData('extranet/booking_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						upcoming_list: result['data'],
						upcoming_count: result['count']
					});
				}else{
					this.setState({
						upcoming_list: [],
						upcoming_count: '0',
					});
				}
			}
		});
		var app = {hotel_id : hotel_id, type : 'past_booking'}
		getData('extranet/booking_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						past_list: result['data'],
						past_count: result['count']
					});
				}else{
					this.setState({
						past_list: [],
						past_count: '0',
					});
				}
			}
		});
		var app = {hotel_id : hotel_id, type : 'reject_booking'}
		getData('extranet/booking_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						reject_list: result['data'],
						reject_count: result['count']
					});
				}else{
					this.setState({
						reject_list: [],
						reject_count: '0',
					});
				}
			}
		});
		var app = {hotel_id : hotel_id, type : 'cancel_booking'}
		getData('extranet/booking_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						cancel_list: result['data'],
						cancel_count: result['count']
					});
				}else{
					this.setState({
						cancel_list: [],
						cancel_count: '0',
					});
				}
			}
		});
		var app = {hotel_id : hotel_id, type : 'pah_booking'}
		getData('extranet/booking_list',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['count']>0){
					this.setState({
						pah_list: result['data'],
						pah_count: result['count']
					});
				}else{
					this.setState({
						pah_list: [],
						pah_count: '0',
					});
				}
			}
		});
	}
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        'activeTab': tab
      });
    }
  }

  bookingSubmit(){
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = document.getElementById("booking_id").value;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_confirm',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
					message.error('Room Not Update!',2);
				}
				this.checkData();
			}
		});


  }



  bookingcheckin = (event) => {
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = event;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_checkin',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});
  }

  notshow = (event) => {
	var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	var booking_id = event;
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		booking_id : booking_id,
	  }
	  getData('extranet/booking_not_show',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
					this.checkData();
				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});
  }

	confirm(event) {
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var booking_id = event;
		var app = {
			hotel_id : storedArray['data'][0]['hotel_id'],
			booking_id : booking_id,
		}
		getData('extranet/booking_reject',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
				if(result['status']==200){
					message.success('Booking rejected!',2);
					this.checkData();
				}
			}
		});
	}
	cancel(event) {
		console.log(event);
	}


render() {

var BookingDetails;
return (
<div>

   <Row >

	<Col>
      <Card>
		  <CardTitle className="bg-light border-bottom p-3 mb-0">Apply Booking Filters/Download</CardTitle>
         <CardBody className="booking_filter">
			<Row>
				<div className="col-md-10 col-xs-12">
				<Row>
                  <div className="col-md-4 col-xs-12 m-t-5">
				  <Col sm="12" md="12" >
					  <Label for="exampleCustomSelect">Select Date *</Label>
					</Col>
					<Col sm="12" md="12" className="m-t-5">
						  <FormGroup>
							<RangePicker
							  disabledDate={disabledDate}
							  disabledTime={disabledRangeTime}
							  format="DD MMM, YYYY"
							  name ="start_end_date"
							  onChange={this.onChangeDate}
							/>
						  </FormGroup>
					</Col>
				  </div>
                  <div className="col-md-3 col-xs-6 m-t-5">
					<Col sm="12" md="12" >
					  <Label for="exampleCustomSelect">Status Type *</Label>
					</Col>
					<Col sm="12" md="12" className="m-t-5">
						<select class="form-control" id="room_id" name="room_id">
                                 <option value="" selected="selected">Select Status</option>
                                 <option value="" >Confirm</option>
                                 <option value="" >Cancelled</option>
								 ))}
                        </select>
					</Col>
				  </div>
                  <div className="col-md-3 col-xs-6 m-t-5" >
					<Col sm="12" md="12" >
					  <Label for="exampleCustomSelect">Booking ID</Label>
					</Col>
					<Col sm="12" md="12" className="m-t-5">
						<Input type="text" placeholder="Booking ID" />
					</Col>
				  </div>
				  <div className="col-md-2 m-t-5">
					<Col sm="12" md="12" >
					  <Label for="exampleCustomSelect"></Label>
					</Col>
					<Col sm="12" md="12" className="m-t-5 no-pad">
						<Button className="btn" color="primary">
							Search
							  </Button>
					</Col>
				  </div>
				  </Row>
				  </div>
		  </Row>
         </CardBody>
	  </Card>
	</Col>
	</Row >
	<Row >

      <Col>
      <Card>
		  <CardTitle className="bg-light border-bottom p-3 mb-0">Recent Booking</CardTitle>
         <CardBody className="no-pad mob_scroll">
			<Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '1'
                  })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                New <span> ({this.state.booking_count}) </span>

                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '2'
                  })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Upcoming <span> ({this.state.upcoming_count}) </span>

                </NavLink>
              </NavItem>
			  <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '3'
                  })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Past <span>({this.state.past_count})</span>
                </NavLink>
              </NavItem>
			  <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '4'
                  })}
                  onClick={() => {
                    this.toggle('4');
                  }}
                >
                 Cancelled <span>({this.state.cancel_count})</span>
                </NavLink>
              </NavItem>
			  <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '5'
                  })}
                  onClick={() => {
                    this.toggle('5');
                  }}
                >
                 Pay@ Hotel <span>({this.state.pah_count})</span>
                </NavLink>
              </NavItem>
			  <NavItem>
                <NavLink
                  className={classnames({
                    'active': this.state.activeTab === '6'
                  })}
                  onClick={() => {
                    this.toggle('6');
                  }}
                >
                   Rejected <span>({this.state.reject_count})</span>
                </NavLink>
              </NavItem>
            </Nav>
			<Modal
				title="Booking Details"
				visible={this.state.visible}
				onCancel={this.handleCancel}
				okButtonProps={{ disabled: true }}
			>
			{this.state.detail_data.map((list) => (
				<table class="table table-bordered">
					<tbody>
					<tr>
						<td className="td_wd">Booking ID</td>
						<td>BMS00{list.booking_id}</td>
					</tr>
					<tr>
						<td> Name of Hotel</td>
						<td>{list.hotel_name}</td>
					</tr>
					<tr>
						<td><label>Name of Guest </label></td>
						<td>{list.name}</td>
					</tr>
					<tr>
						<td> <label>Booking Date </label></td>
						<td>{list.booking_date}</td>
					</tr>
					<tr>
						<td> <label>Checkin Date </label></td>
						<td>{list.check_in}</td>
					</tr>
					<tr>
						<td> <label>Checkout Date </label></td>
						<td>{list.check_out}</td>
					</tr>
					<tr>
						<td> <label>Number of Nights </label></td>
						<td>{list.total_night}</td>
					</tr>
					<tr>
						<td> <label>Guest </label></td>
						<td>Adult {list.adult} Child {list.children} </td>
					</tr>
					<tr>
						<td> <label>Number of Rooms </label></td>
						<td>{list.room}</td>
					</tr>
					<tr>
						<td colspan="2">
						<table class="table table-bordered">
    <thead>
      <tr>
        <th>Room Type</th>
        <th>Total Rooms</th>
        <th>Rate Plan</th>
      </tr>
    </thead>
    <tbody>
	{list.room_list.map((plan) => (
      <tr>
        <td>{plan.display_name}</td>
        <td>{plan.room_qty}</td>
        <td>{plan.plan_type}</td>
      </tr>
	))}
    </tbody>
  </table>
						</td>
					</tr>
					</tbody>
				</table>
			))}
            </Modal>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row  className="content_room">
                  <Col sm="12">
                  <div className="hidden-lg hidden-md hidden-sm">
                  {this.state.booking_list.map((list) => (
                  <table class="table table-bordered mob_booking_box">
                    <tbody>
                    <tr>
                      <td colspan="2" className="text-left">
                         <b>{list.name}</b><br/>
                         <span className="view_book" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</span>
                      </td>
                      <td className="text-right status">{list.booking_status}</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="text-left">
                        <b>Checkin Date</b><br/>
                        {list.check_in}
                      </td>
                      <td className="text-right">
                        <b>Checkout Date</b><br/>
                        {list.check_out}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Amount</b><br/>
                        INR {list.amount}
                      </td>
                      <td>
                        <b>Paid</b><br/>
                        INR {list.paid_amount}
                      </td>
                      <td className="text-right">
                        <b>Balance</b><br/>
                        INR {list.amount-list.paid_amount}
                      </td>
                    </tr>
                    <tr>
                      {(() => {
                     if (list.payment_type =='PREPAID') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PREPAID</td>
                       )
                     }
                     if (list.payment_type =='PAH-HALF') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-HALF</td>
                       )
                     }
                     if (list.payment_type =='PAH-FULL') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-FULL</td>
                       )
                     }
                      })()}
                      <td colspan="2" className="text-right">
                      <Popconfirm
                          title="Are you sure, you want to reject this booking?"
                          onConfirm={this.confirm.bind(this,list.booking_id)}
                          onCancel={this.cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a href="#" className="btn book-btn btn-danger">Reject</a>
                        </Popconfirm>
                      <Input type="hidden" id="booking_id" value={list.booking_id}/>
                      <Button className="btn book-btn" color="success" onClick={this.bookingSubmit}>
                      Confirm
                      </Button>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    ))}
                  </div>
                    <Card body className="no-pad hidden-xs">
                     <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.booking_list.map((list) => (
							<tr>
							  <td>

		<Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>
          BMS00{list.booking_id}
        </Button>



							  </td>
							  <td>{list.name}</td>
							  <td>
								{list.room}
							  </td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
							   {(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>
							   <td>{list.booking_status}</td>
							  <td>
									<div>
										<Input type="hidden" id="booking_id" value={list.booking_id}/>
									  <Button className="btn book-btn" color="success" onClick={this.bookingSubmit}>
										Confirm
									  </Button>

<Popconfirm
    title="Are you sure, you want to reject this booking?"
    onConfirm={this.confirm.bind(this,list.booking_id)}
    onCancel={this.cancel}
    okText="Yes"
    cancelText="No"
  >
    <a href="#" className="btn book-btn btn-danger">Reject</a>
  </Popconfirm>
									</div>
							  </td>


							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">

                  <div className="hidden-lg hidden-md hidden-sm">
                  {this.state.upcoming_list.map((list) => (
                  <table class="table table-bordered mob_booking_box">
                    <tbody>
                    <tr>
                      <td colspan="2" className="text-left">
                         <b>{list.name}</b><br/>
                         <span className="view_book" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</span>
                      </td>
                      <td className="text-right status"><Input type="hidden" id="booking_id" value={list.booking_id}/>
      							   {(() => {
      								if (list.is_checkin =='0' && list.checkin_show == '0') {
      								return (
      								<div>
      									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
      										Check In
      									  </Button>
      								</div>
      								)}
      								if ((list.payment_type == 'PAH-HALF' || list.payment_type == 'PAH-FULL') && list.is_checkin =='0' && list.checkin_show == '0') {
      								return (
      								<div>
      									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
      										No Show
      									  </Button>
      								</div>
      								)}
      								if (list.is_checkin =='0' && list.checkin_show == '1') {
      								return (
      								<div>

      										<span>Confirmed</span>

      								</div>
      								)}

      								if (list.is_checkin =='1') {
      								return (
      								<div>
      									  <Button className="btn book-btn" color="success" outline>
      										Checked-In
      									  </Button>
      								</div>
      								)}
      							   })()}</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="text-left">
                        <b>Checkin Date</b><br/>
                        {list.check_in}
                      </td>
                      <td className="text-right">
                        <b>Checkout Date</b><br/>
                        {list.check_out}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Amount</b><br/>
                        INR {list.amount}
                      </td>
                      <td>
                        <b>Paid</b><br/>
                        INR {list.paid_amount}
                      </td>
                      <td className="text-right">
                        <b>Balance</b><br/>
                        INR {list.amount-list.paid_amount}
                      </td>
                    </tr>
                    <tr>
                      {(() => {
                     if (list.payment_type =='PREPAID') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PREPAID</td>
                       )
                     }
                     if (list.payment_type =='PAH-HALF') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-HALF</td>
                       )
                     }
                     if (list.payment_type =='PAH-FULL') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-FULL</td>
                       )
                     }
                      })()}
                      <td colspan="2" className="text-right">
                      <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
     								<i className="fas fa-eye"></i> View Vouchers
     							  </Button>
     							  </Link>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    ))}
                  </div>
                    <Card body className="no-pad hidden-xs">
                      <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.upcoming_list.map((list) => (
							<tr>

							  <td><Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</Button></td>
							  <td>{list.name}</td>
							  <td>{list.room}</td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
								{(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>
							  <td>
							  <Input type="hidden" id="booking_id" value={list.booking_id}/>
							   {(() => {
								if (list.is_checkin =='0' && list.checkin_show == '0') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
										Check In
									  </Button>
								</div>
								)}
								if ((list.payment_type == 'PAH-HALF' || list.payment_type == 'PAH-FULL') && list.is_checkin =='0' && list.checkin_show == '0') {
								return (
								<div>
									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
										No Show
									  </Button>
								</div>
								)}
								if (list.is_checkin =='0' && list.checkin_show == '1') {
								return (
								<div>

										<span>Confirmed</span>

								</div>
								)}

								if (list.is_checkin =='1') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" outline>
										Checked-In
									  </Button>
								</div>
								)}
							   })()}



							  </td>

							  <td>
							   <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
								<i className="fas fa-eye"></i> View Vouchers
							  </Button>
							  </Link></td>
							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
			  <TabPane tabId="3">
                <Row>
                  <Col sm="12">

                  <div className="hidden-lg hidden-md hidden-sm">
                  {this.state.past_list.map((list) => (
                  <table class="table table-bordered mob_booking_box">
                    <tbody>
                    <tr>
                      <td colspan="2" className="text-left">
                         <b>{list.name}</b><br/>
                         <span className="view_book" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</span>
                      </td>
                      <td className="text-right status"> <Input type="hidden" id="booking_id" value={list.booking_id}/>
       							   {(() => {
       								if (list.is_checkin =='0' && list.checkin_show == '0') {
       								return (
       								<div>
       									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
       										Check In
       									  </Button>
       								</div>
       								)}

       								if ((list.payment_type == 'PAH-HALF' || list.payment_type == 'PAH-FULL') && list.is_checkin =='0' && list.checkin_show == '0') {
       								return (
       								<div>
       									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
       										No Show
       									  </Button>
       								</div>
       								)}

       								if (list.is_checkin =='0' && list.checkin_show == '1') {
       								return (
       								<div>

       										<span>Confirmed</span>

       								</div>
       								)}

       								if (list.is_checkin =='1') {
       								return (
       								<div>
       									  <Button className="btn book-btn" color="success" outline>
       										Checked-In
       									  </Button>
       								</div>
       								)}
       							   })()}</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="text-left">
                        <b>Checkin Date</b><br/>
                        {list.check_in}
                      </td>
                      <td className="text-right">
                        <b>Checkout Date</b><br/>
                        {list.check_out}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Amount</b><br/>
                        INR {list.amount}
                      </td>
                      <td>
                        <b>Paid</b><br/>
                        INR {list.paid_amount}
                      </td>
                      <td className="text-right">
                        <b>Balance</b><br/>
                        INR {list.amount-list.paid_amount}
                      </td>
                    </tr>
                    <tr>
                      {(() => {
                     if (list.payment_type =='PREPAID') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PREPAID</td>
                       )
                     }
                     if (list.payment_type =='PAH-HALF') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-HALF</td>
                       )
                     }
                     if (list.payment_type =='PAH-FULL') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-FULL</td>
                       )
                     }
                      })()}
                      <td colspan="2" className="text-right">
                      <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
     								<i className="fas fa-eye"></i> View Vouchers
     							  </Button>
     							  </Link>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    ))}
                  </div>
                    <Card body className="no-pad hidden-xs">
                      <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.past_list.map((list) => (
							<tr>

							  <td><Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</Button></td>
							  <td>{list.name}</td>
							  <td>{list.room}</td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
								{(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>
							  <td>
							  <Input type="hidden" id="booking_id" value={list.booking_id}/>
							   {(() => {
								if (list.is_checkin =='0' && list.checkin_show == '0') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
										Check In
									  </Button>
								</div>
								)}

								if ((list.payment_type == 'PAH-HALF' || list.payment_type == 'PAH-FULL') && list.is_checkin =='0' && list.checkin_show == '0') {
								return (
								<div>
									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
										No Show
									  </Button>
								</div>
								)}

								if (list.is_checkin =='0' && list.checkin_show == '1') {
								return (
								<div>

										<span>Confirmed</span>

								</div>
								)}

								if (list.is_checkin =='1') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" outline>
										Checked-In
									  </Button>
								</div>
								)}
							   })()}


							  </td>

							  <td>
							   <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
								<i className="fas fa-eye"></i> View Vouchers
							  </Button>
							  </Link></td>
							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
			  <TabPane tabId="4">
                <Row>
                  <Col sm="12">

                  <div className="hidden-lg hidden-md hidden-sm">
                  {this.state.cancel_list.map((list) => (
                  <table class="table table-bordered mob_booking_box">
                    <tbody>
                    <tr>
                      <td colspan="2" className="text-left">
                         <b>{list.name}</b><br/>
                         <span className="view_book" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</span>
                      </td>
                      <td className="text-right status">{list.booking_status}</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="text-left">
                        <b>Checkin Date</b><br/>
                        {list.check_in}
                      </td>
                      <td className="text-right">
                        <b>Checkout Date</b><br/>
                        {list.check_out}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Amount</b><br/>
                        INR {list.amount}
                      </td>
                      <td>
                        <b>Paid</b><br/>
                        INR {list.paid_amount}
                      </td>
                      <td className="text-right">
                        <b>Balance</b><br/>
                        INR {list.amount-list.paid_amount}
                      </td>
                    </tr>
                    <tr>
                      {(() => {
                     if (list.payment_type =='PREPAID') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PREPAID</td>
                       )
                     }
                     if (list.payment_type =='PAH-HALF') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-HALF</td>
                       )
                     }
                     if (list.payment_type =='PAH-FULL') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-FULL</td>
                       )
                     }
                      })()}
                      <td colspan="2" className="text-right">
                      <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
                     <i className="fas fa-eye"></i> View Vouchers
                     </Button>
                     </Link>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    ))}
                  </div>
                    <Card body className="no-pad hidden-xs">
                     <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.cancel_list.map((list) => (
							<tr>

							  <td><Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</Button></td>
							  <td>{list.name}</td>
							  <td>{list.room}</td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
								{(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>
							  <td>{list.booking_status}</td>


							  <td>
							   <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
								<i className="fas fa-eye"></i> View Vouchers
							  </Button>
							  </Link></td>
							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
			  <TabPane tabId="5">
                <Row>
                  <Col sm="12">

                  <div className="hidden-lg hidden-md hidden-sm">
                  {this.state.pah_list.map((list) => (
                  <table class="table table-bordered mob_booking_box">
                    <tbody>
                    <tr>
                      <td colspan="2" className="text-left">
                         <b>{list.name}</b><br/>
                         <span className="view_book" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</span>
                      </td>
                      <td className="text-right status">{(() => {
     								if (list.is_checkin =='0' && list.checkin_show == '0' && list.booking_status != 'reject') {
     								return (
     								<div>
     									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
     										Check In
     									  </Button>
     								</div>
     								)}
     								if (list.payment_type == 'PAH' && list.is_checkin =='0' && list.checkin_show == '0' && list.booking_status != 'reject') {
     								return (
     								<div>
     									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
     										No Show
     									  </Button>
     								</div>
     								)}
     								if (list.is_checkin =='0' && list.checkin_show == '1' && list.booking_status != 'reject') {
     								return (
     								<div>

     										<span>Confirmed</span>

     								</div>
     								)}
     								if (list.is_checkin =='1' && list.booking_status != 'reject') {
     								return (
     								<div>
     									  <Button className="btn book-btn" color="success" outline>
     										Checked-In
     									  </Button>
     								</div>
     								)}
     							   })()}

     							   {(() => {
     							 if (list.booking_status== 'cancel') {
     								return (
     							  <div>Cancelled</div>
     							  )}
     							  })()}
     							  {(() => {
     							 if (list.booking_status== 'reject') {
     								return (
     							  <div>Rejected</div>
     							  )}
     							  })()}</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="text-left">
                        <b>Checkin Date</b><br/>
                        {list.check_in}
                      </td>
                      <td className="text-right">
                        <b>Checkout Date</b><br/>
                        {list.check_out}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Amount</b><br/>
                        INR {list.amount}
                      </td>
                      <td>
                        <b>Paid</b><br/>
                        INR {list.paid_amount}
                      </td>
                      <td className="text-right">
                        <b>Balance</b><br/>
                        INR {list.amount-list.paid_amount}
                      </td>
                    </tr>
                    <tr>
                      {(() => {
                     if (list.payment_type =='PREPAID') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PREPAID</td>
                       )
                     }
                     if (list.payment_type =='PAH-HALF') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-HALF</td>
                       )
                     }
                     if (list.payment_type =='PAH-FULL') {
                       return (
                       <td className="text-left"><b>Payment Type</b><br/>PAH-FULL</td>
                       )
                     }
                      })()}
                      <td colspan="2" className="text-right">
                      <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
     								<i className="fas fa-eye"></i> View Vouchers
     							  </Button>
     							  </Link>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    ))}
                  </div>
                    <Card body className="no-pad hidden-xs">
                     <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.pah_list.map((list) => (
							<tr>

							  <td><Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</Button></td>
							  <td>{list.name}</td>
							  <td>{list.room}</td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
								{(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>

							   <td>
							   {(() => {
								if (list.is_checkin =='0' && list.checkin_show == '0' && list.booking_status != 'reject') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" onClick={this.bookingcheckin.bind(this,list.booking_id)}>
										Check In
									  </Button>
								</div>
								)}
								if (list.payment_type == 'PAH' && list.is_checkin =='0' && list.checkin_show == '0' && list.booking_status != 'reject') {
								return (
								<div>
									  <Button className="btn m-t-5 book-btn" color="danger" onClick={this.notshow.bind(this,list.booking_id)}>
										No Show
									  </Button>
								</div>
								)}
								if (list.is_checkin =='0' && list.checkin_show == '1' && list.booking_status != 'reject') {
								return (
								<div>

										<span>Confirmed</span>

								</div>
								)}
								if (list.is_checkin =='1' && list.booking_status != 'reject') {
								return (
								<div>
									  <Button className="btn book-btn" color="success" outline>
										Checked-In
									  </Button>
								</div>
								)}
							   })()}

							   {(() => {
							 if (list.booking_status== 'cancel') {
								return (
							  <div>Cancelled</div>
							  )}
							  })()}
							  {(() => {
							 if (list.booking_status== 'reject') {
								return (
							  <div>Rejected</div>
							  )}
							  })()}
							</td>


							  <td>
							   <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
								<i className="fas fa-eye"></i> View Vouchers
							  </Button>
							  </Link></td>
							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
			  <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                    <Card body className="no-pad hidden-xs">
                      <Table responsive>
						  <thead>
							<tr>

							  <th>Booking Id</th>
							  <th>Guest Name</th>
							  <th>Rooms</th>
							  <th>Amount</th>
							  <th>Paid</th>
							  <th>Balance</th>
							  <th>Payment Type</th>
							  <th>Check In</th>
							  <th>Check Out</th>
							  <th>Booking Date</th>
							  <th>Booking Status</th>
							  <th>Action</th>
							</tr>
						  </thead>
						  <tbody>
						  {this.state.reject_list.map((list) => (
							<tr>

							  <td><Button type="primary" onClick={this.showModal.bind(this,list.booking_id)}>BMS00{list.booking_id}</Button></td>
							  <td>{list.name}</td>
							  <td>{list.room}</td>
							  <td>{list.amount}</td>
							  <td>{list.paid_amount}</td>
							  <td>{list.amount-list.paid_amount}</td>
								{(() => {
								if (list.payment_type =='PREPAID') {
									return (
									<td>PREPAID</td>
									)
								}
								if (list.payment_type =='PAH-HALF') {
									return (
									<td>PAH-HALF</td>
									)
								}
								if (list.payment_type =='PAH-FULL') {
									return (
									<td>PAH-FULL</td>
									)
								}
							   })()}
							   <td>{list.check_in}</td>
							  <td>{list.check_out}</td>
							   <td>{list.booking_date}</td>
							  <td>
								<div>
									  <span>{list.booking_status}</span>
								</div>
							  </td>

							  <td>
							   <Link to={`/booking/booking_details/${list.booking_id}`} component={BookingDetails}><Button className="btn" color="primary">
								<i className="fas fa-eye"></i> View Vouchers
							  </Button>
							  </Link></td>
							</tr>
						  ))}
						  </tbody>
						</Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

            </TabContent>



         </CardBody>
      </Card>
      </Col>
   </Row>
</div>
);
}
}
export default Booking;
