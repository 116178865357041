import React from "react";
import {getData} from '../../../services/index';

import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';



class Earnings extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			booking_earning:0,
			last_booking_earning:0
		};
	}
	
	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.checkData();
		}
	}
	
	checkData(){
		if (localStorage.getItem('extranetData')){		
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var hotel_id = storedArray['data'][0]['hotel_id'];
			var app = {hotel_id : hotel_id,type:'current'}
			getData('extranet/booking_earning',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							booking_earning: result['data'][0]['total']
						});
					}else{
						this.setState({
							booking_earning: '0'
						});
					}
				}
			});
			var app = {hotel_id : hotel_id,type:'last'}
			getData('extranet/booking_earning',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							last_booking_earning: result['data'][0]['total']
						});
					}else{
						this.setState({
							last_booking_earning: '0'
						});
					}
				}
			});
		}
	}
	
	
	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-3 [Ecommerce]                                                */
			/*--------------------------------------------------------------------------------*/
			<Card>
				<CardBody>
					<CardTitle>Booking Earnings</CardTitle>
					<CardSubtitle>Total Earnings of the Month</CardSubtitle>
					<h2 className="font-medium"><i class="fas fa-rupee-sign"></i> {this.state.booking_earning}</h2>
					<CardSubtitle className="mt-2 mb-0">Total Earnings of last Month</CardSubtitle>
					<h3 className="mt-2 mb-0"><i class="fas fa-rupee-sign"></i> {this.state.last_booking_earning}</h3>
					
				</CardBody>
				
			</Card>
		);
	}
}

export default Earnings;
