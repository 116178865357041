import React from 'react';
import axios from 'axios';
import {getData} from '../../services/index';
import { message } from 'antd';
import { TimePicker } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';



class AddNewHotel extends React.Component {
constructor(props) {
super(props);
this.state = {
persons: [],
persons1: [],
hotel_name : '',
built_year : '',
hotel_type : '',
star_rating : '',
locality : '',
hotelier_name : '',
no_restaurant : '',
checkin_time : '',
checkout_time : '',
tac : '',
street_address : '',
zipcode : '',
country : '',
city : '',
state : '',
email : '',
phone : '',
no_rooms:0,
user_id : '',
ListData : [],
UpdateData : [],
};

this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
}

handleSubmit(){

		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		 var app = {

				hotelier_name : this.state.hotelier_name,
				built_year : this.state.built_year,
				no_restaurant : this.state.no_restaurant,
				checkin_time : document.getElementById("checkin_time").value,
				checkout_time : document.getElementById("checkout_time").value,
				street_address: this.state.street_address,
				locality : this.state.locality,
				country : this.state.country,
				state : this.state.state,
				city : this.state.city,
				zipcode : this.state.zipcode,
				hotel_name : this.state.hotel_name,
				hotel_type : this.state.hotel_type,
				star_rating : this.state.star_rating,
				tac : this.state.tac,
				email : this.state.email,
				phone : this.state.phone,
				no_rooms:this.state.no_rooms

			}

	  getData('extranet/add_new_hotel',app).then((result) => {
		if(result.status==400){
					alert(result.message);
				}
				if(result.status==200){
					message.config({ top: 150});
					message.success('Hotel Add Successfull!',2);
					let responseJson = result;
					if(responseJson.data){
						localStorage.setItem('extranetData',JSON.stringify(responseJson));
						this.setState({redirectToReferrer: true});
					}
				}
		});

}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			this.state.user_id = storedArray['data'][0]['user_id'];
			getData('extranet/basic_info',this.state).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							ListData: result['data']
						});
					}
				}
			});
		}
	}



onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}


render() {
if (this.state.redirectToReferrer){
	//this.props.history.push("/property/contact_info");
	window.location.href = '/property/contact_info';
}

function onChange(time, timeString) {
  console.log(time, timeString);
}

return (
<div>

   <Row>
      <Col sm="12" md="12" lg="12">
          <Card>
			<CardTitle className="bg-light border-bottom p-3 mb-0">Add New Hotel</CardTitle>
            <CardBody>
			<form className="login100-form validate-form">
			<FormGroup>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
				Basic Info
			  </CardTitle>
			</FormGroup>
             <Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Hotelname *</Label>
				   <Input type="text" name="hotel_name" id="hotel_name" onChange={this.onChange} />
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Display Name *</Label>
				   <Input type="text" name="hotelier_name" id="hotelier_name" onChange={this.onChange} />
				</Col>
				<Col sm="12" md="4" >
					<Label for="exampleCustomSelect">Hotel Type</Label>
                   <Input type="select"  name="hotel_type" id="hotel_type" onChange={this.onChange} className="custom-select" >
					<option value='' >Select Type</option>
					<option value="rst">Hotel/Resort</option>
					<option value="bngl">Bungalow</option>
					<option value="farm">Farm Stay</option>
                  </Input>

				</Col>
				</Row>
                </FormGroup>
			</Col>

			 <Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Email *</Label>
				   <Input type="text" name="email" id="email" onChange={this.onChange} className="custom-select" >

                  </Input>
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Phone *</Label>
				   <Input type="text" name="phone" id="phone" onChange={this.onChange} className="custom-select" >

                  </Input>
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Star Rating *</Label>
				    <Input type="select"  name="star_rating" id="star_rating" onChange={this.onChange} className="custom-select" >
					<option value='' >Select Star</option>
					<option value="1">* (1)</option>
					<option value="2">* * (2)</option>
					<option value="3">* * * (3)</option>
					<option value="4">* * * * (4)</option>
					<option value="5">* * * * * (5)</option>
                  </Input>
				</Col>
				<Col sm="12" md="4" className="m-t-10">
                  <Label for="exampleCustomSelect">Built Year</Label>
				   <Input type="text" name="built_year" id="built_year" onChange={this.onChange}/>
				</Col>
				<Col sm="12" md="4" className="m-t-10">
					<Label for="exampleCustomSelect">No. of Rooms*</Label>
					<Input type="text" name="no_rooms" id="no_rooms" onChange={this.onChange}/>
				</Col>
				<Col sm="12" md="4" className="m-t-10">
					<Label for="exampleCustomSelect">No. of Restaurant</Label>
					<Input type="text" name="no_restaurant" id="no_restaurant" onChange={this.onChange}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" >
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Checkin Time *</Label>
				   <div >
					<TimePicker use12Hours format="h:mm a" onChange={onChange} name="checkin_time" id="checkin_time"/>
				  </div>
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Checkout Time *</Label>
				   <div >
					<TimePicker use12Hours format="h:mm a" onChange={onChange} name="checkout_time" id="checkout_time"/>
				  </div>
				</Col>
				<Col sm="12" md="4" >
					<Label for="exampleCustomSelect">TAC (in %)</Label>
					<Input type="text" name="tac" id="tac" onChange={this.onChange} />
				</Col>
				</Row>
                </FormGroup>
			</Col>
			<FormGroup>
              <CardTitle className="bg-light border-bottom p-3 mb-0">
				PROPERTY ADDRESS
			  </CardTitle>
			</FormGroup>

			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Street Address *</Label>
				    <Input type="textarea" name="street_address" id="street_address" onChange={this.onChange} rows="5" />
				</Col>
				<Col md="8" className="row">
				<Col sm="12" md="6" >
				<FormGroup>
                  <Label for="exampleCustomSelect">Country *</Label>
				   <Input type="select" name="country" id="country" onChange={this.onChange} className="custom-select" >
					<option value='' >Select Country</option>
					{this.state.ListData.map((list) => (
					list.country_list.map(data =>
                    <option value={data.country_name} >{data.country_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6" >
				<FormGroup>
                  <Label for="exampleCustomSelect">State *</Label>
				   <Input type="select" name="state" id="state" onChange={this.onChange}  className="custom-select" >
				   <option value='' >Select State</option>
                    {this.state.ListData.map((list) => (
					list.state_list.map(data2 =>
                    <option value={data2.state_name} >{data2.state_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6" >
				  <FormGroup>
				  <Label for="exampleCustomSelect">City *</Label>
				   <Input type="select" name="city" id="city" onChange={this.onChange} className="custom-select" >
				   <option value='' >Select City</option>
                    {this.state.ListData.map((list) => (
					list.city_list.map(data1 =>
                    <option value={data1.city_name} >{data1.city_name}</option>
                    )
					))}
                  </Input>
				  </FormGroup>
				</Col>
				<Col sm="12" md="6" >
				  <FormGroup>
				  <Label for="exampleCustomSelect">Locality *</Label>
				   <Input type="text" name="locality" id="locality" onChange={this.onChange} className="custom-select" >

                  </Input>
				  </FormGroup>
				</Col>
				</Col>
				<Col sm="12" md="4" >
                  <Label for="exampleCustomSelect">Zipcode *</Label>
				    <Input type="text" name="zipcode" id="zipcode" onChange={this.onChange}/>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12">
                <FormGroup>
				<Row>
				<Col sm="12" md="12" className="center">
                  <Button color="primary" onClick={this.handleSubmit}>Save & Continue</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
			</form>

            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default AddNewHotel;
