import React from 'react';
import {connect} from 'react-redux';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import BigCalendar from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { events } from "../calendar/event-data";
import { message, Button,Modal,Collapse} from 'antd';
import 'antd/dist/antd.css';
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Form,
	FormGroup,
	Input,
	FormText,
	Label,
	CustomInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	FormFeedback
} from 'reactstrap';
import { getData } from '../../services/index';
const { confirm } = Modal;
const { Panel } = Collapse;

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: moment(),
      selectedDay: moment().startOf("day"),
			prev_next: [],
			date_list: [],
			room_list: [],
			DetailData:[],
			confirmLoading: false,
			mobile_room_list: [],
			set_interval: false,
			loading: false,
			visible: false,
			model_title: '',
			room_avl: 0,
			current_date: '',
			roomid: 0,
			show_loader:false
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showModal = this.showModal.bind(this);
  }

	showModal = (id,e) => {
				var storedArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = storedArray['data'][0]['hotel_id'];
				var app = {hotel_id : hotel_id,inventory_id:id}
				getData('extranet/rate_details',app).then((result) => {
				let responseJson = result;
				if(responseJson.data){
						if(result['count']>0){
						this.setState({
							DetailData: result['data']
						})
					}else{
						this.setState({
							DetailData: []
						});
					}
				}
			});
			this.setState({
				visible: true,
			});

		};

	handleCancel = (id,e) => {
			this.setState({
				visible: false
			});
		setTimeout(() => {
				this.setState({
			DetailData: [],
			});
			}, 500);

		};

		handleOk = () => {
				this.setState({
					confirmLoading: true,
				});

			if (localStorage.getItem('extranetData')) {
						var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
						var hotel_id          = extranetArray['data'][0]['hotel_id'];
						var inventory_id     = document.getElementById("inventory_id").value;
						var single_sell_rate = document.getElementById("single_sell_rate").value;
						var double_sell_rate = document.getElementById("double_sell_rate").value;
						var single_net_rate  = document.getElementById("single_net_rate").value;
						var double_net_rate  = document.getElementById("double_net_rate").value;
						var extra_adult      = document.getElementById("extra_adult").value;
						var extra_child      = document.getElementById("extra_child").value;
						var infant_charge    = document.getElementById("infant_charge").value;
						var app = {
							hotel_id:hotel_id,
							inventory_id: inventory_id,
							single_sell_rate: single_sell_rate,
							double_sell_rate: double_sell_rate,
							single_net_rate: single_net_rate,
							double_net_rate: double_net_rate,
							extra_adult: extra_adult,
							extra_child: extra_child,
							infant_charge: infant_charge,
						}
						if(inventory_id>0){
							getData('extranet/update_rate_details', app).then((result) => {
							let responseJson = result;
							if (responseJson) {
								if (result['status']==200) {
									this.checkData();
									message.config({ top: 150});
									message.success('Rate Updated!',2);
								}
							}
							});
						}
					}


				setTimeout(() => {
					this.setState({
						visible: false,
						confirmLoading: false,
					});
				}, 1000);
			};


	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: block";
		setTimeout(() => {
			document.querySelector(".loader-wrapper").style = "display: none";
		}, 900);
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			this.checkData();
		}
	}

		room_list() {
				if (localStorage.getItem('extranetData')) {
				var storedArray = JSON.parse(localStorage.getItem("extranetData"));
				this.setState(() => ({
					full_name: storedArray['data'][0]['full_name'],
					email_id: storedArray['data'][0]['email'],
				}));
				this.state.hotel_id = storedArray['data'][0]['hotel_id'];
				getData('extranet/inventory_list', this.state).then((result) => {
					let responseJson = result;
					if (responseJson.data) {
						if (result['count'] > 0) {
							this.setState({
								prev_next: result['data'][0]['prev_next'],
								date_list: result['data'][0]['date_list'],
								room_list: result['data'][0]['room_list']
							})
						}
					}
					this.checkData();
				});
			}
		}

		handleChange(event){
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_id = event.target.value;
				if (event.target.checked == true) {
					var is_active = '1';
				} else {
					var is_active = '0';
				}
				var app = {
					hotel_id: hotel_id,
					room_id: room_id,
					is_active: is_active
				}
				getData('extranet/room_active_inactive', app).then((result) => { this.checkData();  });
			}
		}

		UpdateInventory = (room_id, display_name, date, available,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_avl = event.target.value;
				var app = {
					room_id:room_id,
					display_name: display_name,
					date: date,
					room_avl:room_avl,
					hotel_id:hotel_id
				}
				getData('extranet/update_single_inventory', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							this.room_list();
							message.config({ top: 150});
							message.success('Inventory Updated!',2);
						}
						else{
							message.config({ top: 150});
							message.error('Inventory should not be greater than total rooms!',2);
						}
					}
					this.checkData();
				});
			}

		};

		blockSingleInventory = (room_id,date,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				if (event.target.checked == true) {
					var is_active = '1';
				} else {
					var is_active = '0';
				}
				var app = {
					room_id:room_id,
					date: date,
					is_active: is_active,
					hotel_id:hotel_id
				}
				getData('extranet/single_inventory_block', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Inventory Unblock!',2);
						}
						else{
							message.config({ top: 150});
							message.success('Inventory Block!',2);
						}
					}
					this.checkData();
				});
			}

		};




		plusInventory = (room_id,inventory_id,date,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var app = {
					room_id:room_id,
					inventory_id: inventory_id,
					date: date,
					hotel_id:hotel_id
				}
				getData('extranet/plus_inventory', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Inventory Updated!',2);
							this.checkData();
						}
						else{
							message.config({ top: 150});
							message.error('Inventory should not be greater than total rooms!',2);
						}
					}
				});
			}
		};

		minusInventory = (room_id,inventory_id,date,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var app = {
					room_id:room_id,
					inventory_id: inventory_id,
					date: date,
					hotel_id:hotel_id
				}
				getData('extranet/minus_inventory', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Inventory Updated!',2);
							this.checkData();
						}
					}
				});
			}
		};

		NextInventory = (date,event) => {
			if (localStorage.getItem('extranetData')) {

				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var app = {
					date: date,
					hotel_id:hotel_id
				}
				getData('extranet/inventory_list', app).then((result) => {
					let responseJson = result;
					if (responseJson.data) {
						if (result['count'] > 0) {
							this.setState({
								prev_next: [],
								date_list: [],
								room_list: []
							})
							this.setState({
								prev_next: result['data'][0]['prev_next'],
								date_list: result['data'][0]['date_list'],
								room_list: result['data'][0]['room_list']
							})
						}
					}
				});
			}
		};

		UpdateSellRate = (room_id,date,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_price = event.target.value;
				var tac = localStorage.getItem("tac");
				if(tac==''){
					tac=20;
				}
				var dis1=(parseFloat(room_price) * tac)/100;
				var net_room_price = parseFloat(room_price)-dis1;

				var app = {
					room_id:room_id,
					date: date,
					room_price:room_price,
					net_room_price:net_room_price,
					hotel_id:hotel_id
				}
				if(room_price>999){
					getData('extranet/update_single_sell_rate', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Sell Rate Updated!',2);
						}
					}
					});
				}
				else{
					message.config({ top: 150});
					message.warning('Your average room rate is lower than the actual',2);
					var type='sell_rate';
					getData('extranet/update_single_sell_rate', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 200});
							message.success('Sell Rate Updated!',2);
						}
					}
					});
				}
			}
		};

		UpdateDoubleSellRate = (room_id,date,event) => {
			if (localStorage.getItem('extranetData')) {
				var extranetArray = JSON.parse(localStorage.getItem("extranetData"));
				var hotel_id = extranetArray['data'][0]['hotel_id'];
				var room_price = event.target.value;
				var tac = localStorage.getItem("tac");
				if(tac==''){
					tac=20;
				}
				var dis1=(parseFloat(room_price) * tac)/100;
				var net_room_price = parseFloat(room_price)-dis1;
				var app = {
					room_id:room_id,
					date: date,
					room_price:room_price,
					net_room_price:net_room_price,
					hotel_id:hotel_id
				}
				if(room_price>999){
					getData('extranet/update_double_sell_rate', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 150});
							message.success('Double Sell Rate Updated!',2);
						}
					}
					});
				}
				else{
					message.config({ top: 150});
					message.warning('Your average room rate is lower than the actual',2);
					var type='sell_rate';
					getData('extranet/update_double_sell_rate', app).then((result) => {
					let responseJson = result;
					if (responseJson) {
						if (result['status']==200) {
							message.config({ top: 200});
							message.success('Net Rate Updated!',2);
						}
					}
					});
				}
			}
		};

		checkData = () => {
			if (localStorage.getItem('extranetData')) {
				var storedArray = JSON.parse(localStorage.getItem("extranetData"));
				this.setState(() => ({
					full_name: storedArray['data'][0]['full_name'],
					email_id: storedArray['data'][0]['email'],
				}));
				this.state.hotel_id = storedArray['data'][0]['hotel_id'];
				if(window.innerWidth>768){
					// for desktop
					getData('extranet/inventory_list', this.state).then((result) => {
						let responseJson = result;
						if (responseJson.data) {
							if (result['count'] > 0) {
								this.setState({
									prev_next: result['data'][0]['prev_next'],
									date_list: result['data'][0]['date_list'],
									room_list: result['data'][0]['room_list']
								})
							}
						}
					});
				}
				else{
					// for mobile
					getData('extranet/mobile_inventory_list', this.state).then((result) => {
						let responseJson = result;
						if (responseJson.data) {
							if (result['count'] > 0) {
								this.setState({
									mobile_room_list : []
								})
								this.setState({
									current_date : result['data'][0]['date'],
									mobile_room_list    : result['data'][0]['room_list']
								})
							}
						}
					});
				}


			}
		}

  previous() {
    const currentMonthView = this.state.selectedMonth;

    this.setState({
      selectedMonth: currentMonthView.subtract(1, "month")
    });
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.add(1, "month")
    });
  }

  select(day) {
    this.setState({
			show_loader:true,
      selectedMonth: day.date,
      selectedDay: day.date.clone()
    });
		var storedArray = JSON.parse(localStorage.getItem("extranetData"));
		var hotel_id = storedArray['data'][0]['hotel_id'];
		var app = {
			date: day.date,
			hotel_id:hotel_id
		}
		getData('extranet/mobile_inventory_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result['count'] > 0) {
					this.setState({
						current_date : '',
						mobile_room_list : []
					})
					this.setState({
						current_date : result['data'][0]['date'],
						mobile_room_list : result['data'][0]['room_list'],
						show_loader:false
					});
				}
			}
		});

  }

  goToCurrentMonthView(){
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: moment()
    });
  }

  showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay
    });
  }

  renderMonthLabel() {
    const currentMonthView = this.state.selectedMonth;
    return (
      <span className="box month-label">
        {currentMonthView.format("MMMM YYYY")}
      </span>
    );
  }

  renderDayLabel() {
    const currentSelectedDay = this.state.selectedDay;
    return (
      <span className="box month-label">
        {currentSelectedDay.format("DD MMMM YYYY")}
      </span>
    );
  }

  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;

    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Monday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();

    while (!done) {
      weeks.push(
        <Week
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          selected={currentSelectedDay}
          select={day => this.select(day)}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }

	Calculate1()
	{
		var tac = localStorage.getItem("tac");
		if(tac==''){
			tac=20;
		}
		var minutes1 = document.getElementById('single_sell_rate').value;
		var dis1=(parseFloat(minutes1) * tac)/100;
		document.getElementById('single_net_rate').value = parseFloat(minutes1)-dis1;
	}

	Calculate2()
	{
		var tac = localStorage.getItem("tac");
		if(tac==''){
			tac=20;
		}
		var minutes2 = document.getElementById('double_sell_rate').value;
		var dis2=(parseFloat(minutes2) * tac)/100;
		document.getElementById('double_net_rate').value=parseFloat(minutes2)-dis2;
	}

  render() {
    const currentMonthView = this.state.selectedMonth;
		const { visible, loading, model_title } = this.state;

    return (



				<div>
				   <Row>
					 <Modal
						 title="Rate Details"
						 visible={this.state.visible}
						 onCancel={this.handleCancel}
						 okText="Update"
						 onOk={this.handleOk}
						 confirmLoading={this.state.confirmLoading}
					 >
					 {this.state.DetailData.map((list) => (
						 <table class="table table-bordered rate_details">
							 <tbody>
							 <tr>
								 <td>Single Sell Rate</td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" onKeyUp={this.Calculate1} id="single_sell_rate" defaultValue={`${list.single_sell_rate}`}/>
										 <Input type="hidden" id="inventory_id" defaultValue={`${list.inventory_id}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Single Net Rate</td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" id="single_net_rate" defaultValue={`${list.single_net_rate}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Double Sell Rate</td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" onKeyUp={this.Calculate2} id="double_sell_rate" defaultValue={`${list.double_sell_rate}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Double Net Rate</td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" id="double_net_rate" defaultValue={`${list.double_net_rate}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Extra Adult Charges <br/><span>(age range above 12 years)</span></td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" id="extra_adult" defaultValue={`${list.extra_adult}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Extra child Charges <br/><span>(age range 6 - 12 years)</span></td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" id="extra_child" defaultValue={`${list.extra_child}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 <tr>
								 <td>Infant Charges if any <br/><span>(age below 6 years)</span></td>
								 <td>
									 <InputGroup>
										 <InputGroupAddon addonType="prepend">
											 <InputGroupText>INR</InputGroupText>
										 </InputGroupAddon>
										 <Input type="text" id="infant_charge" defaultValue={`${list.infant_charge}`}/>
									 </InputGroup>
								 </td>
							 </tr>
							 </tbody>
						 </table>
					 ))}
									</Modal>
						 <Col md="12" className="hidden-lg hidden-md hidden-sm event_calender">
						 <section className="main-calendar">
							 <header className="calendar-header">
								 <div className="row title-header">
									 <i
										 className="box arrow fa fa-angle-left"
										 onClick={this.previous}
									 />
									 <div className="box header-text">
									 {this.renderMonthLabel()}
									 </div>
									 <i className="box arrow fa fa-angle-right" onClick={this.next} />
								 </div>
								 <DayNames />
							 </header>
							 <div className="days-container">
								 {this.renderWeeks()}
							 </div>
						 </section>
				        <div class="current_date">{this.state.current_date}</div>

				        <div className="tab_card">
                <div className={`data_loader ${this.state.show_loader==false?'hide_loader':''}`}><img src="https://bungalowsinlonavala.com/img/loading.gif" /></div>

				{this.state.mobile_room_list.map((list) => (
								<table class="table table-bordered mob_booking_box">
									<tbody>
									<tr>
										<td colspan="2"  className="text-left">
											 <b>{list.display_name}</b>
										</td>
										<td className="text-right status"></td>
									</tr>
									<tr>
										<td colspan="2"  className="text-left">
											Room Status
										</td>
										<td className="text-right">
										<CustomInput type="checkbox" id={`inv_${list.inventory_id}`} label={`${list.is_block>0?'Unblock':'Block'}`} checked={`${list.is_block>0?'checked':''}`}  onChange={this.blockSingleInventory.bind(this,list.room_id,list.date)} defaultValue={`${list.is_block}`}/>
										</td>
									</tr>
									<tr>
										<td colspan="2"  className="text-left">
											Available
										</td>
										<td className="text-right fs16">
										<i class=" mdi mdi-minus-circle-outline" onClick={this.minusInventory.bind(this,list.room_id,list.inventory_id,list.date)}></i>	<input className="mob_avl" type="text" disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" onChange={this.UpdateInventory.bind(this,list.room_id,list.display_name,list.date,list.available)} defaultValue={`${list.available>0?list.available:'0'}`} /> <i class="mdi mdi-plus-circle-outline"  onClick={this.plusInventory.bind(this,list.room_id,list.inventory_id,list.date)}></i>
										</td>
									</tr>
									<tr>
										<td colspan="2"  className="text-left">
											Sold
										</td>
										<td className="text-right">
											{list.sold>0?list.sold:'0'}
										</td>
									</tr>

									<tr>
										<td className="text-left">
											EP Rates
										</td>
										<td className="text-right">
												<i class="mr-2 mdi mdi-account"></i>INR <input className="mob_rate" type="text" disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" onKeyUp={this.UpdateSellRate.bind(this,list.room_id,list.date)} defaultValue={`${list.single_sell_rate>0?list.single_sell_rate:'0'}`}/>
										</td>
										<td className="text-right">
												<i class="mdi mdi-account"></i><i class="mr-2 mdi mdi-account"></i> INR <input className="mob_rate" type="text" disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" onKeyUp={this.UpdateDoubleSellRate.bind(this,list.room_id,list.date)} defaultValue={`${list.double_sell_rate>0?list.double_sell_rate:'0'}`} />
										</td>
									</tr>

				{(list.room_rate_plan).map((plan) =>
				<>
				{(() => {

						if (plan.plan_type=='cp') {
											return (
												<tr>
													<td className="text-left">
														{plan.plan_name}
													</td>
													<td className="text-right opacity06">
															<i class="mr-2 mdi mdi-account"></i>INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.cp_sell_rate>0?list.cp_sell_rate:'0'}`} />
													</td>
													<td className="text-right opacity06">
															<i class="mdi mdi-account"></i><i class="mr-2 mdi mdi-account"></i> INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.cp_double_rate>0?list.cp_double_rate:'0'}`} />
													</td>
												</tr>
											)
									}

						if (plan.plan_type=='map') {
											return (
												<tr>
													<td className="text-left">
														{plan.plan_name}
													</td>
													<td className="text-right opacity06">
															<i class="mr-2 mdi mdi-account"></i>INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.map_sell_rate>0?list.map_sell_rate:'0'}`} />
													</td>
													<td className="text-right opacity06">
															<i class="mdi mdi-account"></i><i class="mr-2 mdi mdi-account"></i> INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.map_double_rate>0?list.map_double_rate:'0'}`} />
													</td>
												</tr>
											)
									}

						if (plan.plan_type=='ap') {
											return (
												<tr>
													<td className="text-left">
														{plan.plan_name}
													</td>
													<td className="text-right opacity06">
															<i class="mr-2 mdi mdi-account"></i>INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.ap_sell_rate>0?list.ap_sell_rate:'0'}`} />
													</td>
													<td className="text-right opacity06">
															<i class="mdi mdi-account"></i><i class="mr-2 mdi mdi-account"></i> INR <input className="mob_rate" type="text" readOnly disabled={`${list.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${list.ap_double_rate>0?list.ap_double_rate:'0'}`} />
													</td>
												</tr>
											)
									}

				})()}

				</>
				)}

				<tr>
					<td colspan="2" className="text-left"></td>
					<td className="text-right">
					<span className="more_rate" onClick={this.showModal.bind(this,list.inventory_id)}>
								Extra Rates
							</span>
					</td>
				</tr>
									</tbody>
									</table>
				))}

				</div>
						 </Col>
				      <Col md="12" className="hidden-xs">

							<Card>
								 <CardTitle className="bg-light border-bottom p-3 mb-0">Rates View</CardTitle>
								 <CardBody className="room_view">
										<Col sm="12" md="12" lg="12" className="no-paddding">
							{this.state.prev_next.map((prevnext) => (
										<div class="row">
											 <div class="col-md-6 pull-left topMargin text-left deviceFloatarrow">
													<a onClick={this.NextInventory.bind(this,prevnext.prev_date)} className="badge badge-secondary badge-pill prev_next">
													&lt; &nbsp; Prev 14 Days
													</a>
											 </div>
											 <div class="col-md-6 pull-right topMargin text-right deviceFloatRightArrow">
													<a onClick={this.NextInventory.bind(this,prevnext.next_date)} className="badge badge-secondary badge-pill prev_next">Next 14 Days  &nbsp; &gt;
													</a>
											 </div>
											 <div class="clearfix"></div>
										</div>
							))}
										</Col>

										<Col sm="12" md="12" lg="12" className="no-paddding">
										<div class="table-responsive">
											 <div class="well well2">
													<div class="tab-content">
														 <div class="tab-pane active" id="roomsview">
																<div id="inv_cal">
																	 <div class="topMargin">
																			<div id="calender_grid">

																				 <div className="row grid_hd" id="grid_hd">
																						<div class="cont_lt col-md-2 col-xs-3"><strong>Rooms </strong><br/></div>
																						<div class="srhd_rt col-md-10 col-xs-9">
													{this.state.date_list.map((datelist) => (
																							 <div class="grid_srhd">
																									<small>{datelist.month}</small>
																									<em>{datelist.day}</em>
																									<strong>{datelist.week}</strong>
																							 </div>
													))}
																						</div>
																				 </div>

												 {this.state.room_list.map((list) => (

												 <div className="row grid_hd" id="grid_hd">

																						<div class="cont_lt col-md-2 col-xs-3"><strong>{list.display_name} </strong><br/>
														EP Rates
														<span class="single_double_icon">
															<i class="mr-2 mdi mdi-account"></i><br/>
															<i class="mr-2 mdi mdi-account"></i>
															<i class="mr-2 mdi mdi-account"></i>
														</span>
													</div>
													<div class="cont_rt col-md-10 col-xs-9 roominventory" id="roominventory">
														{(list.inventory).map((inv) =>
																									<div class="grid_cont">
																										 <div>
															 <Input type="text" disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" onKeyUp={this.UpdateSellRate.bind(this,list.room_id,inv.date)} defaultValue={`${inv.single_sell_rate>0?inv.single_sell_rate:'0'}`} />

															 <Input type="text" disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" onKeyUp={this.UpdateDoubleSellRate.bind(this,list.room_id,inv.date)} defaultValue={`${inv.double_sell_rate>0?inv.double_sell_rate:'0'}`}/>

																										 </div>
							<span className="more_rate" onClick={this.showModal.bind(this,inv.inventory_id)}>
									more
								</span>
																									</div>
														)}
																						 </div>



							<Collapse bordered={false} class="rate_plan_view">

							{(list.room_rate_plan).map((plan) =>
							<Panel header={`${plan.plan_name}`} key={`${plan.plan_id}`}>
								<div class="cont_lt col-md-2 col-xs-3">
							<span class="single_double_icon">
								<i class="mr-2 mdi mdi-account"></i><br/>
								<i class="mr-2 mdi mdi-account"></i>
								<i class="mr-2 mdi mdi-account"></i>
							</span>
							</div>
							{(() => {

							if (plan.plan_type=='cp') {
												return (
									<div class="cont_rt col-md-10 col-xs-9 roominventory" id="roominventory">
										{(list.inventory).map((inv) =>
											<div class="grid_cont">
												<div>
													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.cp_sell_rate>0?inv.cp_sell_rate:'0'}`} />

													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.cp_double_rate>0?inv.cp_double_rate:'0'}`}/>
												</div>
											</div>
										)}
									</div>
												)
										}

							if (plan.plan_type=='map') {
												return (
									<div class="cont_rt col-md-10 col-xs-9 roominventory" id="roominventory">
										{(list.inventory).map((inv) =>
											<div class="grid_cont">
												<div>
													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.map_sell_rate>0?inv.map_sell_rate:'0'}`} />

													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.map_double_rate>0?inv.map_double_rate:'0'}`}/>
												</div>
											</div>
										)}
									</div>
												)
										}

							if (plan.plan_type=='ap') {
												return (
									<div class="cont_rt col-md-10 col-xs-9 roominventory" id="roominventory">
										{(list.inventory).map((inv) =>
											<div class="grid_cont">
												<div>
													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.ap_sell_rate>0?inv.ap_sell_rate:'0'}`} />

													<Input type="text" readOnly disabled={`${inv.is_block==1?'disabled':''}`} autocomplete="off" defaultValue={`${inv.ap_double_rate>0?inv.ap_double_rate:'0'}`}/>
												</div>
											</div>
										)}
									</div>
												)
										}

							})()}


							</Panel>
							)}

							</Collapse>

																				 </div>

												))}
																			</div>
																	 </div>
																	 <div class="clearfix"></div>
																	 <div class="">
																			<div class="pull-right">
																				 <span class="badge badge-success badge-pill inv-info">Single Rate</span>
																				 <span class="badge badge-primary badge-pill inv-info">Double Rate</span>
																				 <span class="badge badge-danger badge-pill inv-info">Blocked</span>
																			</div>
																			<div class="clearfix"></div>
																	 </div>
																</div>
														 </div>
													</div>
											 </div>
										</div>
										</Col>
								 </CardBody>
							</Card>



				      </Col>
				   </Row>
				</div>


      );
  }
}


class DayNames extends React.Component {
  render() {
    return (
      <div className="row days-header day_head">
        <span className="box day-name">Mon</span>
        <span className="box day-name">Tue</span>
        <span className="box day-name">Wed</span>
        <span className="box day-name">Thu</span>
        <span className="box day-name">Fri</span>
        <span className="box day-name">Sat</span>
        <span className="box day-name">Sun</span>
      </div>
    );
  }
}

class Week extends React.Component {
  render() {
    let days = [];
    let date = this.props.previousCurrentNextView;
    let currentMonthView = this.props.currentMonthView;
    let selected = this.props.selected;
    let select = this.props.select;

    for (var i = 0; i < 7; i++) {

      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isToday: date.isSame(new Date(), "day"),
        date: date
      };

      days.push(<Day day={day} selected={selected} select={select} />);
      date = date.clone();
      date.add(1, "d");
    }
    return (
      <div className="row week">
        {days}
      </div>
    );
  }
}

class Day extends React.Component {
  render() {
    let day = this.props.day;
    let selected = this.props.selected;
    let select = this.props.select;

    return (
      <div
        className={
          "day" +
          (day.isToday ? " today" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(selected) ? " selected" : "")
        }
        onClick={() => select(day)}
      >
        <div className="day-number">{day.number}</div>
      </div>
    );
  }
}

export default Calendar;
