import React from 'react';

import moment from 'moment';

import { DatePicker,Checkbox,Select  } from "antd";

import 'antd/dist/antd.css';
import { message } from 'antd';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';


import {getData} from '../../services/index';

const { MonthPicker, RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const defaultCheckedList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const { Option } = Select;

class ManageRate extends React.Component {



constructor(props) {
super(props);
this.state = {
room_type : [],
    indeterminate: false,
    checkAll: false,
	room_id : '',
    start_end_date : '',
    daylist : '',
	single_sell_rate : '',
	single_net_rate : '',
	double_sell_rate : '',
	double_net_rate : '',
	extra_adult : '',
	extra_child : '',
	infant_charge : '',
	tac:0
};

this.handleSubmit = this.handleSubmit.bind(this);
this.onChange = this.onChange.bind(this);
this.onChangeText = this.onChangeText.bind(this);
this.onChangeDate = this.onChangeDate.bind(this);
this.Calculate1 = this.Calculate1.bind(this);
this.Calculate2 = this.Calculate2.bind(this);
}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/room_list',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({
							room_type: result['data']
						})
					}
				}
			});
			getData('extranet/get_hotel_tac',app).then((result) => {
			let responseJson = result;
				if(responseJson){
					if(result['status']==200){
						this.setState({
							tac: 0
						})
						this.setState({
							tac: result['tac']
						})
					}
				}
			});
		}
	}

handleSubmit(){
	  var storedArray = JSON.parse(localStorage.getItem("extranetData"));
	  var room_id = document.getElementById("room_id").value;
	  var single_net_rate = document.getElementById("answer1").value;
	  var double_net_rate = document.getElementById("answer2").value;

	  if(room_id==''){
		message.error('Please select room',2);
	  }
	  else if(this.state.start_end_date==''){
		message.error('Please select date',2);
	  }
	  else if(this.state.daylist==''){
		message.error('Please select days of week',2);
	  }
	  else if(this.state.single_sell_rate==''){
		message.error('Please enter single sell rate',2);
	  }
	  else if(this.state.double_sell_rate==''){
		message.error('Please enter double sell rate',2);
	  }
	  else{
	  var app = {
		hotel_id : storedArray['data'][0]['hotel_id'],
		room_id : room_id,
		start_end_date : this.state.start_end_date,
		daylist : this.state.daylist,
		single_sell_rate : this.state.single_sell_rate,
		single_net_rate : this.state.single_net_rate,
		double_sell_rate : this.state.double_sell_rate,
		double_net_rate : this.state.double_net_rate,
		extra_adult : this.state.extra_adult,
		extra_child : this.state.extra_child,
		infant_charge : this.state.infant_charge,
	  }


	  getData('extranet/manage_rate_update',app).then((result) => {
		let responseJson = result;
			if(responseJson.data){
			    if(result['status']==200){
				message.config({ top: 150});
				if(result['data']['tag']=='0'){
					message.error(result['data']['msg'],2);
				}else{
					message.success(result['data']['msg'],2);
				}


				}
				else{
				message.error('Room Not Update!',2);
				}
			}
		});

}

}

onChangeText(e){
		this.setState({[e.target.name]:e.target.value});

	}

onChangeRadio(event) {
	  this.setState({
		is_block: event.target.value,

	  });
	}

	onChangeDate(event) {
		this.setState({
			start_end_date: event,
		});
	}

	Calculate1()
	{
		var tac = this.state.tac;
		if(tac==''){
			tac=20;
		}
		var minutes1 = document.getElementById('Minutes1').value;
		var dis1=(parseFloat(minutes1)*tac)/100;
		document.getElementById('answer1').value = parseFloat(minutes1)-dis1;
		this.setState({
			single_net_rate: parseFloat(minutes1)-dis1
		});
	}

	Calculate2()
	{
		var tac = this.state.tac;
		if(tac==''){
			tac=20;
		}
		var minutes2 = document.getElementById('Minutes2').value;
		var dis2=(parseFloat(minutes2)*tac)/100;
		document.getElementById('answer2').value=parseFloat(minutes2)-dis2;
		this.setState({
			double_net_rate: parseFloat(minutes2)-dis2
		});
	}

  onChange = checkedList => {
    this.setState({

      checkedList,
	  daylist : checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = e => {
    this.setState({
	  daylist : defaultCheckedList,
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

render() {

return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">Manage Room Rates - Bulk Update</CardTitle>
            <CardBody>
              <form className="login100-form validate-form">
             <Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2"  className="mt10">
                  <Label for="exampleCustomSelect">Room Type (Rate Plan) *</Label>
				</Col>
				<Col sm="12" md="4" >

				  <select class="form-control" id="room_id" name="room_id">
                                 <option value="" selected="selected">Select Room</option>
								 {this.state.room_type.map((list) => (
                                 <option value={`${list.room_id}`} >{list.display_name}</option>
								 ))}
                              </select>
				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Select Date *</Label>
				</Col>
				<Col sm="12" md="4" >
					  <FormGroup>
						<RangePicker
						  disabledDate={disabledDate}
						  disabledTime={disabledRangeTime}
						  format="DD MMM, YYYY"
						  name ="start_end_date"
						  onChange={this.onChangeDate}
						/>
					  </FormGroup>
				</Col>
				</Row>
                </FormGroup>
			</Col>



			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  <Label for="exampleCustomSelect">Days Of Week</Label>
				</Col>
				<Col sm="12" md="6" >
                  <Form inline>
				  <div className="col-md-10 ant-checkbox-wrapper-check-all">
				 <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            Check all
          </Checkbox>
		  </div>
		  <br />
		  <div className="col-md-12 no-paddding">
		  <CheckboxGroup
          options={plainOptions}
          value={this.state.checkedList}
          onChange={this.onChange}
		  name="daylist"
        />
		 </div>
				  </Form>



				</Col>
				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
			<Row>
				<Col sm="12" md="2"  className="col-xs-2">
				 &nbsp;
				 </Col>
        <Col sm="12" md="3" className="col-xs-5">
				    <Label for="exampleCustomSelect">Single Rate</Label>
				 </Col>
				 <Col sm="12" md="3" className="col-xs-5">
				     <Label for="exampleCustomSelect">Double Rate</Label>
				 </Col>
			</Row>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2"  className="hidden-xs">
                  <Label for="exampleCustomSelect">Sell Rate</Label>
				</Col>
				<Col sm="12" md="3"  className="col-xs-6">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" onChange={this.onChangeText} onKeyUp={this.Calculate1} name="single_sell_rate" id='Minutes1'/>
                  </InputGroup>
				</Col>
				<Col sm="12" md="3"   className="col-xs-6">

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" onChange={this.onChangeText} onKeyUp={this.Calculate2} name="double_sell_rate" id='Minutes2'/>
                  </InputGroup>
				</Col>

				</Row>
                </FormGroup>
			</Col>
			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2"  className="hidden-xs">
                  <Label for="exampleCustomSelect">Net Rate</Label>
				</Col>
				<Col sm="12" md="3"  className="col-xs-6">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="single_net_rate" onChange={this.onChangeText} id='answer1' disabled/><br/>
                    <span className="small_rate">(Net Rate)</span>
                  </InputGroup>
				</Col>
				<Col sm="12" md="3"  className="col-xs-6">

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="double_net_rate" onChange={this.onChangeText} id='answer2'  disabled/><br/>
                    <span className="small_rate">(Net Rate)</span>
                  </InputGroup>
				</Col>

				</Row>
                </FormGroup>
			</Col>
			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2" >
                  &nbsp;
				</Col>
				<Col sm="12" md="3" >
				<FormGroup>
				<Label for="exampleCustomSelect">Extra Adult Charges</Label>
				<Label for="exampleCustomSelect">(age range above 12 years)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="extra_adult" placeholder="Extra Adult" onChange={this.onChangeText}/>
                  </InputGroup>
				  </FormGroup>
				</Col>
				<Col sm="12" md="3" >
				<FormGroup>
				<Label for="exampleCustomSelect">Extra child Charges</Label>
				<Label for="exampleCustomSelect">(age range 6 - 12 years)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="extra_child" placeholder="Extra child" onChange={this.onChangeText}/>
                  </InputGroup>
				  </FormGroup>
				</Col>
				<Col sm="12" md="3" >
				<FormGroup>
				<Label for="exampleCustomSelect">Infant Charges if any</Label>
				<Label for="exampleCustomSelect">(age below 6 years)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>INR</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="infant_charge" placeholder="Extra Charges" onChange={this.onChangeText}/>
                  </InputGroup>
				  </FormGroup>
				</Col>

				</Row>
                </FormGroup>
			</Col>

			<Col sm="12" md="12" lg="12" className="no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="2"  className="hidden-xs">
                  &nbsp;
				</Col>
				<Col sm="12" md="4" >
                  <Button color="primary" onClick={this.handleSubmit}>Update Rates</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>
             </form>
            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default ManageRate;
