import Login from '../views/authentication/login';
import ExtraneLogin from '../views/authentication/extranet_login';
import Redirect from '../views/redirect';


var authRoutes = [
    { path: '/authentication/redirect/:id', name: 'Redirect', icon: 'mdi mdi-account-key', component: Redirect },
    { path: '/authentication/extranet', name: 'Login', icon: 'mdi mdi-account-key', component: ExtraneLogin }
];
export default authRoutes;
