import React from "react";
import axios from 'axios';
import {getData} from '../../../services/index';
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle
} from 'reactstrap';

import * as S from '../';

class EarningsOverview extends React.Component {
constructor(props) {
super(props);
this.state = {
persons : [],
count : '0',
total_booking : 0,
total_offer : 0,
total_customer : 0,
};
}

	componentDidMount() {
		if(!localStorage.getItem('extranetData')) {
			localStorage.setItem("extranetData",'');
			localStorage.clear();
			window.location.href = '/authentication/extranet';
		}
		else{
			var storedArray = JSON.parse(localStorage.getItem("extranetData"));
			var app = {
				hotel_id : storedArray['data'][0]['hotel_id']
			}
			getData('extranet/extranet_dashboard',app).then((result) => {
			let responseJson = result;
				if(responseJson.data){
					if(result['count']>0){
						this.setState({ 
							total_booking  : result['data']['0']['total_booking'],
							total_offer    : result['data']['0']['total_offer'],
							total_customer : result['data']['0']['total_customer'],
						});
					}
				}
			});
		}
	
	}
	
	
	render() {
		return (
			<Card>
				<CardBody className="border-bottom"  id={`${this.state.todaycount}`} >
					<CardTitle>Booking Overview</CardTitle>
					<CardSubtitle className="mb-0">Total Booking</CardSubtitle>
				</CardBody>
				<CardBody>
					<Row className="mt-2">
						<Col md="6" sm="12" lg="4">
							<S.Statistics
								textColor="orange"
								icon="wallet"
								title="Today Booking"
								subtitle={`${this.state.total_booking}`}
							/>
						</Col>
						<Col md="6" sm="12" lg="4">
							<S.Statistics
								textColor="primary"
								icon="basket"
								title="Total Offers"
								subtitle={`${this.state.total_offer}`}
								
							/>
						</Col>
						<Col md="6" sm="12" lg="4">
							<S.Statistics
								icon="account-box"
								title="Total Customers"
								subtitle={`${this.state.total_customer}`}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default EarningsOverview;
