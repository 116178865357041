import React from 'react';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

import {
  CardImg,
  CardImgOverlay,
  CardText,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from 'reactstrap';

import img1 from '../../assets/images/big/img1.jpg';

class documents extends React.Component {
constructor(props) {
super(props);
this.state = {
modal: false,
obj: {},
jsonData:'' ,
data: '',
treedata: ''
};

}

render() {

return (
<div>

   <Row className="mob_form">
      <Col sm="12" md="12" lg="12">
          <Card>
             <CardTitle className="bg-light border-bottom p-3 mb-0">
				Documents
			  </CardTitle>
            <CardBody>

			 <Col sm="12" md="12" lg="12" className="border-bottom mb-20 no_pad">
                <FormGroup>
				<Row>
				<Col sm="12" md="4"  className="mt10">
                  <FormGroup className="mb-1">
					  <div className="custom-file">
						<Input type="file" className="custom-file-input" id="customFile1" />
						<label className="custom-file-label" htmlFor="customFile1">Choose file</label>
					  </div>
					</FormGroup>
				</Col>
				<Col sm="12" md="4" >
					<Button color="primary">Upload</Button>
				</Col>
				</Row>
                </FormGroup>
			</Col>








            </CardBody>
          </Card>
        </Col>
   </Row>
</div>
);
}
}
export default documents;
